import { useMemo } from "react";

import { convertUptoDigits } from "@utils/appUtils";

import InputReferenceText from "@common/styles/components/headings/InputReferenceText";

const InputRanges = ({ id, ranges, hide, fixedDigits = 2, isInvisible }) => {
  const message = useMemo(() => {
    if (!ranges || hide) return null;
    const { minValue, maxValue } = ranges;
    return `Ranges ${convertUptoDigits(minValue, fixedDigits)} - ${convertUptoDigits(maxValue, fixedDigits)}`;
  }, [ranges, fixedDigits, hide]);

  return message ? <InputReferenceText id={id || "input_range"} refText={message} className={isInvisible ? "invisible" : "visible"} /> : null;
};

export default InputRanges;
