import { useState } from "react";
import ReactDOM from "react-dom";
import { set } from "react-hook-form";
import { usePopper } from "react-popper";

import CaseOptionsMenu from "./caseOptionMenu";

const CaseSubMenu = ({
  parentRef,
  submenuRef,
  showSubmenu,
  setShowSubmenu,
  cases,
  setCases,
  currentActiveCase,
  deleteCaseId,
  selectedCaseIndex,
  handleOpenCase,
  handleToggleDropdown,
}) => {
  const [submenuElement, setSubmenuElement] = useState(null);

  // Configure Popper.js
  const { styles, attributes } = usePopper(parentRef, submenuElement, {
    placement: "right-start",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: "viewport",
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  if (!showSubmenu) return null;

  const setSubmenuRef = ref => {
    setSubmenuElement(ref);
    submenuRef.current = ref;
  };

  const handleMouseLeave = () => {
    setShowSubmenu(false);
  };

  return ReactDOM.createPortal(
    <div
      ref={setSubmenuRef}
      className='submenu'
      style={{
        ...styles.popper,
      }}
      {...attributes.popper}
      onMouseLeave={handleMouseLeave}
    >
      <CaseOptionsMenu
        cases={cases}
        setCases={setCases}
        currentActiveCase={currentActiveCase}
        deleteCaseId={deleteCaseId}
        selectedCaseIndex={selectedCaseIndex}
        handleOpenCase={handleOpenCase}
        setShowSubmenu={setShowSubmenu}
        handleToggleDropdown={handleToggleDropdown}
      />
    </div>,
    document.body,
  );
};

export default CaseSubMenu;
