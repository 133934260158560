import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { useWPUfSystemSizeModuleDetailsTableData } from "./useWPUfSystemSizeModuleDetailsTableData";
import { WPUfSystemSizeModuleDetailsTableColumns as columns } from "./WPUfSystemSizeModuleDetailsTable.constants";

export const WPUfSystemSizeModuleDetailsTable = () => {
  const { uf_system_module } = useSelector(state => state.report.ufReportTableData);
  const { module_details_and_units, module_design } = uf_system_module || {};

  const { showModuleDesignTable, getModuleDesignTableColumns } = useWPUfSystemSizeModuleDetailsTableData();

  return (
    <>
      <WPTable
        sectionHeader={reportStrings.ufSystemSizeModuleDetails}
        columns={columns}
        rows={module_details_and_units}
      />
      {showModuleDesignTable && (
        <WPTable
          columns={getModuleDesignTableColumns()}
          rows={module_design}
          width='335px'
          renderEmptyTable
          tableBorder={{ right: true }}
        />
      )}
    </>
  );
};
