import { useDispatch, useSelector } from "react-redux";
import { getDesignData, getSystemDesignData, getUFDetails } from "src/api";

import API_URLS from "@constants/api.urls";
import { UNIT_TYPES } from "@constants/units.constant";

import { convertUpto2Digits } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";
import { DesignStrings } from "@utils/StringConstants";

import {
  detectDataChange,
  UpdatedStream,
  updateFeedDataAdded,
  updateFeedSetupStoreData,
} from "@features/feedwater/feedsetup/FeedsetupSlice";
import { setIsDataUpdated, setLoading, setNodeAndEdge } from "@features/feedwater/systemdesign/processDiagramSlice";
import {
  DEFAULT_WATER_SUB_TYPE_ID,
  DEFAULT_WATER_TYPE_ID,
  UF_SPECIAL_FEATURE,
} from "@features/feedwater/uf/constants/UFConstants";
import ufDefaultData from "@features/feedwater/uf/constants/UFDefaultValues.json";
import { UF_FIELDS_MAPPING } from "@features/feedwater/uf/constants/UFFieldsMaping";
import {
  setCustomAvail,
  setCustomOfflineTimePerUnit,
  updateActiveUFModule,
  updateUFDefaultInputRangeConfig,
  updateUFStore,
  updateUFStoreData,
  updateWaterSubtypeFlag,
} from "@features/feedwater/uf/UFSlice";
import { filterModules, getUFModulesByCompany, isIngeSelected } from "@features/feedwater/uf/ufUtils";

import useUFChemicalsHandler from "./useUFChemicalsHandler";
import useUFConfig from "./useUFConfig";
import useUFDataHandler from "./useUFDataHandler";
import { useUFInputRanges } from "./useUFInputRanges";
import useUnitConversion from "./useUnitConversion";

const Logger = DupontLogger("useGetProjectDetails");

const useGetProjectDetails = () => {
  const {
    data: UFData,
    ufInputRangeConfig,
    ufInputRangeConfigByWaterType,
    ufModulesByCompany,
  } = useSelector(state => state.UFStore);

  const { convertFromMetric } = useUnitConversion();
  const { updateActiveModule, getModuleDetails } = useUFConfig();
  const { setUFChemicalsDefaultValues } = useUFChemicalsHandler();
  const { fetchWaterTypes, fetchWaterSubTypes, fetchFeedSetupData } = useUFDataHandler();
  const { getConfigForForwardFlushFlow, getConfigForFiltrateFlux } = useUFInputRanges();

  const dispatch = useDispatch();

  const StreamStoreData = useSelector(
    state => state.Feedsetupdetailsdatapanel.streamData.lstrequestsavefeedwater[0]?.streams[0],
  );

  const { waterSubTypeID } = StreamStoreData || {};

  const waterSubTypeBasedInputConfigs = ufInputRangeConfigByWaterType.filter(w => w.waterSubType == waterSubTypeID);

  const { waterTypes } = useSelector(state => state.Feedsetupdetailsdatapanel);

  const formatFeedData = source => {
    const { waterTypeId, feedStreamId, ph, chargeBalance, ...rest } = source;
    const feedData = {
      waterTypeID: waterTypeId,
      feedStreamID: feedStreamId,
      pH: ph,
      chargeBalance: Number.parseFloat(chargeBalance).toFixed(6),
      balacedInd: true,
      blendedStreamInd: true,
      ...rest,
    };
    return feedData;
  };

  const onSystemDesignSucc = sysDesignresp => {
    let flow = sysDesignresp.flow;
    if (flow === 0 || flow === 1) {
      flow = 100;
    }
    const newFlowValue = convertUpto2Digits(convertFromMetric(flow, UNIT_TYPES.FLOW));
    dispatch(setNodeAndEdge({ ...sysDesignresp, ["flow"]: newFlowValue }));
    dispatch(setIsDataUpdated());
  };

  const getSystemDesign = async params => {
    try {
      const { data } = await getSystemDesignData(params);
      onSystemDesignSucc(data);
      return data;
    } catch (e) {
      Logger.error("System Design API error", e);
    }
  };

  const onFeedDataFetched = feedWaterData => {
    const waterTypeId = feedWaterData[0].waterTypeId || DEFAULT_WATER_TYPE_ID;
    const waterSubTypeID = feedWaterData[0].waterSubTypeID || DEFAULT_WATER_SUB_TYPE_ID;
    if (!waterTypes || waterTypes.length === 0) {
      fetchWaterTypes();
    }
    fetchWaterSubTypes(waterTypeId, waterSubTypeID);

    dispatch(detectDataChange(false));
  };

  const fetchFeedWaterDetails = async ({ caseID, projectID, userID }) => {
    try {
      const feedWaterData = await fetchFeedSetupData(caseID);
      if (feedWaterData.length === 0) {
        throw new Error("No feed water data found");
      }
      const streamData = { caseID, ...formatFeedData(feedWaterData[0]) };
      streamData.percentContribution = 100;
      const formattedStreamData = {
        Method: API_URLS.feedWater,
        userID,
        projectID,
        lstrequestsavefeedwater: [{ streams: [streamData] }],
      };
      dispatch(UpdatedStream(formattedStreamData));
      dispatch(updateFeedSetupStoreData({ title: feedWaterData[0].streamName }));
      dispatch(updateWaterSubtypeFlag(true));
      dispatch(updateFeedDataAdded(Boolean(feedWaterData[0].waterSubTypeID !== 0)));

      onFeedDataFetched(feedWaterData);
      return feedWaterData;
    } catch (e) {
      Logger.error("Feed water fetch API error", e);
    }
  };

  const getConfigForUFFields = (label, selectedUFModule) => {
    let normalRange = [];
    let rangeOnWaterSubType = [];
    if (label == "recycleFlowRate") {
      normalRange = ufInputRangeConfig.filter(config => config.label === DesignStrings.cIPRecycleFlowRate);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(
        config => config.label === DesignStrings.cIPRecycleFlowRate,
      );
      normalRange = [
        {
          ...normalRange[0],
          minValue: selectedUFModule.cIP_Flow_min,
          maxValue: selectedUFModule.cIP_Flow_max,
          defaultValue: convertFromMetric(selectedUFModule.cIP_Flow_std, UNIT_TYPES.FLOW),
        },
      ];
    } else if (label == "recycleFlowRate_MiniCIP") {
      // ranges should be same as recycleFlowRate , value should be 0 for inge or isCebOnly
      normalRange = ufInputRangeConfig.filter(config => config.label === DesignStrings.cIPRecycleFlowRate);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(
        config => config.label === DesignStrings.cIPRecycleFlowRate,
      );
      let defaultValue = convertFromMetric(selectedUFModule.cIP_Flow_std, UNIT_TYPES.FLOW);
      const { isCebOnly } = UF_SPECIAL_FEATURE;
      if (isIngeSelected(UFData.pUFTechnologyID) || +UFData.ufSpecialFeatureID === +isCebOnly) {
        defaultValue = 0;
      }
      normalRange = [
        {
          ...normalRange[0],
          label: DesignStrings.miniCIPRecycleFlowRate,
          minValue: selectedUFModule.cIP_Flow_min,
          maxValue: selectedUFModule.cIP_Flow_max,
          defaultValue,
        },
      ];
    } else if (label == "miniCIP") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Mini-CIP");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "mCIP Interval");
    } else if (label == "valveOpenCloseDuration") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Valve Open/Close Action Duration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "t_valves");
    } else if (label == "oxidantValue_BW") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Backwash Oxidant Concentration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Backwash NaOCl Dose");
    } else if (label == "backwash_design") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Filtration Duration");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Backwash Interval");
    } else if (label == "alkaliOxidantCEB") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Alkaline CEB");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Alkali CEB Interval");
    } else if (label == "acidCEB") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "Acid CEB");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "Acid CEB Interval");
    } else if (label == "cIP") {
      normalRange = ufInputRangeConfig.filter(config => config.label == "CIP");
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == "CIP Interval");
    } else {
      normalRange = ufInputRangeConfig.filter(config => config.label == UF_FIELDS_MAPPING[label]);
      rangeOnWaterSubType = waterSubTypeBasedInputConfigs.filter(config => config.label == UF_FIELDS_MAPPING[label]);
    }
    const rangeConfig = rangeOnWaterSubType?.length > 0 ? rangeOnWaterSubType : normalRange;
    return rangeConfig;
  };

  const getConfigForAirFlow = selectedUFModule => {
    const genericRange = ufInputRangeConfig.filter(config => config.label == "Air Flow");
    const airFlowRange = [
      {
        ...genericRange[0],
        minValue: selectedUFModule.aS_Flow_min,
        maxValue: selectedUFModule.aS_Flow_max,
        defaultValue: selectedUFModule.aS_Flow_std,
      },
    ];
    return airFlowRange;
  };

  const getFieldRangeConfig = (label, moduleID, selectedUFModule) => {
    if (UF_FIELDS_MAPPING[label] == "Filtrate Flux") {
      return getConfigForFiltrateFlux(selectedUFModule);
    } else if (UF_FIELDS_MAPPING[label] == "Forward Flush Flow") {
      return getConfigForForwardFlushFlow(selectedUFModule);
    } else if (UF_FIELDS_MAPPING[label] == "Air Flow") {
      return getConfigForAirFlow(selectedUFModule);
    } else {
      return getConfigForUFFields(label, selectedUFModule);
    }
  };

  const fetchDesignData = async ({ caseID, projectID, userID }) => {
    const { data } = await getDesignData({ projectID, userID, caseID });

    const {
      listModules,
      listUfField,
      lstUFGuidelineVM,
      lstUfmoduleFlowVM: ufModuleFlowVM,
      lstUFFluxGuideline: ufFluxGuideline,
      lstUFTargetPHGuideline: ufPHGuidline,
      lstUFTargetDoseGuideline: ufDoseGuidline,
      lstUFModulePressureRatingVM: ufModulePressureRatingVM,
    } = data || {};

    const ufModulesByCompany = filterModules(listModules);
    dispatch(updateUFStoreData({ ufModulesByCompany }));

    const ufInputRangeConfig = formatUFFields(listUfField);
    const ufInputRangeConfigByWaterType = formatUFFields(lstUFGuidelineVM);
    const isUFDesignDataFetched = true;

    const ufData = {
      ufPHGuidline,
      ufModuleFlowVM,
      ufDoseGuidline,
      ufFluxGuideline,
      ufInputRangeConfig,
      ufModulePressureRatingVM,
      ufInputRangeConfigByWaterType,
      isUFDesignDataFetched,
    };

    dispatch(updateUFStoreData(ufData));
  };

  const formatSingleUfField = (f, isBar) => ({
    label: f.guideLineAbbr,
    defaultValue: isBar ? f.typicalValue * 1000 : f.typicalValue,
    minValue: isBar ? f.minValue * 1000 : f.minValue,
    maxValue: isBar ? f.maxValue * 1000 : f.maxValue,
    softLowerLimit: f.softLowerLimit ? f.softLowerLimit : f.minValue,
    softUpperLimit: f.softUpperLimit ? f.softUpperLimit : f.maxValue,
    uom: isBar ? "mbar/h" : f.uom,
    waterSubType: f.waterSubTypeId != undefined ? f.waterSubTypeId : "",
    companyName: f.companyName,
  });

  const fields = [
    "Backwash TMP Increase",
    "Acid CEB TMP Increase",
    "Alkali CEB TMP increase",
    "CIP TMP increase",
    "Mini-CIP",
  ];

  const formatUFFields = ufFieldsArray => {
    const fieldToValueMap = [];
    ufFieldsArray?.map(f => {
      if (fields.includes(f.guideLineAbbr) && (f.uom == "bar/h" || f.uom == "bar/hr")) {
        fieldToValueMap.push(formatSingleUfField(f, true));
      } else {
        fieldToValueMap.push(formatSingleUfField(f));
      }
    });
    return fieldToValueMap;
  };

  const convertUfValues = (keys, dataSource, unitType) =>
    keys.reduce((acc, key) => {
      const value = dataSource[key];
      acc[key] = convertUpto2Digits(convertFromMetric(value, unitType));
      return acc;
    }, {});

  const unitDataKeyMapping = {
    [UNIT_TYPES.TEMPERATURE]: ["recycleTemperature"],
    [UNIT_TYPES.PRESSURE]: [
      "maxAirScourPressure",
      "maxAirProcPressure",
      "filteratePressure",
      "nonIntegraPacTrainPresDrop",
      "integraPacFiltrationPreDrop",
      "backwashPipingPreDrop",
      "cIPPipingPreDrop",
      "backwash_Filtration",
      "acidCEB_Filtration",
      "alkaliCEB_Filtration",
      "cIP_Filtration",
    ],
    [UNIT_TYPES.FLUX]: ["filtrateFlux", "backwashFlux", "cEBFlux"],
    [UNIT_TYPES.FLOW]: ["forwardFlushFlow", "recycleFlowRate", "recycleFlowRate_MiniCIP"],
    [UNIT_TYPES.GAS_FLOW]: ["airFlow", "aerationAirFlow"],
    [UNIT_TYPES.POWER]: ["pLCPowerReqPertrain", "volvePowerReqPerTrain"],
  };

  const fetchUFDetails = async ({ caseID, projectID, userID, treatmentObjID, feedWaterDataResp }) => {
    const { waterSubTypeID, waterTypeID } = feedWaterDataResp[0];

    try {
      const responseUFDetails = await getUFDetails({ caseID, projectID, userID, treatmentObjID });
      dispatch(setLoading(false));
      const obj = { ...responseUFDetails.data };
      const UFDefaultConfigs = { ...UFData };

      const UFInputKeys = Object.keys(obj);
      const { uFModuleID, pUFTechnologyID } = responseUFDetails.data;
      const techId = pUFTechnologyID === 0 ? 1 : pUFTechnologyID;
      const company = techId == 1 ? "DUPONT" : "INGE";
      const companyModules = getUFModulesByCompany({
        ufCompany: company,
        waterID: waterTypeID,
        ufModulesByCompany,
        feedWaterData: StreamStoreData,
      });

      let moduleID = uFModuleID;
      if (moduleID == 0) {
        moduleID = companyModules[0].ufmoduleId;
      }

      const selectedModule = getModuleDetails(moduleID, companyModules);
      dispatch(updateUFStoreData({ ufModules: companyModules }));
      dispatch(updateActiveUFModule(selectedModule));
      updateActiveModule(moduleID, companyModules, responseUFDetails.data);
      if (obj["uFModuleID"] == 0) {
        const obj = { ...ufDefaultData };
        const UFDefaultConfigs = { ...UFData };
        let storeObj = { ...UFData };
        storeObj["pUFTechnologyID"] = techId;
        dispatch(setCustomAvail(true));

        UFInputKeys?.map(x => {
          if (x == "uFModuleID") {
            storeObj["uFModuleID"] = moduleID;
            storeObj["uFBWProtocolID"] = UFData.uFBWProtocolID;
          }
          storeObj["disOxidantEnabled_Ind_CEB"] = false;
          const config = getFieldRangeConfig(x, moduleID, selectedModule);
          UFDefaultConfigs[x] = config?.length > 0 ? config[0] : {};

          if (obj[x] === null) {
            storeObj[x] = "0";
          }
          if (obj[x] == 0) {
            const fieldConfiguration = getFieldRangeConfig(x, moduleID, selectedModule);
            if (fieldConfiguration?.length > 0) {
              const { minValue, defaultValue } = fieldConfiguration[0];
              const fieldDefaultValue = defaultValue == 0 ? minValue : defaultValue;
              storeObj[x] = fieldDefaultValue;

              if (unitDataKeyMapping[UNIT_TYPES.FLUX].includes(x)) {
                storeObj[x] = convertFromMetric(fieldDefaultValue, UNIT_TYPES.FLUX);
              } else if (unitDataKeyMapping[UNIT_TYPES.PRESSURE].includes(x)) {
                storeObj[x] = convertFromMetric(fieldDefaultValue, UNIT_TYPES.PRESSURE);
              } else if (unitDataKeyMapping[UNIT_TYPES.FLOW].includes(x)) {
                storeObj[x] = convertFromMetric(fieldDefaultValue, UNIT_TYPES.FLOW);
              } else if (unitDataKeyMapping[UNIT_TYPES.GAS_FLOW].includes(x)) {
                storeObj[x] = convertFromMetric(fieldDefaultValue, UNIT_TYPES.GAS_FLOW);
              } else if (unitDataKeyMapping[UNIT_TYPES.POWER].includes(x)) {
                storeObj[x] = convertFromMetric(fieldDefaultValue, UNIT_TYPES.POWER);
              }
            }
            //non dupont fields are set as zero
            const nonDupontFields = ["backwash_Filtration", "lF", "disinfectionCEB", "aerationAirFlow"];
            if (nonDupontFields.includes(x)) {
              storeObj[x] = 0;
            }
          }
        });
        storeObj.disOxidantEnabled_Ind_CEB = false;
        storeObj.t_BWBtnAirScour = 1;
        storeObj.userID = userID;
        storeObj.projectID = projectID;
        storeObj.fromTreatmentObjID = obj.fromTreatmentObjID;
        storeObj.caseTreatmentID = responseUFDetails.data.caseTreatmentID;
        storeObj.treatmentObjID = responseUFDetails.data.treatmentObjID;
        storeObj.caseID = caseID;
        storeObj.bWStepInCIP = obj.bWStepInCIP || 2;
        storeObj.rinseBWCycle = obj.rinseBWCycle || 1;
        storeObj.bWStepInMiniCIP = obj.bWStepInMiniCIP || 1;
        storeObj.rinseBWCycle_MiniCIP = obj.rinseBWCycle_MiniCIP || 1;
        storeObj.cIPRinseSoakCycle_MiniCIP = obj.cIPRinseSoakCycle_MiniCIP || 1;
        storeObj.recycleTemperature = convertFromMetric(35, UNIT_TYPES.TEMPERATURE);
        storeObj.uFBWCEBStandbyOptionID = 1;
        storeObj.uFBWFlushWaterTypeID = 1;
        storeObj.uFBWWaterTypeID = 2;
        storeObj.uFBWProtocolID = 2;
        storeObj.uFCIPWaterTypeID = 1;
        storeObj.uFCEBWaterTypeID = 1;
        storeObj.uFMiniCIPWaterTypeID = 1;
        storeObj.offlinetimepertrain = 0;
        // Chemicals default data
        const chemicalsDefaultData = setUFChemicalsDefaultValues({ UFData: storeObj, waterSubTypeID });
        storeObj = { ...storeObj, ...chemicalsDefaultData };
        dispatch(updateUFDefaultInputRangeConfig(UFDefaultConfigs));
        dispatch(updateWaterSubtypeFlag(false));
        dispatch(updateUFStore(storeObj));
      } else {
        dispatch(setCustomAvail(false));
        let storeObj = JSON.parse(JSON.stringify(responseUFDetails.data));
        obj["isWaterSubTypeChanged"] = false;
        obj["isDesignTempChanged"] = false;
        storeObj["uFModuleID"] = moduleID;
        storeObj["uFBWProtocolID"] = storeObj.uFBWProtocolID == 0 ? 2 : storeObj.uFBWProtocolID;

        UFInputKeys?.map(x => {
          if (x != "uFModuleID") {
            const config = getFieldRangeConfig(x, moduleID, selectedModule);
            UFDefaultConfigs[x] = config?.length > 0 ? config[0] : {};
          }
        });

        Object.entries(unitDataKeyMapping).forEach(([unitType, keys]) => {
          storeObj = { ...storeObj, ...convertUfValues(keys, responseUFDetails.data, parseInt(unitType)) };
        });

        const ingeTROption = selectedModule?.tRack ? `TR${obj.skidsPerTrain}` : null;

        dispatch(updateUFDefaultInputRangeConfig(UFDefaultConfigs));
        dispatch(updateUFStoreData({ data: storeObj, ingeTROption }));
      }

      if (obj["offlinetimepertrain"] != 12 && obj["offlinetimepertrain"] != 0) {
        dispatch(setCustomOfflineTimePerUnit(true));
      }

      dispatch(
        updateUFStoreData({
          isForDrinkingWater: obj["drinkingWater_Ind"],
          isUfDataUpdated: false,
          isUFDetailsFetched: true,
          calcEngineDataRefreshCount: 1,
        }),
      );
    } catch (e) {
      Logger.error("UF Details API error", e);
    }
  };

  return { getSystemDesign, fetchFeedWaterDetails, fetchUFDetails, fetchDesignData };
};

export default useGetProjectDetails;
