import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfSystemOverviewRows = [
  ["moduleType"],
  ["numUnits"],
  [],
  ["modules"],
  ["systemFlowRate", UNIT_TYPES.FLOW],
  ["trainFlowRate", UNIT_TYPES.FLOW],
  ["ufSystemRecovery", UNITS.percentage],
  ["tmp", UNIT_TYPES.PRESSURE],
  ["utilityWater"],
  [""],
];

export const WPUfSystemOverviewTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
    rowHeader: true,
    width: "80px",
  },
  {
    field: "col3",
  },
  {
    field: "col4",
  },
];
