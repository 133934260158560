import { useEffect, useMemo, useState } from "react";

import { CustomModalButtons } from "@constants/global.constants";

import useProjectDetails from "@hooks/useProjectDetails";

import DupontLogger from "@utils/DupontLogger";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import CustomModal from "@components/CustomModal";
import WPButton from "@components/WPButton";
import { useToastNotification } from "@components/WPToastNotification";

import { PROJECT_COST_FOOTER_BUTTONS } from "../chemicalLibrary.constants";
import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLibraryHooks/useChemicalLibrary";
import ProjectCostTable from "../ProjectCostTable";

import ProjectOperationCost from "./ProjectOperationCost";

import "./ProjectCostDialog.scss";

const ProjectCostDialog = ({ onClose }) => {
  const Logger = DupontLogger("ProjectCostDialog");
  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  const { loaderText, projectChemicalCosts, fetchProjectCosts, updateProjectCosts, makeChemicalAndUtilityCostDefault } =
    useChemicalLibrary();
  const { projectDetails } = useProjectDetails();

  const [updatedChemicals, setUpdatedChemicals] = useState([]);
  const [updatedCost, setUpdatedCost] = useState(null);
  const [confirmDlgData, setConfirmDlgData] = useState(null);
  const [isUtilityCostDefaultFlag, setIsUtilityCostFlag] = useState(false);

  const { operationCost, projectChemicals } = projectChemicalCosts;

  useEffect(() => {
    fetchProjectCosts();
  }, []);

  const updateOperationCosts = async () => {
    const paylaod = {
      operationCost: updatedCost || operationCost,
      projectChemicals: updatedChemicals,
    };
    const { isSuccess, message } = await updateProjectCosts(paylaod);
    if (isSuccess) {
      setUpdatedChemicals([]);
      setUpdatedCost(null);
      showSuccessNotification(message);
    } else {
      showErrorNotification(message);
    }
  };

  /**
   * Sets the default price for chemicals or utility costs based on the button clicked.
   *
   * @param {string} buttonName - The name of the button that was clicked,
   *                              used to determine if the utility cost or chemical price is being set as default.
   */
  const makePriceDefault = async buttonName => {
    const isUtilityCostDefault = buttonName === CHEMICALS_LIBRARY_STRINGS.makeUtilityCostBtnFlag;
    setIsUtilityCostFlag(isUtilityCostDefault);
    const dialogHeader = isUtilityCostDefault
      ? CHEMICALS_LIBRARY_STRINGS.utilityCostHeaderUpdated
      : CHEMICALS_LIBRARY_STRINGS.chemicalPriceHeaderUpdated;

    const dialogDescription = isUtilityCostDefault
      ? CHEMICALS_LIBRARY_STRINGS.utilityCostDescUpdated
      : CHEMICALS_LIBRARY_STRINGS.chemicalPriceDescUpdated;

    setConfirmDlgData({
      type: DIALOG_TYPES.WARNING,
      header: dialogHeader,
      description: dialogDescription,
    });
  };
  const handleConfirmDlgClose = async id => {
    setConfirmDlgData(null);
    if (id === CustomModalButtons.CONFIRM) {
      const payload = {
        projectId: projectDetails.projectID,
        isProjectCost: !isUtilityCostDefaultFlag,
      };
      const { isSuccess, message } = await makeChemicalAndUtilityCostDefault(payload, isUtilityCostDefaultFlag);
      if (isSuccess) {
        showSuccessNotification(message);
      } else {
        showErrorNotification(message);
      }
    }
  };

  const handleBtnClick = ({ target }) => {
    switch (target.id) {
      case "makeDefaultPriceBtn":
        makePriceDefault();
        break;
      case "addEditChemicalBtn":
        Logger.log("Adding or editing chemicals");
        onClose({ openChemicalLib: true });
        break;
      case "saveUpdatedChemicals":
        updateOperationCosts();
        break;
      default:
        Logger.error("Unknown button clicked", target.id);
    }
  };

  const isSaveDisabled = useMemo(
    () => !updatedCost && !updatedChemicals.length,
    [updatedCost, updatedChemicals.length],
  );

  const buttonStates = {
    saveUpdatedChemicals: isSaveDisabled,
    makeDefaultPriceBtn: !isSaveDisabled,
  };

  const modalFooter = useMemo(
    () =>
      PROJECT_COST_FOOTER_BUTTONS.map(btnConfig => {
        const isDisabled = buttonStates[btnConfig.id] ?? false;
        return (
          <WPButton
            key={btnConfig.id}
            variant={btnConfig.variant}
            id={btnConfig.id}
            onClick={handleBtnClick}
            disabled={isDisabled}
          >
            {btnConfig.label}
          </WPButton>
        );
      }),
    [handleBtnClick, updatedChemicals, updatedCost, isSaveDisabled],
  );

  const onChemicalUpdate = updatedData => {
      const { isError, ...updatedChemical } = updatedData;
  
      setUpdatedChemicals(prevChemicals => {
          if (isError) {
              return prevChemicals.filter(chem => +chem.id !== +updatedChemical.id);
          }
  
          const index = prevChemicals.findIndex(chem => +chem.id === +updatedChemical.id);
          if (index !== -1) {
              return prevChemicals.map(chem => (chem.id === updatedChemical.id ? updatedChemical : chem));
          }
  
          return [...prevChemicals, updatedChemical];
      });
  };

  const handleCostChange = updatedOperationCost => setUpdatedCost(updatedOperationCost);

  return (
    <CustomModal
      header={CHEMICALS_LIBRARY_STRINGS.projectCostDlgHeading}
      headerHelper={CHEMICALS_LIBRARY_STRINGS.projectCostDlgSubHeading}
      onModalClose={onClose}
      width='930px'
      customclass='chemical-dialog--container'
      isLoading={Boolean(loaderText)}
      loadingText={loaderText}
      modalFooter={modalFooter}
    >
      {operationCost && (
        <ProjectOperationCost
          operationCost={operationCost}
          onCostChange={handleCostChange}
          onUtilityCostDefault={makePriceDefault}
          utilityCostBtnDisabled={!isSaveDisabled}
        />
      )}
      <ProjectCostTable chemicals={projectChemicals} onChemicalUpdate={onChemicalUpdate} />
      {confirmDlgData && (
        <ConfirmationPopup
          {...confirmDlgData}
          onClose={handleConfirmDlgClose}
          confirmBtn={CHEMICALS_LIBRARY_STRINGS.setAsDefaultBtn}
          cancelBtn={CHEMICALS_LIBRARY_STRINGS.cancelBtn}
        />
      )}
    </CustomModal>
  );
};

export default ProjectCostDialog;
