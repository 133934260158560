import React, { useEffect, useMemo, useState } from "react";

import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomTooltip from "@common/styles/components/tooltip/CustomTooltip";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";

const ChemicalProjectCostRenderer = params => {
  const { data, context } = params;
  const { activeCurrency, convertChemicalCurrency } = useChemicalUnitConversion();
  const [errMessage, setErrMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const validate = value => {
      let message = null;
  
      if (value === 0 || value === "0") {
          message = CHEMICALS_LIBRARY_STRINGS.projectCostZeroError;
      } else if (!value) {
          message = CHEMICALS_LIBRARY_STRINGS.projectCostRequiredError;
      } else if (value < 0) {
          message = CHEMICALS_LIBRARY_STRINGS.projectCostNegError;
      }
      setErrMessage(message);
      setIsError(Boolean(message));
      return Boolean(message);
  };

  const handleInputBlur = ({ target }) => {
    const { value } = target;
    const isError = validate(value);
    if (context.onDataUpdate) {
      const bulkPrice = convertChemicalCurrency(value, true);
      context.onDataUpdate({ ...data, bulkPrice, isError });
    }
  };

  const handleBodyScroll = ({ direction }) => {
    if (direction == "vertical") {
      setErrMessage(null);
    }
  };

  useEffect(() => {
    const gridApi = params.api;
    gridApi.addEventListener("bodyScroll", handleBodyScroll);

    return () => {
      gridApi.removeEventListener("bodyScroll", handleBodyScroll);
    };
  }, [params.api]);

  const value = useMemo(() => {
    validate(data.bulkPrice);
    return convertChemicalCurrency(data.bulkPrice);
  }, [data.bulkPrice]);

  return (
    <CustomTooltip text={errMessage} type='error'>
      <div className='cost-input-container'>
        <InputWithText
          type='number'
          id={`checmical_cost_input_${data.id}`}
          name={`checmical_cost_input_${data.id}`}
          inputText={activeCurrency}
          value={value}
          onBlur={handleInputBlur}
          isError={isError}
        />
      </div>
    </CustomTooltip>
  );
};

export default ChemicalProjectCostRenderer;
