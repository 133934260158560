import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DupontString, IngeString } from "@utils/StringConstants";

import CustomCard from "@components/CustomCard";
import IconButton from "@components/IconButton";
import { QuestionIcon } from "@components/SVGs";
import WPHeading from "@components/WPHeading";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import CalcEngineInputBox from "@common/styles/components/inputs/CalcEngineInputBox";
import CustomInput from "@common/styles/components/inputs/CustomInput";
import { handleTabAndEnter } from "@common/styles/components/inputs/InputFocusUtililty";
import CustomRadio from "@common/styles/components/radios/CustomRadio";

import ProjectErrorPopup from "@features/modals/ProjectErrorPopup";

import { INGE_MODULE_TR_MULTIPLIER, INGE_MODULE_TR_RANGES } from "../../constants/UFConstants";
import { UFStrings } from "../../constants/UFStrings";
import { updateUFStore, updateUFStoreData } from "../../UFSlice";
import { calculateUFFields } from "../UFHelper";

import UFSelectedConfigurationHelpDlg from "./UFSelectedConfigurationHelpDlg";

const MesageMap = {
  modulesPerSkid: "Modules/Rack",
  onlineTrains: "Online Units",
  redundantStandbyTrains: "Standby Units",
  redundantTrains: "Redundant Units",
  modulesPerTrain: "Modules/Unit",
  skidsPerTrain: "Racks/Unit",
};

const INGE_TR_OPTION_WITH_ODD_VALUE = "TR4";

const CardHeader = ({ isHelpIcon, onHelpBtnClick }) => (
  <div className='card-header-container'>
    <WPHeading size='small' secondary text={UFStrings.selectedConfiguraion} />
    {isHelpIcon && (
      <IconButton
        id='help-dialog-btn'
        className='question-icon'
        onClick={onHelpBtnClick}
        tooltip={UFStrings.questionIconTooltip}
      >
        <QuestionIcon />
      </IconButton>
    )}
  </div>
);

const UFSelectedConfiguration = () => {
  const dispatch = useDispatch();
  const updateStoreData = newData => dispatch(updateUFStoreData(newData));

  const { activeUFModule, defaultInputRangeConfig, calcEngineData, data, ufCompany, ingeTROption, isUFConfigLoading } =
    useSelector(state => state.UFStore);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { skids, skidsPerTrain, modulesPerSkid } = data;
  const [isFieldValid, setIsFieldValid] = useState(false);
  const [flux_Filter_actual, setFlux_Filter_actual] = useState(0);
  const [message, setMessage] = useState("");
  const [ingeModuleRackOptions, setIngeModuleRackOptions] = useState();
  const [selectedTROption, setSelectedTROption] = useState(null);
  const [inputErrMsg, setInputErrMsg] = useState();
  const [isHelpDlg, setIsHelpDlg] = useState(false);
  const labelStrings = ufCompany === "INGE" ? IngeString : DupontString;

  const fieldOnlineTrains = defaultInputRangeConfig["onlineTrains"];
  const redundantStandbyTrains1 = defaultInputRangeConfig["redundantStandbyTrains"];

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleTabAndEnter(event);
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => document.body.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    const { ufmoduleId, moduleType } = activeUFModule;
    setSelectedTROption(null);
    //Timout is added to make sure, selected TR option is reset to null, before setting new
    setTimeout(() => {
      if (moduleType === "Inge") {
        const TROptions = INGE_MODULE_TR_RANGES[ufmoduleId];
        const trOption = activeUFModule.tRack ? `TR${skidsPerTrain}` : null;
        setIngeModuleRackOptions(TROptions);
        setSelectedTROption(trOption);
      } else {
        setIngeModuleRackOptions(null);
      }
    }, 200);
  }, [activeUFModule]);

  useEffect(() => {
    if (ingeTROption) {
      setSelectedTROption(ingeTROption);
    }
  }, [ingeTROption]);

  // useEffect(() => {
  //   if (activeUFModule.integraPacInd || activeUFModule.tRack) {
  //     dispatch(calculateUFConfigFields());
  //   }
  // }, [skidsPerTrain]);

  useEffect(() => {
    const value = Number(Math.round(calcEngineData?.flux_Filter_actual) ?? 0).toFixed(2);
    setFlux_Filter_actual(value);
  }, [calcEngineData?.flux_Filter_actual]);

  const validations = {
    modulesPerSkid: defaultInputRangeConfig["modulesPerSkid"],
    onlineTrains: defaultInputRangeConfig["onlineTrains"],
    redundantStandbyTrains: defaultInputRangeConfig["redundantStandbyTrains"],
    redundantTrains: { minValue: 0, maxValue: 200 },
    modulesPerTrain: defaultInputRangeConfig["modulesPerTrain"],
    skidsPerTrain: defaultInputRangeConfig["skidsPerTrain"],
  };

  const getFieldRange = id => {
    const { minValue, maxValue } = validations[id] || {};
    let range = { minValue, maxValue };
    if (id === "modulesPerSkid" && activeUFModule?.tRack) {
      const ufmoduleId = activeUFModule?.ufmoduleId;
      const ingeTROption = `TR${skidsPerTrain}`;
      const { min, max } = INGE_MODULE_TR_RANGES[ufmoduleId][ingeTROption];
      range = { minValue: min, maxValue: max };
    }
    return range;
  };

  const checkModulesPerSkidError = id => {
    const { minValue, maxValue } = getFieldRange(id);
    const isModulesPerSkidInvalid = id === "modulesPerSkid" && isModulePerRackInvalid();
    const isError = minValue > modulesPerSkid || maxValue < modulesPerSkid || isModulesPerSkidInvalid;
    return isError;
  };

  const checkError = id => {
    const { minValue, maxValue } = validations[id] || {};
    return !(minValue <= skidsPerTrain && maxValue >= skidsPerTrain);
  };

  const checkWarning = name => {
    if ("modulesPerSkid" === name && activeUFModule?.tRack) return false;
    const value = data[name];
    if (validations[name]) {
      const { minValue, softLowerLimit, softUpperLimit, maxValue } = validations[name];
      return value >= minValue && value < maxValue && (value < softLowerLimit || value > softUpperLimit);
    }
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    const numericValue = value ? +value : "";

    if (!isNaN(numericValue)) {
      dispatch(updateUFStore({ ...data, [name]: numericValue }));
    }
  };

  const isNumberEven = number => number % 2 === 0;
  const isModulePerRackInvalid = () =>
    activeUFModule?.tRack && !isNumberEven(modulesPerSkid) && selectedTROption !== INGE_TR_OPTION_WITH_ODD_VALUE;

  const validateModulesPerUnit = value => {
    const multiplier = INGE_MODULE_TR_MULTIPLIER[selectedTROption];
    const isInvalid = value % multiplier !== 0;
    setInputErrMsg(isInvalid ? `Value must be a multiple of ${multiplier}` : null);
    return isInvalid;
  };

  const handleBlur = ({ target }) => {
    const { id, name, value } = target;
    const { modulesPerSkid, modulesPerTrain, skidsPerTrain } = defaultInputRangeConfig;
    const configMap = {
      modulesPerSkid,
      onlineTrains: fieldOnlineTrains,
      redundantStandbyTrains: redundantStandbyTrains1,
      redundantTrains: { minValue: 0, maxValue: 200 },
      modulesPerTrain,
      skidsPerTrain,
    };

    if (activeUFModule?.tRack && name === "modulesPerTrain") {
      if (validateModulesPerUnit(value)) return;
    }

    const config = configMap[name];
    const { minValue, maxValue } = id === "modulesPerSkid" ? getFieldRange(id) : config;
    const isValueInvalid = isNaN(value) || value < minValue || value > maxValue;
    const isModulesPerSkidInvalid = id === "modulesPerSkid" && isModulePerRackInvalid();

    if (isValueInvalid || isModulesPerSkidInvalid) {
      setIsFieldValid(true);
      const field = MesageMap[name];
      const revisitText = "Please revise your input.";
      const errorMessage = isValueInvalid
        ? `${field} value entered is outside the allowed range (${minValue} to ${maxValue}). ${revisitText}`
        : `${field} value entered is not an even number. ${revisitText}`;
      setMessage(errorMessage);
      setTimeout(() => target.focus(), 0);
    } else {
      setIsFieldValid(false);
      const calculatedUFFields = calculateUFFields(data, activeUFModule);
      setTimeout(
        () =>
          updateStoreData({
            isUfDataUpdated: true,
            data: calculatedUFFields,
            isCustomConfigAvail: false,
            calcEngineDataRefreshCount: 1,
            isRecommendationCalucalting: true,
          }),
        200,
      );
    }
  };

  const handleRadioChange = ({ target }) => setSelectedTROption(target.value);
  const isIngeTRModule = useMemo(() => Boolean(ingeModuleRackOptions), [ingeModuleRackOptions]);

  const onHelpBtnClick = () => setIsHelpDlg(oldVal => !oldVal);
  const hanldeHelpDlgClose = () => setIsHelpDlg(false);

  return (
    <>
      <div className='selected-configuration'>
        <CustomCard
          className='selected-configuration-card'
          customHeader={<CardHeader isHelpIcon onHelpBtnClick={onHelpBtnClick} />}
        >
          {(activeUFModule?.integraPacInd || activeUFModule?.tRack) && (
            <div className='rack-wrapper'>
              <CustomHeading className='track-design' fontSize='10px' label={labelStrings.selectedConfRackLabel} />
              <div className='rack-module'>
                {isIngeTRModule ? (
                  <div>
                    <>
                      {Object.keys(ingeModuleRackOptions).map(trOption => (
                        <div key={`${trOption}`} className='radio'>
                          <CustomRadio
                            name='TrRadio'
                            id={trOption}
                            value={trOption}
                            checked={selectedTROption === trOption}
                            label={ingeModuleRackOptions[trOption].label}
                            disabled
                            onChange={handleRadioChange}
                          />
                        </div>
                      ))}
                    </>
                  </div>
                ) : (
                  <>
                    <div className='racks'>
                      <CustomLabel label='Racks/Units' />
                      <CustomInput
                        type='text'
                        isError={checkError("skidsPerTrain")}
                        name='skidsPerTrain'
                        id='skidsPerTrain'
                        value={skidsPerTrain}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        isWarning={checkWarning("skidsPerTrain")}
                        disabled={ingeModuleRackOptions}
                        tabIndex={1}
                      />
                    </div>
                    <div className='racks'>
                      <CustomLabel label='Modules/Rack' lineHeight='18px' />
                      <CustomInput
                        type='text'
                        isWarning={checkWarning("modulesPerSkid")}
                        isError={checkModulesPerSkidError("modulesPerSkid")}
                        name='modulesPerSkid'
                        id='modulesPerSkid'
                        value={modulesPerSkid}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        tabIndex={2}
                      />
                    </div>
                    <div className='racks'>
                      <CustomLabel label='Total No. of Racks' />
                      <CalcEngineInputBox isAutoPopulated name='skids' id='skids' value={skids} disabled />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          <div className='unit-wrapper'>
            <CustomHeading className='unit-header' fontSize='10px' label='Number of Units' />
            <div className='unit-group'>
              <div>
                <CustomLabel label='Online Units' />
                <CustomInput
                  type='text'
                  name='onlineTrains'
                  id='onlineTrains'
                  placeholder='0'
                  value={data.onlineTrains}
                  isError={checkError("onlineTrains")}
                  isWarning={checkWarning("onlineTrains")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  tabIndex={3}
                  disabled={isUFConfigLoading}
                />
              </div>
              <div>
                <CustomLabel label='Standby Units' />
                <CustomInput
                  type='text'
                  name='redundantStandbyTrains'
                  disabled={isUFConfigLoading || data.uFBWCEBStandbyOptionID == 1}
                  id='redundantStandbyTrains'
                  value={Number(data.redundantStandbyTrains)}
                  isError={checkError("redundantStandbyTrains")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  isWarning={checkWarning("redundantStandbyTrains")}
                  tabIndex={4}
                />
              </div>
              <div>
                <CustomLabel label='Redundant Unit' />
                <CustomInput
                  type='text'
                  name='redundantTrains'
                  id='redundantTrains'
                  value={data.redundantTrains}
                  isError={checkError("redundantTrains")}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  isWarning={checkWarning("redundantTrains")}
                  tabIndex={5}
                  disabled={isUFConfigLoading}
                />
              </div>
              <div>
                <CustomLabel label='Total Units' />
                <CustomInput
                  type='text'
                  name='totalTrains'
                  disabled
                  id='totalTrains'
                  value={data.totalTrains}
                  isError={false}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  tabIndex={6}
                />
              </div>

              <div>
                <CustomLabel label='Modules/Unit' />
                <CustomInput
                  type='text'
                  id='modulesPerTrain'
                  name='modulesPerTrain'
                  onBlur={handleBlur}
                  value={data.modulesPerTrain}
                  onChange={handleInputChange}
                  disabled={isUFConfigLoading || activeUFModule?.integraPacInd}
                  isError={checkError("modulesPerTrain")}
                  isWarning={checkWarning("modulesPerTrain")}
                  tabIndex={7}
                />
                <ErrorMessage
                  texMsg={inputErrMsg}
                  invisible={!inputErrMsg}
                  style={{ visibility: inputErrMsg ? "visible" : "hidden" }}
                />
              </div>
              <div>
                <CustomLabel label='Total Modules' />
                <CustomInput
                  type='text'
                  name='totalModules'
                  disabled
                  id='totalModules'
                  value={isNaN(data.totalModules) ? 0 : data.totalModules}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  tabIndex={8}
                />
              </div>
              <div>
                <CustomLabel label='Operating Flux' />
                <CalcEngineInputBox
                  type='number'
                  name='flux_Filter_actual'
                  inputText={unit?.selectedUnits[4]}
                  disabled
                  id='flux_Filter_actual'
                  value={flux_Filter_actual}
                />
              </div>
            </div>
          </div>
        </CustomCard>
      </div>
      {isFieldValid && <ProjectErrorPopup show message={message} close={() => setIsFieldValid(false)} />}
      {isHelpDlg && <UFSelectedConfigurationHelpDlg onClose={hanldeHelpDlgClose} standByOption={data.uFBWCEBStandbyOptionID}/>}
    </>
  );
};

export default UFSelectedConfiguration;
