import { CHEMICAL_SYMBOLS } from "@constants/chemical-symbol.constants";
import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

import { CHEMICALS_LIST } from "@features/chemicalLibrary/chemicalLibrary.strings";

export const CHEMICAL_WITH_NAME_PROP = ["Citric_acid", "Oxalic_acid"];

export const WPUfChemicalsTableColumns = [
  {
    field: "label", // custom field not present in the API response
    rowHeader: true,
  },
  {
    field: "Unit_cost",
    headerName: reportStrings.unitCost,
  },
  {
    field: "Dose",
    headerName: reportStrings.dose,
  },
  {
    field: "Volume",
    headerName: reportStrings.volume,
  },
  {
    field: "Cost",
    headerName: reportStrings.cost,
  },
];

// please note the processSteps are also seperate rows in the API response
// the key in the process steps is appended to form the key ex: FeCl3_Feed , Citric_acid_CEB1
export const WPUfChemicalsTableRowsData = [
  {
    apiRowKey: "FeCl3",
    chemical: CHEMICALS_LIST.ferricChloride,
    [CHEMICAL_SYMBOLS.ferricIon]: CHEMICALS_LIST.ferricIon,
    processSteps: ["Feed"],
  },
  {
    apiRowKey: "PACl",
    chemical: CHEMICALS_LIST.polyaluminiumChloride,
    [CHEMICAL_SYMBOLS.aluminiumIon]: CHEMICALS_LIST.polyaluminiumIon,
    processSteps: ["Feed"],
  },
  {
    apiRowKey: "Citric_acid",
    chemical: CHEMICALS_LIST.citricAcid,
    processSteps: ["CEB1", "Mini_CIP", "CIP"],
  },
  {
    apiRowKey: "HCl",
    chemical: CHEMICALS_LIST.hydrochloricAcid,
    processSteps: ["Feed", "CEB1", "Mini_CIP", "CIP"],
  },
  {
    apiRowKey: "H2SO4",
    chemical: CHEMICALS_LIST.sulfuricAcid,
    processSteps: ["Feed", "CEB1", "Mini_CIP", "CIP"],
  },
  {
    apiRowKey: "NaoCl",
    chemical: CHEMICALS_LIST.sodiumHypochlorite,
    processSteps: ["Feed", "BW", "CEB2", "CEB3", "Mini_CIP", "CIP"],
  },
  {
    apiRowKey: "NaOH",
    chemical: CHEMICALS_LIST.sodiumHydroxide,
    processSteps: ["CEB2", "Mini_CIP", "CIP"],
  },
  {
    apiRowKey: "Oxalic_acid",
    chemical: CHEMICALS_LIST.oxalicAcid,
    processSteps: ["CEB1", "Mini_CIP", "CIP"],
  },
  {
    apiRowKey: "Sodium_lauryl_sulfate",
    chemical: CHEMICALS_LIST.sodiumLaurylSulfate,
    processSteps: ["mCIP", "CIP"],
  },
  {
    apiRowKey: "Total_Chemical_Cost",
    label: reportStrings.totalChemicalCost,
  },
];

export const processStepsLabels = {
  Feed: reportStrings.feed,
  BW: reportStrings.bw,
  CEB1: reportStrings.ceb1,
  CEB2: reportStrings.ceb2,
  CEB3: reportStrings.ceb3,
  Mini_CIP: reportStrings.miniCip,
  CIP: reportStrings.cip,
  mCIP: reportStrings.miniCip,
};

export const WPUfChemicalsTableUnitMapping = {
  Unit_cost: {
    isCost: true,
    unit: UNIT_TYPES.WEIGHT,
    precision: 3,
  },
  Dose: {
    unit: UNITS.MiligramPerLiter,
    precision: 0, // overriding default unit precision
  },
  Volume: {
    // conversion of volume applies here ie. m3/d to gal/day or L/d , we can use ConverUnitPerUnit method for conversion but since only the numerator is being converted sticking with convertToMetric method
    unit: UNIT_TYPES.VOLUME_SOLUTION,
    unitSubText: "/" + UNITS.day,
    // precision exception, refer 145642
    precisionByUnit: {
      [UNITS.CUBIC_METER]: 4,
      [UNITS.gallon]: 2,
      [UNITS.liter]: 1,
    },
  },
  Cost: {
    isCost: true,
    unit: UNITS.day,
    precision: 2,
  },
};
