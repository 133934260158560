import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { DupontString, IngeString } from "@utils/StringConstants";

import CustomCard from "@components/CustomCard";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import DynamicLoadder from "@common/utils/DynamicLoadder";

import { INGE_MODULE_TR_RANGES } from "../../constants/UFConstants";

import UFConfigurationRangeSlider from "./UFConfigurationRangeSlider";
import UFConfigurationTable from "./UFConfigurationTable";

import "rc-slider/assets/index.css";

const UFRecommendedConfiguration = () => {
  const {
    activeUFModule,
    ufCompany,
    data: UFData,
    isUFConfigLoading,
    ingeTROption,
  } = useSelector(state => state.UFStore);
  const labelStrings = ufCompany === "INGE" ? IngeString : DupontString;

  const [sliderRange, setSliderRange] = useState({});

  useEffect(() => {
    if (activeUFModule?.tRack) {
      const ufmoduleId = activeUFModule?.ufmoduleId;
      const trOption = ingeTROption || `TR${UFData.skidsPerTrain}`;
      const step = 2;
      const { min, max } = INGE_MODULE_TR_RANGES[ufmoduleId][trOption];
      setSliderRange({ min, max, step });
    } else {
      setSliderRange({ min: 6, max: 22, step: 1 });
    }
  }, [activeUFModule, UFData.skidsPerTrain, ingeTROption]);

  const isLoading = useMemo(
    () => isUFConfigLoading || (activeUFModule?.tRack && !ingeTROption),
    [isUFConfigLoading, activeUFModule, ingeTROption],
  );

  return (
    <div className='card-wrapper-four'>
      <CustomCard
        header='Recommended Configurations'
        className='recomm-config'
        tooltipLabel='List of potential configurations which will meet the desired target operating flux.'
      >
        <DynamicLoadder isLoading={isLoading}>
          {(activeUFModule?.integraPacInd && sliderRange?.max && sliderRange?.min) && (
            <div className='tracks-wrapper'>
              <div className='limit'>
                <CustomHeading label={labelStrings.recommConfigSliderMinRange} />
              </div>
              <UFConfigurationRangeSlider {...sliderRange} />
              <div className='limit'>
                <CustomHeading label={labelStrings.recommConfigSliderMaxRange} />
              </div>
            </div>
          )}
          <UFConfigurationTable />
        </DynamicLoadder>
      </CustomCard>
    </div>
  );
};

export default UFRecommendedConfiguration;
