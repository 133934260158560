import React, { useEffect, useRef } from "react";

import { CaseManagementStrings } from "@utils/StringConstants";

import IconButton from "@components/IconButton";
import WPButton from "@components/WPButton";
import WPHeading from "@components/WPHeading";

import CloseIcon from "@common/icons/CloseIcon";

const CaseCustomMenu = React.forwardRef(
  ({ children, className, handleManageCases, handleClose, handleSubmenuClose, "aria-labelledby": labeledBy }, ref) => {
    CaseCustomMenu.displayName = "CustomMenu";
    const listRef = useRef(null);

    const handleScroll = () => {
      handleSubmenuClose();
    };

    useEffect(() => {
      listRef?.current?.addEventListener("scroll", handleScroll);

      return () => {
        listRef?.current?.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <div ref={ref} className={className} aria-labelledby={labeledBy}>
        <div className='manage-case-container flex-center'>
          <WPHeading text={CaseManagementStrings.cases} color='var(--du-color-grey-900)' className='case-heading' />
          <div className='margin-left-auto flex-center'>
            <WPButton onClick={handleManageCases} variant='text' customClass='manage-case-button'>
              {CaseManagementStrings.manageCase}
            </WPButton>
            <IconButton tooltip={CaseManagementStrings.close} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <ul className='list-unstyled case-dropdown-list' ref={listRef}>
          {React.Children.toArray(children).filter(child => child.props.children)}
        </ul>
      </div>
    );
  },
);

export default CaseCustomMenu;
