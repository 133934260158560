import { useDispatch } from "react-redux";

import { btnlist } from "@features/home/CardListSlice";

const useSearchFunction = () => {
  const dispatch = useDispatch();

  const searchFunction = (Technology, branName, TempStoreData, txtbrand) => {
    if (branName == null) {
      branName = txtbrand;
    }
    if (parseInt(Technology.technologes.length) > 0 && branName !== null) {
      const array = Technology.response;
      const _RecentProject1 = TempStoreData?.filter(function (item) {
        for (let i = 0; i < array.length; i++) {
          if (item.technologyName?.includes(array[i])) return true;
        }
        return false;
      });
      if (branName !== "All") {
        const _RecentProject = _RecentProject1.filter(item => item.segmentName?.includes(branName));
        dispatch(btnlist(_RecentProject));
      } else {
        dispatch(btnlist(_RecentProject1));
      }
    } else if (parseInt(Technology.technologes.length) > 0) {
      const array = Technology.technologes;
      const _RecentProject = TempStoreData.filter(function (item) {
        for (let i = 0; i < array.length; i++) {
          if (item.technologyName?.includes(array[i])) return true;
        }
        return false;
      });
      dispatch(btnlist(_RecentProject));
    } else if (branName !== null) {
      if (branName !== "All") {
        const _RecentProject = TempStoreData.filter(item => item.segmentName?.includes(branName));
        dispatch(btnlist(_RecentProject));
      } else {
        dispatch(btnlist(TempStoreData));
      }
    } else {
      dispatch(btnlist(TempStoreData));
    }
  };

  return searchFunction;
};

export default useSearchFunction;
