import { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteRequest } from "src/api";

import API_URLS from "@constants/api.urls";
import { CustomModalButtons, PROJECT_NAME_MAX_LENGTH, PROJECT_TYPE, TECHNOLOGIES } from "@constants/global.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { useExportProject } from "@hooks/useExportProject";
import useSearchFunction from "@hooks/useSearchFilter";

import { CommonButtonStrings, ProjectStrings } from "@utils/StringConstants";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import { useToastNotification } from "@components/WPToastNotification";

import CloseBlackIcon from "@common/icons/CloseBlackIcon";
import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import CreateNewProjectPlusIcon from "@common/icons/CreateNewProjectPlusIcon";
import ShortUpArrow from "@common/icons/ShortUpArrow";
import SortDownArrow from "@common/icons/SortDownArrow";
import { updateProjectInfo } from "@common/ProjectInfoSlice";
import StandardPrimaryButton from "@common/styles/components/buttons/standard/StandardPrimaryButton";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import StyledModal from "@common/styles/components/modals/CustomModal";
import { colors } from "@common/styles/Theme";
import { MyError } from "@common/utils/ErrorCreator";
import FavRemovePopup from "@common/utils/FavRemovePopup";

import {
  useDeleteDataMutation,
  useDeleteDataRecordMutation,
  useLazyGetAllDataQuery,
  useUpdateDataMutation,
} from "@services/apiConfig";

import { btnlist, sortData, sortData2, Tempbtnlist, updateFlag, updateLoader } from "../home/CardListSlice";
import CreateNewProjectModal from "../modals/CreateNewProjectModal";
import SendProjectModal from "../modals/SendProjectModal";

import CardListStyled, { NewStyledCard } from "./CardListStyled";
import DeleteProjectPopup from "./DeleteProjectPopup";
import ProjectList from "./ProjectItem";

import "react-toastify/dist/ReactToastify.css";

const CardList = () => {
  const dispatch = useDispatch();
  const { exportProject, duplicateProject } = useExportProject();

  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userId = UserInfoStore ? UserInfoStore.UserId : 1;
  const [Cardstate, setCard] = useState([]);
  const [flagstate, setflagstate] = useState(true);
  const userID = userId;
  const StoreData = useSelector(state => state.cardlist.data);
  const title = useSelector(state => state.cardlist.title);
  const order = useSelector(state => state.cardlist.order);

  const { pTitle } = useSelector(state => state.cardlist);
  const StoreSidLeftpanel = useSelector(state => state.leftpanel.data);
  const activeMenu = useSelector(state => state.leftpanel.activeMenu);
  const FolderProjectIDLeftpanel = useSelector(state => state.FolderProjectleftpanel.data);
  const [isPreviewShown, setPreviewShown] = useState(false);
  const [getData, responseInfo] = useLazyGetAllDataQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState({
    projectID: "",
    projectName: "",
    prevProjectName: "",
  });
  const [Message, setMessage] = useState("");
  const [GetRenameData, { data }] = useUpdateDataMutation("");
  const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [lblMessage, setlabelMesg] = useState(null);
  const [idDel, setIdDel] = useState(null);
  const [deletedProject, setDeletedProject] = useState(null);
  const [permanentDelProject, setPermanentDeleteProject] = useState(null);
  const [MethodName, setMethodName] = useState(null);
  const [IsDeleted, setIsDeleted] = useState(null);
  const [isFocused, setIsFocused] = useState(null);
  const [selectTitle, setSelectTitle] = useState("");
  const [popupOperator, setPopupOperator] = useState({
    message: "",
    show: false,
    type: "",
    subtext: "",
  });
  const { showSuccessNotification, showErrorNotification } = useToastNotification();
  const { selectedBrand, filterText, isIX: ixCheck, isUF: ufCheck } = useSelector(state => state.globalAppData.filter);

  const searchFunction = useSearchFunction();

  const [sendProject, setSendProject] = useState({
    show: false,
    projectName: "",
    projectId: 0,
  });

  const handleFocus = id => {
    setIsFocused(id);
  };
  const handleBlur = () => {
    setIsFocused(null);
  };

  const deleteProject = async (id, isPermanentDelete) => {
    const payload = { isPermanent: isPermanentDelete, projectIds: [id] };
    try {
      await deleteRequest(API_URLS.project, payload);
      const newData = StoreData.filter(item => item.projectID !== id);
      dispatch(btnlist(newData));
      showSuccessNotification(APP_TOAST_NOTIFICATION.PROJECT_DELETED);
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.PROJECT_DELETE_FAILED);
    }
  };

  const handleDeleteWarningClose = async id => {
    if (CustomModalButtons.CONFIRM === id) {
      deleteProject(permanentDelProject?.ProjectId ?? deletedProject?.ProjectId, Boolean(permanentDelProject));
    }
    setDeletedProject(null);
    setPermanentDeleteProject(null);
  };

  const handleOpenPermanentDelete = id => setPermanentDeleteProject(id);
  const handleOpenDelete = id => setDeletedProject(id);

  const handleFolderPermanentDelete = id => {
    setIdDel(id);
    const objMethod = {
      Method: "masterdata/api/v1/PermanentDeleteFolder",
      userID: userId,
      folderId: id,
    };
    setMethodName(objMethod);
    setDeleteSuccessModal(true);
    setlabelMesg("Are you sure you want to delete this Folder?");
    setIsDeleted("F");
  };

  const handleRestoreFolder = id => {
    setIdDel(id);
    const objMethod = {
      Method: "masterdata/api/v1/RestoreFolder",
      userID: userId,
      folderId: id,
    };
    setMethodName(objMethod);
    setDeleteSuccessModal(true);
    setlabelMesg("Are you sure you want to restore this Folder?");
    setIsDeleted("RF");
  };

  const handleOpenRestoreProject = id => {
    setIdDel(id);
    const objMethod = {
      Method: "masterdata/api/v1/RestoreProject",
      userID: userId,
      projectID: id,
    };
    setMethodName(objMethod);
    setDeleteSuccessModal(true);
    setlabelMesg("Are you sure you want to restore this project?");
    setIsDeleted("R");
  };
  const handleOpenRenameProject = items => {
    const { ProjectId, title } = items;
    setIsOpen(true);
    setInputValue({ projectID: ProjectId, projectName: title, prevProjectName: title });
    document.body.classList.add("disable");
  };
  const handleCloseRenameProject = () => {
    setIsOpen(false);
    document.body.classList.remove("disable");
  };
  const handleOpenSendProject = ({ ProjectId, title }) => {
    setSendProject({ show: true, projectName: title, projectId: ProjectId });
  };
  const handleInputChange = e => {
    const myvalus = e.target.value;
    const Rename = e.target.value;
    const ProjectId = inputValue.projectID;
    const index = Cardstate.findIndex(item => item.title === myvalus);
    if (myvalus.trim() === "") {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("This field cannot be empty, please enter a project name.");
      setError(true);
      return;
    } else if (myvalus.length < 3) {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("Project Name,Three Minimum Length Required !!");
      setError(true);
      return;
    } else if (index > -1) {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("Data Exist !!");
      setError(true);
      return;
    } else {
      setInputValue({ ...inputValue, projectID: ProjectId, projectName: Rename });
      setMessage("");
      setError(false);
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (inputValue.projectName.trim() === "") {
      setError(true);
      return;
    }
    if (inputValue.length < 3) {
      setPopupOperator({
        type: "warning",
        show: true,
        message: "Minimum 3 characters required !!",
        subtext: "",
      });
      setError(true);
      return;
    } else {
      const newData = {
        Method: "masterdata/api/v1/RenameProject",
        userID: userId,
        projectID: inputValue.projectID,
        projectName: inputValue.projectName,
      };

      const ResponseValues = await GetRenameData(newData);
      const prevTitle =
        inputValue.prevProjectName.length > PROJECT_NAME_MAX_LENGTH
          ? `${inputValue.prevProjectName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
          : inputValue.prevProjectName;
      const newTitle =
        inputValue.projectName.length > PROJECT_NAME_MAX_LENGTH
          ? `${inputValue.projectName.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
          : inputValue.projectName;
      if (ResponseValues.data.responseMessage == "Success") {
        setPopupOperator({
          type: "success",
          show: true,
          message: `Project Name ${prevTitle} has been successfully renamed to ${newTitle}.`,
          subtext: "",
        });
        if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
          getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
        } else {
          getData(`${StoreSidLeftpanel}?userID=${userID}`);
        }
        LoadRecord();
      } else {
        toast.success(ResponseValues.data.responseMessage, "Record not Update !!", {
          position: toast.POSITION.TOP_CENTER,
          className: "toast-center",
        });
      }

      // setInputValue(undefined);
      setIsOpen(false);
      document.body.classList.remove("disable");
    }
  };
  useEffect(() => {
    if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
      getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
    } else {
      getData(`${StoreSidLeftpanel}?userID=${userID}`);
    }
  }, [StoreSidLeftpanel, FolderProjectIDLeftpanel, activeMenu]);

  useEffect(() => {
    const handleResponse = () => {
      if (responseInfo.isLoading) {
        dispatch(updateLoader(true));
        return;
      }

      if (responseInfo.isError) {
        throw new MyError("responseInfo Api Error", responseInfo.error.status, "ApiError");
      }

      if (responseInfo.isSuccess) {
        let data = responseInfo.data;

        if (data["lstprojectInfoHomePageVM"] || data["lstfolderDeleteVM"]) {
          const updatedRes = data["lstfolderDeleteVM"].map(item => ({
            ...item,
            projectID: 0,
            projectName: item.folderName,
          }));
          data = [...data["lstprojectInfoHomePageVM"], ...updatedRes];
        } else if (activeMenu === PROJECT_TYPE.IMPORTED) {
          data = data.filter(project => project.isImported);
        }
        dispatch(Tempbtnlist(data));
        dispatch(sortData2({ flag: pTitle, data }));
        dispatch(updateLoader(false));

        const techArr = [...(ixCheck ? [TECHNOLOGIES.IX] : []), ...(ufCheck ? [TECHNOLOGIES.UF] : [])];
        searchFunction({ technologes: techArr, response: techArr }, selectedBrand, data, filterText);
      }
    };

    handleResponse();
  }, [responseInfo, activeMenu, pTitle, dispatch]);

  const [deletePost, response] = useDeleteDataMutation();
  const [FavouritePost, responseFavorite] = useDeleteDataRecordMutation();
  useEffect(() => {
    if (response.isSuccess) {
      toast.success("Project deleted successfully !", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-center",
      });
      if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
        getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
      } else {
        getData(`${StoreSidLeftpanel}?userID=${userID}`);
      }
      LoadRecord();
    }
  }, [response]);
  useEffect(() => {
    try {
      if (responseFavorite.isSuccess) {
        const newTitle =
          selectTitle.length > PROJECT_NAME_MAX_LENGTH
            ? `${selectTitle.substring(0, PROJECT_NAME_MAX_LENGTH)}...`
            : selectTitle;
        if (flagstate === true) {
          setPopupOperator({
            message: "Project added to Favourite Projects successfully! ",
            show: true,
            type: "success",
            subtext: `${newTitle} has been added to Favourite Projects successfully.`,
          });
        } else {
          setPopupOperator({
            message: "Project removed from Favourite Projects successfully!.",
            show: true,
            type: "success",
            subtext: `${newTitle} has been removed from Favourite Projects successfully.`,
          });
        }
        if (StoreSidLeftpanel == "masterdata/api/v1/FolderProject") {
          getData(`${StoreSidLeftpanel}?folderID=${FolderProjectIDLeftpanel}`);
        } else {
          getData(`${StoreSidLeftpanel}?userID=${userID}`);
        }
        LoadRecord();
      }
    } catch {
      console.log("Error in use effect responseFavorite");
    }
  }, [responseFavorite]);

  const FavouriteRecord = items => {
    const { ProjectId, favorite, title } = items;
    dispatch(updateLoader(false));
    setSelectTitle(title);
    const flag = favorite === true ? false : true;
    setflagstate(flag);
    const data1 = {
      Method: "masterdata/api/v1/FavoriteProject",
      userID: userId,
      projectID: ProjectId,
      favorite: flag,
    };
    FavouritePost(data1);
  };

  useEffect(() => {
    LoadRecord();
  }, [StoreData]);

  const LoadRecord = () => {
    setCard([]);
    if (StoreData === 0) {
      <div>Loading....</div>;
    } else {
      dispatch(updateLoader(true));
      setCard([]);
      StoreData.forEach(element => {
        setCard(current => [
          ...current,
          {
            favorite: element.favorite,
            ProjectId: element.projectID || 0,
            title: element.projectName || element.folderName,
            description: element.modifiedDuration,
            createDate: element.createDateDuration,
            image: "./images/list-view-icon.svg",
            tagName: element.tagName,
            isDeleted: element.isDeleted,
            folderName: element.folderName,
            folderNameDeleted: element.folderName,
            folderDeletedDuration: element.modifiedDuration,
            folderIdDeleted: element.folderID || 0,
            technologyName: element.technologyName,
          },
        ]);
      });
    }
  };

  const handleExportProject = projectInfo => {
    exportProject(projectInfo.ProjectId, projectInfo.title);
  };

  const handleOnclick2 = item => {
    const obj = {};
    const _projectId = item.ProjectId ? item.ProjectId : 0;
    if (_projectId !== 0) {
      obj.projectID = _projectId;
      obj.caseId = 0;
      obj.projectName = item.title;
      obj.Tchnology = item.technologyName;
      obj.isImported = item.isImported;
      dispatch(updateProjectInfo(obj));
    }
  };

  const handleUpdateProject = (item, folderId, foldername) => {
    if (item) {
      const obj = {};
      const _projectId = item.ProjectId ? item.ProjectId : 0;
      if (_projectId !== 0) {
        obj.projectID = _projectId;
        obj.caseId = 0;
        obj.projectName = item.title;
        obj.Tchnology = item.technologyName;
        obj.folderName = foldername;
        obj.folderId = folderId;
        obj.isImported = item.isImported;
        dispatch(updateProjectInfo(obj));
      }
    }
  };

  const updateCreateprojc = e => {
    e.preventDefault();
    setPreviewShown(false);
  };

  const handleSortOnDateCreated = () => {
    dispatch(sortData("CD"));
    dispatch(updateFlag("Date Created"));
  };
  const handleSortDateModified = () => {
    dispatch(sortData("MD"));
    dispatch(updateFlag("Last Modified"));
  };
  const handleSortOnProjectName = () => {
    dispatch(sortData("PN"));

    if (order === "A") {
      dispatch(updateFlag("Project Name (descending)"));
    } else {
      dispatch(updateFlag("Project Name (ascending)"));
    }
  };
  const btnCreateprojct = e => {
    e.preventDefault();
    setPreviewShown(true);
  };
  const handleCLoseOperator = () => {
    setPopupOperator({
      show: false,
      message: "",
      type: "",
    });
  };

  return (
    <>
      {["masterdata/api/v1/ProjectDeleted", "masterdata/api/v1/FolderProject"].includes(StoreSidLeftpanel) ? (
        ""
      ) : (
        <NewStyledCard className='create_new_project_list_view' onClick={btnCreateprojct}>
          <Card.Body className='create-project'>
            <div className='create-project-icon'>
              <CreateNewProjectPlusIcon />
            </div>
            <div className='project_name'>
              <CustomHeading fontFamily='NotoSansSemiBold' fontWeight={"700"}>
                {"Create New Project"}
              </CustomHeading>
            </div>
          </Card.Body>
        </NewStyledCard>
      )}

      {isPreviewShown ? (
        <CreateNewProjectModal CPmodal={updateCreateprojc} show={isPreviewShown} close={setPreviewShown} />
      ) : (
        ""
      )}
      {responseInfo?.data?.length == 0 ? null : (
        <>
          <CardListStyled>
            {!StoreData.length ? (
              ""
            ) : (
              <>
                <div className='list-view-header'>
                  <div className='project_hidden'></div>
                  <div className='project_name_header_title'>
                    <CustomHeading
                      className={"list_header_sort_title"}
                      fontSize={"18px"}
                      color={colors.blackTransparency045}
                      onClick={handleSortOnProjectName}
                    >
                      Project Name
                    </CustomHeading>
                    {title === "PN" ? (
                      <div>
                        {order === "D" ? (
                          <div className='arrow-updown-icon' onClick={handleSortOnProjectName}>
                            <SortDownArrow />
                          </div>
                        ) : (
                          <div className='arrow-updown-icon' onClick={handleSortOnProjectName}>
                            <ShortUpArrow />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className='created_date_header_title'>
                    <CustomHeading
                      className={"list_header_sort_title"}
                      fontSize={"18px"}
                      color={colors.blackTransparency045}
                      onClick={handleSortOnDateCreated}
                    >
                      Date Created
                    </CustomHeading>
                    {title === "CD" ? (
                      <div>
                        {order === "D" ? (
                          <div className='arrow-updown-icon' onClick={handleSortOnDateCreated}>
                            <SortDownArrow onClick={handleSortOnDateCreated} />
                          </div>
                        ) : (
                          <div className='arrow-updown-icon' onClick={handleSortOnDateCreated}>
                            <ShortUpArrow onClick={handleSortOnDateCreated} />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className='last_modified_header_title'>
                    <CustomHeading
                      className={"list_header_sort_title"}
                      fontSize={"18px"}
                      color={colors.blackTransparency045}
                      onClick={handleSortDateModified}
                    >
                      Last Modified
                    </CustomHeading>
                    {title === "MD" ? (
                      <div>
                        {order === "D" ? (
                          <div className='arrow-updown-icon' onClick={handleSortDateModified}>
                            <SortDownArrow onClick={handleSortDateModified} />
                          </div>
                        ) : (
                          <div className='arrow-updown-icon' onClick={handleSortDateModified}>
                            <ShortUpArrow onClick={handleSortDateModified} />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className='projects_tags_header'>
                    <CustomHeading fontSize={"18px"} color={colors.blackTransparency045}>
                      Tags
                    </CustomHeading>
                  </div>
                  <div className='project_hidden'></div>
                </div>
                {
                  <DeleteProjectPopup
                    show={deleteSuccessModal}
                    close={() => setDeleteSuccessModal(false)}
                    id={idDel}
                    methodName={MethodName}
                    IsDeleted={IsDeleted}
                    lblMessage={lblMessage}
                  />
                }
                {
                  <ProjectList
                    Cardstate={Cardstate}
                    StoreSidLeftpanel={StoreSidLeftpanel}
                    handleOnclick2={handleOnclick2}
                    FavouriteRecord={FavouriteRecord}
                    handleOpenRestoreProject={handleOpenRestoreProject}
                    handleDelete={handleOpenDelete}
                    handleOpenPermanentDelete={handleOpenPermanentDelete}
                    handleOpenRenameProject={handleOpenRenameProject}
                    handleOpenSendProject={handleOpenSendProject}
                    handleUpdateProject={handleUpdateProject}
                    handleFolderPermanentDelete={handleFolderPermanentDelete}
                    handleRestoreFolder={handleRestoreFolder}
                    handleExportProject={handleExportProject}
                    handleDuplicateProject={duplicateProject}
                  ></ProjectList>
                }
              </>
            )}
          </CardListStyled>
          <StyledModal
            show={isOpen}
            onHide={handleCloseRenameProject}
            backdrop='static'
            keyboard='false'
            centered
            maxWidth='572px'
            backgroundColor={colors.GreyF8}
          >
            <Modal.Header backgroundColor={colors.GreyF8}>
              <CustomHeading
                fontFamily='DiodrumSemiBold'
                fontSize='16px'
                fontWeight='600'
                color={colors.PrimaryDarkAquaMarine}
                label={ProjectStrings.renameThisProject}
              />
              {/* <h1> </h1> */}
              <div className='close-icon' onClick={handleCloseRenameProject}>
                <CloseBlackIcon />
              </div>
            </Modal.Header>
            <Modal.Body>
              <CustomLabel label='Project Name' />
              <InputWithIcon
                isError={error}
                onClick
                type='text'
                onBlur={handleBlur}
                onFocus={() => handleFocus(1)}
                isFocused={isFocused === 1}
                maxlength='200'
                inputText={error ? <CloseCircleRedIcon /> : <CloseCircleGreenIcon />}
                unitBgColor='transparent'
                minLength='3'
                value={inputValue.projectName}
                onChange={handleInputChange}
              />
              <ErrorMessage errorIcon={true} style={{ visibility: error ? "visible" : "hidden" }} texMsg={Message} />
            </Modal.Body>
            <Modal.Footer>
              <StandardSecondaryButton variant='light' onClick={handleCloseRenameProject} id='canBtn' label='Cancel' />
              <StandardPrimaryButton
                type='submit'
                id=''
                variant='light'
                disabled={error ? "true" : ""}
                onClick={handleSubmit}
                label='Submit'
              />
            </Modal.Footer>
          </StyledModal>
          <FavRemovePopup operator={popupOperator} close={handleCLoseOperator} />
          <SendProjectModal
            show={sendProject.show}
            projectId={sendProject.projectId}
            projectName={sendProject.projectName}
            close={() => setSendProject({ show: false, projectName: "", projectId: 0 })}
          />
          {deletedProject && (
            <ConfirmationPopup
              header={ProjectStrings.deleteProject}
              description={ProjectStrings.deleteNote}
              type={DIALOG_TYPES.WARNING}
              onClose={handleDeleteWarningClose}
              confirmBtn={CommonButtonStrings.delete}
              cancelBtn={CommonButtonStrings.cancel}
            />
          )}
          {permanentDelProject && (
            <ConfirmationPopup
              header={ProjectStrings.deleteProject}
              description={ProjectStrings.deleteNotePermanent}
              type={DIALOG_TYPES.ERROR}
              onClose={handleDeleteWarningClose}
              confirmBtn={CommonButtonStrings.okay}
              cancelBtn={CommonButtonStrings.cancel}
            />
          )}
        </>
      )}
    </>
  );
};

export default CardList;
