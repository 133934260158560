import { reportStrings } from "@constants/report.strings";

import { WPUfParametersDataMapping } from "../../WPUfParametersTables.constants";
import { generateStepRowData } from "../../WPUfParametersTablesUtils";

export const WPUfIngeCebParametersTableColumns = [
  {
    field: "col1",
    rowHeader: true,
    headerName: reportStrings.pumpAndValveConditions,
  },
  {
    field: "col2",
    headerName: reportStrings.operation,
  },
  {
    field: "col3",
    headerName: reportStrings.cebFullForm,
    colSpan: 6,
  },
  {
    field: "col4",
    colSpan: 0,
  },
  {
    field: "col5",
    colSpan: 0,
  },
  {
    field: "col6",
    colSpan: 0,
  },
  {
    field: "col7",
    colSpan: 0,
  },
  {
    field: "col8",
    colSpan: 0,
  },
  {
    field: "col9",
    headerName: reportStrings.returnToOpertaion + "<sup>d</sup>",
  },
];

export const WPUfIngeCebParametersTableRows = [
  {
    rowKey: "stepNumber",
    ...generateStepRowData(2, 7),
    col9: 1,
  },
  {
    rowKey: "stepLabel",
    col2: reportStrings.filtrationMode,
    col3: reportStrings.backwashBeforeCEB,
    col4: reportStrings.cebInjectionDrainBottom + "<sup>b</sup>",
    col5: reportStrings.cebInjectionDrainTop + "<sup>b</sup>",
    col6: reportStrings.soak,
    col7: reportStrings.rinse1,
    col8: reportStrings.rinse2,
    col9: reportStrings.filtrationMode,
  },
  {
    rowKey: "feedPump",
    col1: reportStrings.feedPump,
    col2: true,
    col9: true,
  },
  {
    rowKey: "backwashPump",
    col1: reportStrings.backwashPump,
    col3: true,
    col4: true,
    col5: true,
    col7: true,
    col8: true,
  },
  {
    rowKey: "chemDosingPump",
    col1: reportStrings.chemDosingPump,
  },
  {
    rowKey: "cebDosingPump",
    col1: reportStrings.cebDosingPump + "<sup>a,c,d</sup>",
    col4: true,
    col5: true,
  },
  {
    rowKey: "cipRecyclePump",
    col1: reportStrings.cipRecyclePump,
  },
  {
    rowKey: "feedValve",
    col1: reportStrings.feedValve,
    col2: true,
    col9: true,
  },
  {
    rowKey: "filtrateValve",
    col1: reportStrings.filtrateValve,
    col2: true,
    col9: true,
  },
  {
    rowKey: "concentrateValve",
    col1: reportStrings.concentrateValve,
    col3: true,
    col4: true,
    col5: true,
    col7: true,
    col8: true,
  },
  {
    rowKey: "backwashInletValve",
    col1: reportStrings.backwashInletValve,
    col3: true,
    col4: true,
    col5: true,
    col7: true,
    col8: true,
  },
  {
    rowKey: "duration",
    col1: reportStrings.duration,
    col2: WPUfParametersDataMapping.t_filtration_cycle,
    col3: WPUfParametersDataMapping.backwashBeforeCeb,
    col4: WPUfParametersDataMapping.t_BW1_CEB,
    col5: WPUfParametersDataMapping.t_BW2_CEB,
    col6: WPUfParametersDataMapping.t_CEB_soak,
    col7: WPUfParametersDataMapping.t_BW1_CEBRinse,
    col8: WPUfParametersDataMapping.t_BW2_CEBRinse,
    col9: WPUfParametersDataMapping.t_filtration_cycle,
  },
  {
    rowKey: "flowRate",
    col1: reportStrings.flowRate,
    col2: WPUfParametersDataMapping.Flux_Filter_actual,
    col3: WPUfParametersDataMapping.Flux_BW,
    col4: WPUfParametersDataMapping.Flux_CEB,
    col5: WPUfParametersDataMapping.Flux_CEB,
    col6: WPUfParametersDataMapping.flowZero,
    col7: WPUfParametersDataMapping.Flux_BW,
    col8: WPUfParametersDataMapping.Flux_BW,
    col9: WPUfParametersDataMapping.Flux_Filter_actual,
  },
];
