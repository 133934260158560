import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import useProjectDetails from "@hooks/useProjectDetails";
import { useResetProjectData } from "@hooks/useResetReduxData";
import useUpdateCase from "@hooks/useUpdateCase";

import { swapData } from "@utils/appUtils";
import { CaseManagementStrings } from "@utils/StringConstants";

import { DeleteIcon } from "@components/SVGs";
import ArrowIconDown from "@components/SVGs/ArrowIconDown";
import ArrowIconUp from "@components/SVGs/ArrowIconUp";
import OpenCaseIcon from "@components/SVGs/OpenCaseIcon";
import { useToastNotification } from "@components/WPToastNotification";

import { updateCaseConfig } from "@common/ProjectInfoSlice";

const MenuOptionWithIcon = (icon, title, handleOnClick) => (
  <div className='option-item' onClick={handleOnClick}>
    <div className='margin-right-10'> {icon}</div>
    <span>{title}</span>
  </div>
);

const CaseOptionsMenu = ({
  cases,
  setCases,
  deleteCaseId,
  currentActiveCase,
  selectedCaseIndex,
  setShowSubmenu,
  handleOpenCase,
  handleToggleDropdown,
}) => {
  const dispatch = useDispatch();
  const { resetProjectData } = useResetProjectData();
  const { activeCaseId } = useProjectDetails();
  const { updateCase, loading, caseUpdateError } = useUpdateCase();
  const { showErrorNotification } = useToastNotification();

  const switchToCase = () => {
    resetProjectData();
    handleOpenCase(cases[selectedCaseIndex]);
    setShowSubmenu(null);
    handleToggleDropdown();
  };

  const updateOnServer = async updatedCases => {
    const res = await updateCase(updatedCases);
    if (!loading) {
      if (caseUpdateError) {
        showErrorNotification(APP_TOAST_NOTIFICATION.CASE_MOVE_FAILED);
      } else {
        dispatch(updateCaseConfig([...updatedCases]));
        setCases([...updatedCases]);
      }
    }
  };

  const swapCases = (index1, index2) => {
    const newCases = swapData(cases, index1, index2);
    updateOnServer(newCases);
  };

  const moveUp = () => {
    if (selectedCaseIndex > 0) {
      swapCases(selectedCaseIndex, selectedCaseIndex - 1);
    }
    setShowSubmenu(null);
  };

  const moveDown = () => {
    if (selectedCaseIndex < cases.length - 1) {
      swapCases(selectedCaseIndex, selectedCaseIndex + 1);
    }
    setShowSubmenu(null);
  };

  const deleteCase = () => {
    deleteCaseId(currentActiveCase.caseID);
    setShowSubmenu(null);
  };

  return (
    <>
      {activeCaseId !== currentActiveCase.caseID && (
        <div className='dropdown-item-custom'>
          {MenuOptionWithIcon(<OpenCaseIcon />, CaseManagementStrings.switchToCase, switchToCase)}
        </div>
      )}
      {selectedCaseIndex !== 0 && (
        <div className='dropdown-item-custom'>
          {MenuOptionWithIcon(<ArrowIconUp />, CaseManagementStrings.moveUp, moveUp)}
        </div>
      )}
      {selectedCaseIndex !== cases.length - 1 && (
        <div className='dropdown-item-custom'>
          {MenuOptionWithIcon(<ArrowIconDown />, CaseManagementStrings.moveDown, moveDown)}
        </div>
      )}
      {activeCaseId !== currentActiveCase.caseID && (
        <div className='dropdown-item-custom'>
          {MenuOptionWithIcon(<DeleteIcon width='16' height='16' />, CaseManagementStrings.deleteCase, deleteCase)}
        </div>
      )}
    </>
  );
};

export default CaseOptionsMenu;
