import { useSelector } from "react-redux";

import { reportStrings } from "@constants/report.strings";

import { WPTable } from "@components/WPTable";

import { WPUfDupontUxaCipParametersTableColumns as columns } from "./WPUfDupontUxaCipParametersTable.constants";

export const WPUfDupontUxaCipParametersTable = () => {
  const { uf_cip_parameters: rows } = useSelector(state => state.report.ufReportTableData);

  return (
    <WPTable
      tableLayout='fixed'
      sectionHeader={reportStrings.cipUxaParameters}
      columns={columns}
      rows={rows}
      remarksList={[
        reportStrings.cipFrequencyRemark,
        reportStrings.startCipBwRemark,
        reportStrings.cipManuallyRemark,
        reportStrings.oValueRemark,
      ]}
      footNotesList={[
        reportStrings.airFlowFootNote,
        reportStrings.repeatStepRemark.replace("${steps}", "3-8"),
        reportStrings.cipStepDurationFootNote.replace("${steps}", "3-6"),
        reportStrings.cipFootNote4,
        reportStrings.cipDurationLongerFootNote,
        reportStrings.forwardFlushFlowFootNote,
        reportStrings.mayWastePermeateRemark,
      ]}
    />
  );
};
