import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfWaterQualityTableColumns = [
  {
    field: "col1",
    rowHeader: true,
  },
  {
    field: "col2",
    rowHeader: true,
    width: "105px",
  },
  {
    field: "col3",
    headerName: reportStrings.feed,
    onCell: ({ rowIndex }) => ({
      colSpan: rowIndex === 0 ? 2 : undefined,
    }),
    width: "27%",
  },
  {
    field: "col4",
    headerName: reportStrings.product,
    onCell: ({ rowIndex }) => ({
      colSpan: rowIndex === 0 ? 0 : undefined,
    }),
    width: "27%",
  },
];

export const organicsLabelByUnit = {
  [UNITS.organicsKMnO4]: reportStrings.organicsKMnO4,
  [UNITS.toc]: reportStrings.organicsTOC,
};

export const WPUfWaterQualityTableDataRows = {
  source: {
    label: reportStrings.source,
    // declaring unit as temperature for converting the temperature values of water source
    unit: UNIT_TYPES.TEMPERATURE,
  },
  temperature_design: {
    label: reportStrings.tempDesign,
    unit: UNIT_TYPES.TEMPERATURE,
  },
  turbidity: {
    label: reportStrings.turbidity,
    unit: UNITS.ntu,
  },
  Organics_TOC: {
    unit: UNIT_TYPES.ORGANICS,
    labelByUnit: organicsLabelByUnit,
  },
  TSS: {
    label: reportStrings.tss,
    unit: UNITS.MiligramPerLiter,
  },
  TDS: {
    label: reportStrings.tds,
    unit: UNITS.MiligramPerLiter,
  },
  pH: {
    label: reportStrings.ph,
    precision: 1,
  },
  SDI: {
    label: reportStrings.sdi,
    precision: 1,
  },
};
