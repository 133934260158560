import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CustomModalButtons } from "@constants/global.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import { FolderStrings, ImportProjectStrings } from "@utils/StringConstants";

import CustomModal from "@components/CustomModal";
import { useToastNotification } from "@components/WPToastNotification";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";

import { useCreateDataMutation, useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import { Folderbtnlist, FolderTempbtnlist, FolderupdateLoader } from "../ViewAllFolderSlice";

const FolderUpdatePopup = ({ isOpen, setIsOpen, userID, folder, folders, setIsFolderCreated, mode }) => {
  const PROJECT_NAME_MAX_LENGTH = 20;
  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const [inputValue, setInputValue] = useState({ folderName: folder?.folderName });
  const [Message, setMessage] = useState("");

  const [error, setError] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const [GetRenameData] = useUpdateDataMutation("");
  const [getFolderListData, folderListResponse] = useLazyGetAllDataQuery();
  const [openModal, setOpenModal] = useState(true);
  const [CreatePost] = useCreateDataMutation();
  const isRename = mode === "rename";
  const folderNameIntial = "Untitled Folder - ";

  const dispatch = useDispatch();

  useEffect(() => {
    if (folderListResponse.status === "fulfilled") {
      dispatch(Folderbtnlist(folderListResponse.data));
      dispatch(FolderTempbtnlist(folderListResponse.data));
      dispatch(FolderupdateLoader(false));
    }
  }, [folderListResponse]);

  useEffect(() => {
    if (folders && !folder) {
      const newFolderList = folders.filter(folder => folder.folderName.startsWith(folderNameIntial));
      newFolderList.sort(
        (x, y) => y.folderName.substring(folderNameIntial.length) - x.folderName.substring(folderNameIntial.length),
      );
      const value = newFolderList.length !== 0 ? newFolderList[0].folderName.substring(folderNameIntial.length) : 0;
      const folderValue = isNaN(value) ? 1 : parseInt(value) + 1;
      setInputValue({ ...inputValue, folderName: folderNameIntial + folderValue });
      setError(false);
    }
  }, [folders, folder]);

  useEffect(() => {
    if (isOpen) {
      setOpenModal(true);
    }
  }, [openModal]);

  const handleBlur = () => {
    // handle onBlur logic here
  };

  const handleFocus = index => {
    setIsFocused(index);
  };

  const handleInputChange = e => {
    const isRename = mode === "rename";
    const myvalus = e.target.value;
    const index = folders?.findIndex(item => item.folderName.toLowerCase().trim() === myvalus.toLowerCase().trim());

    if (myvalus.trim() === "") {
      const errorMessage = FolderStrings.folderNameCannotBeBlank;
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage(errorMessage);
      setError(true);
      return;
    } else if (myvalus.length < 3) {
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage(FolderStrings.folderNameLength);
      setError(true);
      return;
    } else if (index > -1) {
      const errorMessage = FolderStrings.folderNameAlreadyExist;
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage(errorMessage);
      setError(true);
      return;
    } else {
      setInputValue(
        isRename ? { ...inputValue, folderID: folder?.folderID, folderName: myvalus } : { folderName: myvalus },
      );
      setMessage("");
      setError(false);
    }
  };

  const handleFolderOperation = async e => {
    if (inputValue?.folderName?.trim() === "" || inputValue?.folderName?.length < 3) {
      setError(true);
      return;
    } else {
      let newData = {};
      if (mode === "create") {
        newData = {
          Method: "masterdata/api/v1/Folder",
          userID: userID,
          folderName: inputValue.folderName?.trim(),
        };
      } else if (mode === "rename") {
        newData = {
          Method: "masterdata/api/v1/Folder",
          userID: userID,
          folderID: inputValue.folderID,
          folderName: inputValue.folderName,
        };
      }

      const ResponseValues = mode === "create" ? await CreatePost(newData) : await GetRenameData(newData);
      if (ResponseValues?.data?.responseMessage == "Success") {
        let successMessage = "";
        if (mode === "create") {
          showSuccessNotification(APP_TOAST_NOTIFICATION.FOLDER_CREATED);
          setIsFolderCreated(true);
        } else if (mode === "rename") {
          successMessage = APP_TOAST_NOTIFICATION.FOLDER_RENAMED;
          getFolderListData(`masterdata/api/v1/FolderList?userID=${userID}`);
          showSuccessNotification(successMessage);
        }
      } else if (ResponseValues?.error?.data?.responseCode === 400) {
        showErrorNotification(FolderStrings.folderExists);
      } else {
        showErrorNotification(`${ResponseValues?.error?.data?.responseMessage}`);
      }
      setIsOpen(false);
      document.body.classList.remove("disable");
    }
  };

  const handleClose = event => {
    const { id } = event.target;
    if (id === CustomModalButtons.CONFIRM) {
      handleFolderOperation();
    } else {
      setIsOpen(false);
    }
  };

  return (
    <CustomModal
      header={isRename ? FolderStrings.renameFolder : FolderStrings.createNewFolder}
      confirmBtn={isRename ? ImportProjectStrings.rename : FolderStrings.createFolder}
      cancelBtn={ImportProjectStrings.cancel}
      onModalClose={handleClose}
      width='512px'
      confirmBtnDisabled={error}
    >
      <div style={{ marginBottom: "6px" }}>{FolderStrings.folderName}</div>
      <InputWithIcon
        isError={error}
        isWarning={false}
        type='text'
        onBlur={handleBlur}
        onFocus={() => handleFocus(1)}
        isFocused={isFocused === 1}
        maxLength='200'
        inputText={error ? <CloseCircleRedIcon /> : <CloseCircleGreenIcon />}
        unitBgColor='transparent'
        minLength='3'
        value={inputValue.folderName}
        onChange={handleInputChange}
      />
      <ErrorMessage style={{ visibility: error ? "visible" : "hidden" }} texMsg={Message} />
    </CustomModal>
  );
};

export default FolderUpdatePopup;
