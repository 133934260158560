import { CHEMICAL_SYMBOLS } from "@constants/chemical-symbol.constants";

import { memoizedFun } from "@utils/appUtils";

import { CHEMICAL_FIELDS } from "../chemicalLibrary.constants";

import {
  ADD_EDIT_FORM_CONTROLS,
  CHEMICAL_WITH_FIXED_DENSITY,
  EDIT_DISABLED_FIELDS,
} from "./ChemicalLibraryAddEditDialog.constants";

const TEMPERATURE = 25;
const polynomialChemicalTemp = memoizedFun(pow => Math.pow(TEMPERATURE, pow));

/**
 * Calculates the water density based on a polynomial equation.
 *
 * The equation uses a series of polynomial terms of the form `polynomialChemicalTemp(n)`,
 * where `n` is the power to which the temperature is raised. The coefficients for each term
 * are constants derived from empirical data. TEMPERATURE is 25.
 *
 * The formula is:
 *
 * WATER_DENSITY = (999.83952 +
 *                  16.945176 * TEMPERATURE +
 *                  -0.0079870401 * TEMPERATURE^2 +
 *                  -0.000046170461 * TEMPERATURE^3 +
 *                  0.00000010556302 * TEMPERATURE^4 +
 *                  -2.8054253e-10 * TEMPERATURE^5) /
 *                 (1 + 0.01687985 * TEMPERATURE)
 *
 * This formula calculates the density of water at a given temperature, where
 * `polynomialChemicalTemp(n)` is a function that raises the temperature to the power of `n`.
 */
export const WATER_DENSITY =
  (999.83952 +
    16.945176 * polynomialChemicalTemp(1) +
    -0.0079870401 * polynomialChemicalTemp(2) +
    -0.000046170461 * polynomialChemicalTemp(3) +
    0.00000010556302 * polynomialChemicalTemp(4) +
    -2.8054253e-10 * polynomialChemicalTemp(5)) /
  (1 + 0.01687985 * polynomialChemicalTemp(1));

/**
 * Calculates the volume based on the given molal concentration, coefficients, and average Pitzer value.
 *
 * The formula used is:
 *
 * VOLUME = CSA +
 *          (CSB / 1.2) * avPitzer * log(1 + 1.2 * sqrt(CSB * conc_molal)) +
 *          CSC * conc_molal +
 *          CSD * conc_molal^2 +
 *          CSE * conc_molal^3
 *
 * This function calculates the volume using a polynomial equation with coefficients CSA, CSB, CSC, CSD, and CSE.
 * The equation also includes a logarithmic term that depends on the average Pitzer value and the concentration.
 *
 * @param {number} conc_molal - The molal concentration.
 * @param {Object} coeffs - The coefficients for the polynomial equation.
 * @param {number} avPitzer - The average Pitzer value.
 * @returns {number} - The calculated volume.
 */
const calculateVolume = (conc_molal, coeffs, avPitzer) =>
  coeffs.CSA +
  (coeffs.CSB / 1.2) * avPitzer * Math.log(1 + 1.2 * Math.sqrt(coeffs.CSB * conc_molal)) +
  coeffs.CSC * conc_molal +
  coeffs.CSD * Math.pow(conc_molal, 2) +
  coeffs.CSE * Math.pow(conc_molal, 3);

/**
 * Calculates the specific gravity based on the given bulk concentration, molecular weight, and coefficients.
 *
 * The formula used involves several steps:
 * 1. Calculate the average Pitzer value using an exponential function of temperature.
 * 2. Convert the bulk concentration percentage to molal concentration.
 * 3. Calculate the volume using the molal concentration, coefficients, and average Pitzer value.
 * 4. Calculate the specific gravity using the molal concentration, molecular weight, water density, and volume.
 *
 * @param {number} bulkConc_per - The bulk concentration percentage.
 * @param {number} molecularWeight - The molecular weight of the substance.
 * @param {Object} coeffs - The coefficients for the volume calculation.
 * @returns {number} - The calculated specific gravity.
 */
const calculateSpecificGravity = (bulkConc_per, molecularWeight, coeffs) => {
  const avPitzer = Math.exp(0.411276653 + 0.008251869 * TEMPERATURE + 0.0000162678 * Math.pow(TEMPERATURE, 2));
  const conc_molal = ((bulkConc_per / 100 / (1 - bulkConc_per / 100)) * 1000) / molecularWeight;
  const volume = calculateVolume(conc_molal, coeffs, avPitzer);

  return (1000 + conc_molal * molecularWeight) / (1000 + ((conc_molal * WATER_DENSITY) / 1000) * volume);
};

/**
 * Calculates the polynomial gravity based on the given bulk concentration percentage and coefficients.
 *
 * The formula used is a polynomial equation:
 *
 * POLYNOMIAL_GRAVITY = CSBP +  CSCP * bulkConc_per +  CSDP * bulkConc_per^2 + CSEP * bulkConc_per^3
 *
 * This function calculates the polynomial gravity using the coefficients CSBP, CSCP, CSDP, and CSEP.
 * The equation includes terms up to the third power of the bulk concentration percentage.
 *
 * @param {number} bulkConc_per - The bulk concentration percentage.
 * @param {Object} coeffs - The coefficients for the polynomial equation.
 * @returns {number} - The calculated polynomial gravity.
 */
const calculatePolynomialGravity = (bulkConc_per, coeffs) =>
  coeffs.CSBP +
  coeffs.CSCP * bulkConc_per +
  coeffs.CSDP * Math.pow(bulkConc_per, 2) +
  coeffs.CSEP * Math.pow(bulkConc_per, 3);

const getCoefficients = chemicalProperties => {
  const hasCoefficients = chemicalProperties.coeffSpecGravityA != null;

  return {
    CSA: hasCoefficients ? chemicalProperties.coeffSpecGravityA : 0,
    CSB: hasCoefficients ? chemicalProperties.coeffSpecGravityB : 0,
    CSC: hasCoefficients ? chemicalProperties.coeffSpecGravityC : 0,
    CSD: hasCoefficients ? chemicalProperties.coeffSpecGravityD : 0,
    CSE: hasCoefficients ? chemicalProperties.coeffSpecGravityE : 0,
    CSBP: hasCoefficients ? chemicalProperties.coeffSpecGravityBPrime : 0,
    CSCP: hasCoefficients ? chemicalProperties.coeffSpecGravityCPrime : 0,
    CSDP: hasCoefficients ? chemicalProperties.coeffSpecGravityDPrime : 0,
    CSEP: hasCoefficients ? chemicalProperties.coeffSpecGravityEPrime : 0,
  };
};

// Usage in calculateBulkDensity function
const calculateBulkDensity = (chemicalData, chemicalPropertiesById) => {
  const { id, symbol, bulkConcentration, bulkDensity } = chemicalData;

  const chemicalProperties = chemicalPropertiesById[id];
  if (chemicalProperties && id) {
    const molecularWeight = chemicalProperties.molecularWeight;

    const coeffs = getCoefficients(chemicalProperties);

    let specificGravity = bulkDensity;
    if (symbol === CHEMICAL_SYMBOLS.H2SO4) {
      specificGravity = calculatePolynomialGravity(bulkConcentration, coeffs);
    } else {
      specificGravity = calculateSpecificGravity(bulkConcentration, molecularWeight, coeffs);
    }
    return specificGravity;
  }
  return bulkDensity;
};

/**
 * Determines the editable fields for a given chemical based on its properties.
 *
 * @param {Object} chemicalData - The data object for the chemical.
 * @returns {Array} - An array of fields that are editable.
 */
const getEditableFields = chemicalData => {
  const { isSystem, name } = chemicalData;
  return isSystem
    ? EDIT_DISABLED_FIELDS.systemChemical
    : CHEMICAL_WITH_FIXED_DENSITY.includes(name)
      ? EDIT_DISABLED_FIELDS.customSystemChemical
      : EDIT_DISABLED_FIELDS.customChemicals;
};

/**
 * Formats the chemical form data by populating the form controls with the provided chemical data.
 * It also sets the options for category and chemical fields based on the provided categories and chemicals.
 *
 * @param {Object} chemicalData - The data of the chemical to be formatted.
 * @param {Array} options.chemicalCategories - The list of chemical categories.
 * @param {Object} options.chemicalsByCategoryId - The chemicals grouped by category ID.
 * @returns {Object} - The formatted form controls with populated values and options.
 */
export const formatChemicalFormData = (chemicalData, { chemicalCategories, chemicalsByCategoryId }) => {
  // Deep Clone the form controls to avoid mutating the original
  let formControls = structuredClone(ADD_EDIT_FORM_CONTROLS);

  if (chemicalData) {
    const editableFields = getEditableFields(chemicalData);
    formControls = Object.entries(formControls).reduce((updatedControls, [fieldKey, fieldConfig]) => {
      let options = fieldConfig.options;
      if (fieldKey === CHEMICAL_FIELDS.category) {
        options = chemicalCategories || [];
      } else if (fieldKey === CHEMICAL_FIELDS.chemical) {
        options = chemicalsByCategoryId[chemicalData.categoryId] || [];
      }

      const updatedFieldConfig = {
        ...fieldConfig,
        value: chemicalData[fieldKey],
        disabled: !editableFields.includes(fieldKey),
        options: options,
      };

      return { ...updatedControls, [fieldKey]: updatedFieldConfig };
    }, {});
  }

  return formControls;
};

export default calculateBulkDensity;
