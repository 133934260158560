import { useState } from "react";
import styled from "styled-components";

import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";

const ToggleContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const ChemicalToggleRenderer = ({ data, context }) => {
    const [isChecked, setIsChecked] = useState(data.isSystem || data.viewInProjects);

    const handleToggleChange = ({ target }) => {
        const value = target.checked;
        setIsChecked(value);
        if (context.onActionBtnClick) {
            context.onActionBtnClick({ type: "viewInProjects", data: { ...data, viewInProjects: value } });
        }
    };

    return (
        <ToggleContainer id='Toggle-container'>
            <ToggleSwitch
                small
                id={`${data.localId || data.id}-toggle`}
                name={`${data.localId || data.id}-toggle`}
                disabled={data.isSystem}
                checked={isChecked}
                onChange={handleToggleChange}
            />
        </ToggleContainer>
    );
};

export default ChemicalToggleRenderer;
