import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  CHEMICAL_WITH_NAME_PROP,
  processStepsLabels,
  WPUfChemicalsTableColumns as columns,
  WPUfChemicalsTableRowsData as rowsData,
  WPUfChemicalsTableUnitMapping as unitMapping,
} from "./WPUfChemicalsTable.constants";

export const useWPUfChemicalsTableData = () => {
  const { ufReportRespData, formatLabel, formatChemicalNameConc, convertReportDataValue, getUnitLabelValue } =
    useReportUtils();

  const getChemicalRowData = (rowData, rowResp) =>
    columns.reduce((acc, col) => {
      const colKey = col.field;
      acc[colKey] = getColumnValue(colKey, rowData, rowResp);
      return acc;
    }, {});

  const getColumnValue = (colKey, rowData, rowResp) => {
    if (colKey === "label") {
      const chemical = rowData[rowResp.symbol] || rowData.chemical;
      const chemicalProp = CHEMICAL_WITH_NAME_PROP.includes(rowData.apiRowKey) ? "name" : "symbol";
      return rowData.label ? rowData.label : formatChemicalNameConc(chemical[chemicalProp], rowResp.conc);
    }
    const { isCost, unit, precision, precisionByUnit } = unitMapping[colKey] || {};
    return convertReportDataValue({ value: rowResp[colKey], isCost, unit, precision, precisionByUnit });
  };

  const processStepsRowsData = (apiRowKey, rowData, Chemicals) =>
    rowData.processSteps.reduce((acc, chemicalKey) => {
      const rowResp = Chemicals[`${apiRowKey}_${chemicalKey}`]; // combined key to get process steps data
      const { display = true } = rowResp;
      if (!display) return acc;
      const row1 = columns.reduce((acc, col) => {
        const colKey = col.field;
        acc[colKey] =
          colKey === "label"
            ? formatLabel(processStepsLabels[chemicalKey], 1)
            : getColumnValue(colKey, rowData, rowResp);
        return acc;
      }, {});
      acc.push(row1);
      return acc;
    }, []);

  const convertToUfChemicalsTableData = () => {
    const { Chemicals } = ufReportRespData;

    // first row is unit row
    const firstrow = columns.reduce((acc, col) => {
      const { isCost, unit, unitSubText } = unitMapping[col.field] || {};
      acc[col.field] = getUnitLabelValue({ isCost, unit, unitSubText });
      return acc;
    }, {});

    const data = rowsData.reduce((acc, rowData) => {
      const { apiRowKey } = rowData;
      const rowResp = Chemicals[apiRowKey];
      if (!rowResp) return acc;
      const { display = true } = rowResp;

      // chemical row
      if (display) {
        acc.push(getChemicalRowData(rowData, rowResp));
      }
      // process steps of above chemicals , CEB1, CEB2, CEB3 , BW etc.
      if (rowData.processSteps) {
        const processStepRows = processStepsRowsData(apiRowKey, rowData, Chemicals);
        acc.push(...processStepRows);
      }
      return acc;
    }, []);

    data.unshift(firstrow);

    return data;
  };

  return { convertToUfChemicalsTableData };
};
