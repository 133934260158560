import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useUFInputRanges } from "@hooks/useUFInputRanges";

import CustomRadio from "@common/styles/components/radios/CustomRadio";

import { UFCongurationTableHeader } from "@features/feedwater/uf/constants/UFConstants";
import { updateUFStoreData } from "@features/feedwater/uf/UFSlice";

import UFConfigurationTableStyled from "./UFConfigurationTableStyled";

export default function UFConfigurationTable() {
  const dispatch = useDispatch();
  const { getDefaultUFConfiguration } = useUFInputRanges();
  const { recommended_configs, sliderMin, sliderMax, data, activeUFModule } = useSelector(state => state.UFStore);
  const [selectedConfig, setSelectedConfig] = useState();

  const handleConfigSelection = useCallback(
    rowSelected => {
      const selectedRowOption = rowSelected.options;
      setSelectedConfig(selectedRowOption);
      const defaultConfig = getDefaultUFConfiguration(rowSelected);
      dispatch(
        updateUFStoreData({
          ...defaultConfig,
          isUfDataUpdated: true,
          isRecommendationCalucalting: true,
          calcEngineDataRefreshCount: 1,
        }),
      );
    },
    [dispatch, getDefaultUFConfiguration],
  );

  const isIngeTRackModule = useMemo(() => activeUFModule?.tRack, [activeUFModule]);

  const tableHeaders = useMemo(
    () =>
      isIngeTRackModule
        ? UFCongurationTableHeader.filter(({ field }) => field !== "modulesPerRack" && field !== "racksPerUnit")
        : UFCongurationTableHeader,
    [isIngeTRackModule],
  );

  useEffect(() => {
    const { totalModules, onlineTrains, modulesPerTrain, modulesPerSkid } = data;
    if (recommended_configs.length > 0) {
      const selectedRow = recommended_configs.find(
        item =>
          item.onlineUnits === onlineTrains &&
          item.modulesPerUnit === modulesPerTrain &&
          item.totalModules === totalModules &&
          (item.modulesPerRack === "-" || item.modulesPerRack === modulesPerSkid),
      );
      setSelectedConfig(selectedRow?.options);
    }
  }, [recommended_configs, data]);

  return (
    <UFConfigurationTableStyled>
      <table className='table'>
        <thead>
          <tr>
            {tableHeaders.map((item, index) => (
              <th key={index} className='th'>
                {item.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {recommended_configs
            ?.filter( ({ modulesPerRack }) => modulesPerRack === "-" || (modulesPerRack <= sliderMax && modulesPerRack >= sliderMin))
            .map((config, index) => (
              <tr key={`config-row-${config.options}-${index}`} onClick={() => handleConfigSelection(config)}>
                <td className='td'>
                  <CustomRadio
                    type='radio'
                    id='selectedConfig'
                    name='selectedConfig'
                    key={`recommended-configuration-${config.options}`}
                    checked={selectedConfig == config.options}
                    onChange={() => handleConfigSelection(config)}
                  />
                </td>
                <td className='td'>{config.onlineUnits}</td>
                <td className='td'>{config.standByUnits}</td>
                <td className='td'>{config.totalUnits}</td>
                <td className='td'>{config.maxOfflineBW_CEB}</td>
                {!isIngeTRackModule && (
                  <>
                    <td className='td'>{config.modulesPerRack}</td>
                    <td className='td'>{config.racksPerUnit}</td>
                  </>
                )}
                <td className='td'>{config.modulesPerUnit}</td>
                <td className='td'>{config.onlineModules}</td>
                <td className='td'>{config.totalModules}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </UFConfigurationTableStyled>
  );
}
