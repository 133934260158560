import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const VALVE_OPENED = "o";

/**

The Data Structure of the UF Parameters Table must follow below interface

interface IWPUfParameterRow {
  rowKey: string;
  [key: string]: boolean | number | string | IWPUfParameterRowValue| undefined;
}

interface IWPUfParameterRowValue{
  value: any;
  unit?: string; // UNITS or UNIT_TYPES
  ufDataKey?: string;
  calEngineDataKey?: string;
  precision?: number;
}
  
**/

//These are duration and flow parameters for the UF Treatment, these data is common
export const WPUfParametersDataMapping = {
  forwardFlushDuration: {
    value: "~2.0 - 3.0", // static default value
    unit: UNITS.min,
  },
  Flow_FF: {
    ufDataKey: "forwardFlushFlow",
    unit: UNIT_TYPES.FLOW,
  },
  t_filtration_cycle: {
    ufDataKey: "backwash_design",
    unit: UNITS.min,
  },
  Flux_Filter_actual: {
    calEngineDataKey: "flux_Filter_actual",
    unit: UNIT_TYPES.FLUX,
  },
  t_AS: {
    ufDataKey: "backwash_AirScour",
    unit: UNITS.sec,
  },
  Flow_AS: {
    ufDataKey: "airFlow",
    unit: UNIT_TYPES.GAS_FLOW,
  },
  t_Drain: {
    ufDataKey: "drain_backWash",
    unit: UNITS.sec,
  },
  drainFlow: {
    value: reportStrings.byGravity,
  },
  t_BW1: {
    ufDataKey: "backWash1_backWash",
    unit: UNITS.sec,
  },
  t_BW2: {
    ufDataKey: "backWash2_backWash",
    unit: UNITS.sec,
  },
  Flux_BW: {
    ufDataKey: "backwashFlux",
    unit: UNIT_TYPES.FLUX,
  },
  t_FF: {
    ufDataKey: "forwardFlush_backWash",
    unit: UNITS.sec,
  },
  t_AS_CEB: {
    ufDataKey: "ceb_AirScour",
    unit: UNITS.sec,
  },
  t_Drain_CEB: {
    ufDataKey: "drain",
    unit: UNITS.sec,
  },
  t_BW1_CEB: {
    ufDataKey: "backWash1_CEB",
    unit: UNITS.sec,
  },
  t_BW2_CEB: {
    ufDataKey: "backWash2_CEB",
    unit: UNITS.sec,
  },
  Flux_CEB: {
    ufDataKey: "cEBFlux",
    unit: UNIT_TYPES.FLUX,
  },
  t_CEB_soak: {
    ufDataKey: "chemicalSoakingDuration_CEB",
    unit: UNITS.min,
  },
  t_CIP_soak: {
    ufDataKey: "chemicalSoakingDuration_CIP",
    unit: UNITS.min,
  },
  t_CIP_recycle: {
    ufDataKey: "recycleDuration",
    unit: UNITS.min,
  },
  Flow_CIP_recycle: {
    ufDataKey: "recycleFlowRate",
    unit: UNIT_TYPES.FLOW,
  },
  t_mCIP_recycle: {
    ufDataKey: "recycleDuration_MiniCIP",
    unit: UNITS.min,
  },
  Flow_mCIP_recycle: {
    ufDataKey: "recycleFlowRate_MiniCIP",
    unit: UNIT_TYPES.FLOW,
  },
  t_mCIP_soak: {
    ufDataKey: "chemicalSoakingDuration_MiniCIP",
    unit: UNITS.min,
  },
  flowZero: {
    value: 0, // static default value
    unit: UNIT_TYPES.FLOW,
  },
  minutesZero: {
    value: 0, // static default value
    unit: UNITS.min,
  },
  Flow_FF3: {
    ufDataKey: "flow_FF3",
    unit: UNIT_TYPES.FLOW,
  },
  t_BW1_CEBRinse: {
    ufDataKey: "bwRinseDrainTop",
    unit: UNITS.sec,
  },
  t_BW2_CEBRinse: {
    ufDataKey: "bwRinseDrainBottom",
    unit: UNITS.sec,
  },
  //customkey, sum of t_BW1 + t_BW2
  backwashBeforeCeb: {
    customKey: "backwashBeforeCeb",
    unit: UNITS.sec,
  },
};
