export const UFStrings = {
    moduleSelection: "Module Selection",
    moduleSectionInfo: "Select a module.",
    ufDuPontModule: "DuPont™ IntegraTec™ Ultrafiltration Module",
    ufProductGuidelines: "UF Product Guidelines",
    selectedConfiguraion: "Selected Configuration",
    constantOperatingFlux: "Constant operating flux, variable system output",
    constantSystemOutput: "Constant system output, variable operating flux",
    questionIconTooltip: "Help",
    ufConfigGloassaryLink: "Click to View Glossary"
};

export const ConfigurationHelpDlgData = {
    constantSystemOutput: [
        {
            section: "Online Units (n)",
            description: "Minimum number of units in filtration at any moment, required for design flow at design flux."
        },
        {
            section: "Max Offline BW/CEB (y)",
            description: "Maximum number of units that are simultaneously in Backwash or CEB."
        },
        {
            section: "Standby Units (x)",
            description: `Additional units required to maintain constant flow and flux when other units are offline. 
                        Normally, standby units (x) = Max. offline BW/CEB (y). <br /> <strong>(n+x):</strong> Number of 
                        units installed for operation. When no unit is in BW or CEB mode, n+x units are in filtration mode.`
        },
        {
            section: "Redundant Units (z)",
            description: `Installed spare units. These show redundancy or units in maintenance and 
                        are not considered in the computation. <br /> <strong>(n+x+z):</strong> Number of units
                        installed for operation + installed spare for CIP, maintenance, membrane integrity testing, etc.`
        }
    ],

    constantOperatingFlux: [
        {
            section: "Online Units (n)",
            description: "Maximum number of units in operation (recovery, chemical consumption, and wastewater amount is based on the number of units)."
        },
        {
            section: "Max Offline BW/CEB (y)",
            description: `Maximum number of Units that are simultaneously in Backwash or CEB <br/>
                        <strong>(n-y):</strong> minimum Number of Units in filtration at any moment`
        },
        {
            section: "Standby Units (x)",
            description: "Not required when operating at constant flux. It is always zero."
        },
        {
            section: "Redundant Units (z)",
            description: `Installed spare units. These show redundancy or units in maintenance and are not 
                        considered in the computation. <br /> <strong>(n+z):</strong> Number of units installed for 
                        operation + installed spare for CIP, maintenance, membrane integrity testing, etc.`
        }
    ]
};