import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFConfig from "@hooks/useUFConfig";

import CustomCard from "@components/CustomCard";
import WPDropdown from "@components/WPDropdown";

import ArrowRightBlackIcon from "@common/icons/ArrowRightBlackIcon";
import StandardLinkButtonWithIcon from "@common/styles/components/buttons/standard/StandardLinkButtonWithIcon";
import CustomLabel from "@common/styles/components/headings/CustomLabel";

import { UFStrings } from "../constants/UFStrings";
import { updateUFStoreData } from "../UFSlice";

import UFProductGuidelines from "./UFProductGuidelines";

const UFModuleSelection = ({ showProductGuidelines, tabIndex }) => {
  const dispatch = useDispatch();
  const { setUFChemicalsDefaultValues } = useUFChemicalsHandler();
  const { updateActiveModule, updateNonMiniCIPFieldsValue } = useUFConfig();

  const { data, ufModules, bwProtocol } = useSelector(state => state.UFStore);
  const { filteredModules } = useSelector(state => state.UFStore.ufProductFilter);

  const [openUFGuideline, setOpenUFGuideline] = useState(false);
  const [selectedModule, setSelectedModule] = useState("0");

  useEffect(() => setSelectedModule(data?.uFModuleID), [data?.uFModuleID]);

  const handleUFModule = data => {
    const { ufmoduleId } = data;
    updateActiveModule(ufmoduleId);
    const selectedModule = ufModules.find(item => item.ufmoduleId === parseInt(ufmoduleId));

    //When UXA protocol is slected drain_backwash and drain 0
    if (selectedModule?.newModuleLongName?.includes("UXA") && bwProtocol.length > 1) {
      dispatch(updateUFStoreData({ data: { drain_backWash: 0, drain: 0 } }));
    }
    updateNonMiniCIPFieldsValue();
    //Timout added to make sure, selected module updated in redux
    setTimeout(() => setUFChemicalsDefaultValues(), 100);
  };

  const handleOpenUFGuideLine = () => setOpenUFGuideline(true);

  const setPopUPBasedModuleSelection = item => {
    if (item?.id) {
      updateActiveModule(item.id);
    }
  };

  const getModuleOptionLabel = option => option.newModuleLongName;

  const moduleIDValue = useMemo(
    () =>
      data.uFModuleID ? filteredModules.find(item => item.ufmoduleId.toString() === data.uFModuleID?.toString()) : "",
    [data.uFModuleID, filteredModules],
  );

  return (
    <CustomCard className='module-selection-card' header={UFStrings.moduleSelection} tooltipLabel={UFStrings.moduleSectionInfo}>
      <div style={{ marginTop: "2px" }}>
        <CustomLabel label={UFStrings.ufDuPontModule} mandatoryIcon />
        <WPDropdown
          id='pesSelect'
          name='uFModuleID'
          value={moduleIDValue}
          options={filteredModules}
          onChange={handleUFModule}
          getOptionLabel={getModuleOptionLabel}
          tabIndex={tabIndex}
        />
      </div>

      {showProductGuidelines && (
        <>
          <div className='btn-div' style={{ marginTop: 15 }}>
            <StandardLinkButtonWithIcon
              id='ufProductDefinitions'
              label={UFStrings.ufProductGuidelines}
              padding='10px 0px'
              icon={<ArrowRightBlackIcon />}
              onClick={handleOpenUFGuideLine}
            />
          </div>
          {openUFGuideline && (
            <UFProductGuidelines
              show
              onSelection={item => setPopUPBasedModuleSelection(item)}
              close={() => setOpenUFGuideline(false)}
            />
          )}
        </>
      )}
    </CustomCard>
  );
};

export default UFModuleSelection;
