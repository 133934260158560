import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { EXTERNAL_URLS } from "@constants/api.urls";
import { APP_COMMON_STRINGS } from "@constants/common.strings";

import CustomModal from "@components/CustomModal";
import IconButton from "@components/IconButton";
import { ArrowIconRight, BackIcon } from "@components/SVGs";
import WPButton from "@components/WPButton";
import WPHeading from "@components/WPHeading";

import { ConfigurationHelpDlgData, UFStrings } from "../../constants/UFStrings";

const ConfigHelpDlgContainer = styled.div`
  margin: 16px 0;

  .header {
    margin-bottom: 6px;
    font-family: NotoSans;
  }

  .description {
    font-family: NotoSans;
    color: var(--Black, rgba(0, 0, 0, 0.7)) !important;
    white-space: normal;
  }
`;

const StyledDialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  #open_config_glossary {
    margin-left: -24px;

    svg {
      margin-left: 3px;
    }

    &:hover {
      svg {
        path {
          fill: var(--du-color-primary-dark);
        }
      }
    }
  }
`;

const DialogFooter = ({ onBtnClick }) => (
  <StyledDialogFooter>
    <WPButton id='open_config_glossary' onClick={onBtnClick} variant='text'>
      {UFStrings.ufConfigGloassaryLink} <ArrowIconRight />
    </WPButton>
    <WPButton id='cofig_okay_btn' onClick={onBtnClick}>
      {APP_COMMON_STRINGS.okay}
    </WPButton>
  </StyledDialogFooter>
);
const UFSelectedConfigurationHelpDlg = ({ onClose, standByOption }) => {
  const standByType = useMemo(
    () => (standByOption === 1 ? "constantOperatingFlux" : "constantSystemOutput"),
    [standByOption],
  );
  const headerContent = useMemo(
    () => (
      <div>
        <IconButton tooltip={UFStrings.backBtnTitle} onClick={onClose} className='config-help-back-button'>
          <BackIcon />
        </IconButton>
        {UFStrings[standByType]}
      </div>
    ),
    [onClose],
  );

  const handleBtnClick = ({target}) => {
    if(target.id  === "open_config_glossary"){
      window.open(EXTERNAL_URLS.ufConfigurationHelp, "_blank");
    }
    onClose();
  };

  return (
    <CustomModal
      header={headerContent}
      onModalClose={onClose}
      width='775px'
      hideCloseIcon
      modalFooter={<DialogFooter onBtnClick={handleBtnClick} />}
    >
      {ConfigurationHelpDlgData[standByType].map((item, index) => (
        <ConfigHelpDlgContainer id={`help-${index}`} key={`help-${index}`}>
          <WPHeading size='xSmall' className='header' text={item.section} />
          <WPHeading secondary size='xSmall' className='description' renderHtml text={item.description} />
        </ConfigHelpDlgContainer>
      ))}
    </CustomModal>
  );
};

UFSelectedConfigurationHelpDlg.propTypes = {
  onClose: PropTypes.func.isRequired,
  standByOption: PropTypes.string.isRequired,
};

export default UFSelectedConfigurationHelpDlg;
