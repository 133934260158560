import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequest, getRequest, putRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";

import useGlobalDataHandler from "@hooks/useGlobalDataHandler";
import useProjectDetails from "@hooks/useProjectDetails";

import { convertListToObj, groupBy } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { updateGlobalData } from "@common/GlobalDataSlice";
import { updateProjectInfoState } from "@common/ProjectInfoSlice";

import { updateChemicalUpdatesCount } from "@features/feedwater/ix/IXDSlice";

import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import calculateBulkDensity from "../ChemicalLibraryAddEditDialog/ChemicalLibraryAddEditDialog.util";

const useChemicalLibrary = () => {
  const Logger = DupontLogger("useChemicalLibrary");

  const dispatch = useDispatch();
  const { projectDetails, projectChemicalCosts } = useProjectDetails();
  const { fetchChemicalLibrary, fetchChemicalCategory } = useGlobalDataHandler();

  const { chemicalCategoryData, chemicalLibraryList, chemicalCategoryList } = useSelector(state => state.globalAppData);

  const [loaderText, setLoaderText] = useState(null);

  const fetchChemicalLibraryList = async isForceRefersh => {
    try {
      setLoaderText(isForceRefersh ? CHEMICALS_LIBRARY_STRINGS.refreshingList : CHEMICALS_LIBRARY_STRINGS.loadingData);
      await fetchChemicalLibrary(isForceRefersh);
    } catch (error) {
      Logger.error("Error fetching data:", error);
    } finally {
      setLoaderText(null);
    }
  };

  const formatProjectCosts = data => {
    const result = { type: "projectChemicalCosts", data: { isFetching: false } };

    if (data) {
      const { projectChemicals } = data;
      const chemicalListById = convertListToObj(projectChemicals, "id");
      const chemicalListByName = convertListToObj(projectChemicals, "displayName");
      const chemicalListByCategory = groupBy(projectChemicals, "category");
      result.data = { ...result.data, ...data, chemicalListById, chemicalListByName, chemicalListByCategory };
    }

    return result;
  };

  const fetchProjectCosts = useCallback(async projectID => {
    let projectCostsData = {};
    try {
      setLoaderText(CHEMICALS_LIBRARY_STRINGS.loadingData);
      dispatch(updateProjectInfoState({ type: "projectChemicalCosts", data: { isFetching: true } }));
      const projectId = projectID || projectDetails.projectID;
      const { data } = await getRequest(`${API_URLS.projectCosts}/${projectId}`);
      projectCostsData = formatProjectCosts(data);
    } catch (error) {
      Logger.error("Error fetching data:", error);
      projectCostsData = { type: "projectChemicalCosts", data: { isFetching: false } };
    } finally {
      Logger.log("fetchProjectCosts - projectChemicals finally: ", projectCostsData);
      dispatch(updateProjectInfoState(projectCostsData));
      setLoaderText(null);
    }
  }, []);

  const updateProjectCosts = async updatedCosts => {
    try {
      const projectId = projectDetails.projectID;
      setLoaderText(CHEMICALS_LIBRARY_STRINGS.updatingOperatingCosts);
      await putRequest(API_URLS.projectCosts, { ...updatedCosts, projectId });
      return { isSuccess: true, message: APP_TOAST_NOTIFICATION.OPERATION_COSTS_UPDATED };
    } catch (error) {
      Logger.error("Error fetching data:", error);
      return { isSuccess: false, message: APP_TOAST_NOTIFICATION.OPERATION_COSTS_UPDATE_FAILED };
    } finally {
      setLoaderText(null);
    }
  };

  const makeChemicalAndUtilityCostDefault = async (payload, isUtilityCostDefault) => {
    const actionText = isUtilityCostDefault
      ? CHEMICALS_LIBRARY_STRINGS.defaultUtilityCostUpdated
      : CHEMICALS_LIBRARY_STRINGS.defaultChemicalPricesUpdated;

    const successMessage = isUtilityCostDefault
      ? APP_TOAST_NOTIFICATION.DEFAULT_UTILITY_COST
      : APP_TOAST_NOTIFICATION.DEFAULT_CHEMICAL_PRICE;

    try {
      setLoaderText(actionText);
      await putRequest(API_URLS.defaultChemicalPriceAndUtilityCost, payload);
      return {
        isSuccess: true,
        message: successMessage,
      };
    } catch (error) {
      Logger.error("Error fetching data:", error);
      return { isSuccess: false, message: APP_TOAST_NOTIFICATION.DEFAULT_PROJECT_COST_FAILED };
    } finally {
      setLoaderText(null);
    }
  };

  /**
   * Handles the saving of chemical data.
   * Generates a new localId for the chemical data,
   * updates the global state with the new chemical data,
   * and returns a notification message.
   * 
   * @param {Object} chemicalData - The chemical data to be saved.
   * @returns {string} - Notification message indicating the chemical data has been saved.
   */
  const onChemicalSave = chemicalData => {
    const newChemicalLibraryList = [...chemicalLibraryList];
    const lastItemLocalId = newChemicalLibraryList[newChemicalLibraryList.length - 1]?.localId || 0;
    const localId = lastItemLocalId + 1;
    newChemicalLibraryList.push({ ...chemicalData, localId });
    dispatch(updateGlobalData({ type: "chemicalLibraryList", value: newChemicalLibraryList }));
    return APP_TOAST_NOTIFICATION.CHEMICAL_SAVED;
  };

  const onChemicalUpdate = chemicalData => {
    const chemicalIdx = chemicalLibraryList.findIndex(item => item.localId === chemicalData.localId);
    const newChemicalList = [...chemicalLibraryList];
    newChemicalList[chemicalIdx] = chemicalData;
    dispatch(updateGlobalData({ type: "chemicalLibraryList", value: newChemicalList }));
    return APP_TOAST_NOTIFICATION.CHEMICAL_UPDATED;
  };

  const addEditChemical = async chemicalData => {
    const { localId, ...payload } = chemicalData;
    const isUpdate = Boolean(chemicalData.id);
    const msgKey = isUpdate ? "updatingChemical" : "savingChemical";
    setLoaderText(CHEMICALS_LIBRARY_STRINGS[msgKey]);
    try {
      const { data } = await putRequest(API_URLS.chemicalLibrary, payload);
      const message = isUpdate ? onChemicalUpdate({ ...data, localId }) : onChemicalSave(data);
      fetchProjectCosts();
      dispatch(updateChemicalUpdatesCount());
      return { isSuccess: true, message };
    } catch (error) {
      Logger.error("Error during addEditChemical:", error);
      const msgKey = isUpdate ? "CHEMICAL_UPDATE_FAILED" : "CHEMICAL_SAVE_FAILED";
      const message = error?.response?.data?.responseMessage || APP_TOAST_NOTIFICATION[msgKey];
      return { isSuccess: false, message };
    } finally {
      setLoaderText(null);
    }
  };

  const deleteChemical = async chemicalData => {
    setLoaderText(CHEMICALS_LIBRARY_STRINGS.deletingChemical);
    try {
      await deleteRequest(`${API_URLS.chemicalLibrary}/${chemicalData.id}`);
      const filteredChemicals = chemicalLibraryList.filter(({ id }) => id !== chemicalData.id);
      dispatch(updateGlobalData({ type: "chemicalLibraryList", value: filteredChemicals }));
      fetchProjectCosts();
      return { isSuccess: true, message: APP_TOAST_NOTIFICATION.CHEMICAL_DELETED };
    } catch (error) {
      Logger.error("Error during addEditChemical:", error);
      const message = error?.response?.data?.responseMessage || APP_TOAST_NOTIFICATION.CHEMICAL_DELETE_FAILED;
      return { isSuccess: false, message };
    } finally {
      setLoaderText(null);
    }
  };

  const calculateChemicalBulkDensity = ({ categoryId, name, bulkDensity, ...rest }) => {
    const { chemicalsByCategoryId, chemicalPropertiesById } = chemicalCategoryData;
    const item = chemicalsByCategoryId[categoryId]?.find(chemical => chemical.name === name);
    return item
      ? calculateBulkDensity({ ...rest, id: item.id, symbol: item.symbol }, chemicalPropertiesById)
      : bulkDensity;
  };

  const checkChemicalUsage = async userChemicalId => {
    try {
      setLoaderText(CHEMICALS_LIBRARY_STRINGS.checkingChemicalUsage);
      const { data } = await getRequest(API_URLS.chemicalLibraryChemicalUsage, { userChemicalId });
      return data;
    } catch (error) {
      Logger.error("Error fetching data:", error);
    } finally {
      setLoaderText(null);
    }
  };

  const deleteProjects = async payload => {
    try {
      setLoaderText(CHEMICALS_LIBRARY_STRINGS.deletingProjects);
      await deleteRequest(API_URLS.project, payload);
      return { isSuccess: true, message: APP_TOAST_NOTIFICATION.PROJECT_DELETED };
    } catch (error) {
      Logger.error("Error while deleleting projects: ", error);
      return { isSuccess: false, message: APP_TOAST_NOTIFICATION.WATER_LIB_SAVE_FAILED };
    } finally {
      setLoaderText(null);
    }
  };

  return {
    loaderText,
    projectChemicalCosts,
    chemicalLibraryList,
    chemicalCategoryList,
    chemicalCategoryData,
    deleteProjects,
    deleteChemical,
    addEditChemical,
    fetchProjectCosts,
    updateProjectCosts,
    checkChemicalUsage,
    fetchChemicalCategory,
    fetchChemicalLibraryList,
    calculateChemicalBulkDensity,
    makeChemicalAndUtilityCostDefault,
  };
};

export default useChemicalLibrary;
