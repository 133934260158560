import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest } from "src/api/baseApi";

import API_URLS from "@constants/api.urls";
import { TECHNOLOGIES } from "@constants/global.constants";

import { isProdEnv } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { updateGlobalData } from "@common/GlobalDataSlice";
import { updateIXPermission } from "@common/UserInfoSlice";

const useGlobalDataHandler = () => {
  const Logger = DupontLogger("useGlobalDataHandler");
  const dispatch = useDispatch();

  const { countries, marketSegments, waterLibraryList, chemicalLibraryList, chemicalCategoryData } = useSelector(
    state => state.globalAppData,
  );
  const { UserId } = useSelector(state => state.userInfo.data);
  const { isIXPermission } = useSelector(state => state.userInfo);

  const fetchCountries = async () => {
    if (countries.length > 0) return;
    try {
      const response = await getRequest(API_URLS.countriesList);
      dispatch(updateGlobalData({ type: "countries", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching countries: ", error);
    }
  };

  const fetchWaterLibraryList = async () => {
    if (waterLibraryList.predefinedLibrary) return;
    try {
      const { data } = await getRequest(API_URLS.waterLibrary);
      const { predefinedLibrary, userLibrary } = data.reduce(
        (acc, item) => {
          if (item.isSystem) {
            acc.predefinedLibrary.push(item);
          } else {
            acc.userLibrary.push(item);
          }
          return acc;
        },
        { predefinedLibrary: [], userLibrary: [] },
      );
      const waterLibraryList = { predefinedLibrary, userLibrary };
      dispatch(updateGlobalData({ type: "waterLibraryList", value: waterLibraryList }));
    } catch (error) {
      Logger.error("Error fetching waterLibraryData: ", error);
    }
  };

  const transformChemicalsData = chemicals =>
    chemicals.reduce((acc, chemical) => {
      const { categoryId } = chemical;
      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }
      acc[categoryId].push(chemical);
      return acc;
    }, {});

  const fetchChemicalCategory = async () => {
    if (chemicalCategoryData.chemicalCategories) return;
    try {
      const { data } = await getRequest(API_URLS.chemicalLibraryCategory);
      const { chemicals, chemicalCategories, chemicalProperties } = data;
      const chemicalsByCategoryId = transformChemicalsData(chemicals);
      const chemicalPropertiesById = chemicalProperties.reduce(
        (acc, item) => ({ ...acc, [item.chemicalId]: item }),
        {},
      );
      dispatch(
        updateGlobalData({
          type: "chemicalCategoryData",
          value: { chemicalCategories, chemicalsByCategoryId, chemicalPropertiesById },
        }),
      );
    } catch (error) {
      Logger.error("Error fetching Chemical Library: ", error);
    }
  };

  const fetchChemicalLibrary = async isForceRefersh => {
    if (!isForceRefersh && chemicalLibraryList.length) return;
    try {
      const { data } = await getRequest(API_URLS.chemicalLibrary);
      const sortedData = data
        .sort((item1, item2) => item2.isSystem - item1.isSystem)
        .map((item, index) => ({ ...item, localId: index }));
      dispatch(updateGlobalData({ type: "chemicalLibraryList", value: sortedData }));
    } catch (error) {
      Logger.error("Error fetching Chemical Library: ", error);
    }
  };

  const fetchUnreadNotifications = useCallback(async () => {
    try {
      const response = await getRequest(API_URLS.unreadNotifications);
      dispatch(updateGlobalData({ type: "unreadNotifications", value: response.data }));
    } catch (error) {
      Logger.error("Error fetching unread notifications: ", error);
    }
  }, []);

  const fetchMarketSegments = useCallback(
    async userID => {
      if (marketSegments.length > 0) return;
      try {
        const response = await getRequest(API_URLS.marketSegment, { userID });
        dispatch(updateGlobalData({ type: "marketSegments", value: response.data }));
      } catch (error) {
        Logger.error("Error fetching unread notifications: ", error);
      }
    },
    [marketSegments],
  );

  useEffect(() => {
    Logger.info("fetchUnreadNotifications UserId: ", UserId);
    if (UserId) {
      fetchMarketSegments(UserId);
    }
  }, [UserId]);

  const checkIXPermission = useCallback(async userEmail => {
    const isProd = isProdEnv();
    try {
      let hasIXAccess = true;
      if (isProd) {
        const { data } = await getRequest(API_URLS.ixdUsers);
        hasIXAccess = data.some(({ email }) => email === userEmail);
      }
      dispatch(updateIXPermission(hasIXAccess));
    } catch (error) {
      Logger.error("Error fetching fetchIXDUser: ", error);
      if (isProd) {
        dispatch(updateIXPermission(false));
      }
    }
  }, []);

  const isTechDisabled = useCallback(
    techName => techName?.toUpperCase() === TECHNOLOGIES.IX && !isIXPermission,
    [isIXPermission],
  );

  const fetchGlobalData = () => {
    fetchCountries();
    fetchUnreadNotifications();
    fetchWaterLibraryList();
  };

  return {
    isTechDisabled,
    fetchGlobalData,
    checkIXPermission,
    fetchChemicalLibrary,
    fetchChemicalCategory,
  };
};

export default useGlobalDataHandler;
