import React from "react";
import { Col } from "react-bootstrap";

import UFSystemDesignDiagram from "../UFSystemDesignDiagram";

import UFConfigurationStyled from "./UFConfigurationStyled";
import UFModuleSelection from "./UFModuleSelection";
import UFRecommendedConfiguration from "./UFRecommendedConfiguration";
import UFSelectedConfiguration from "./UFSelectedConfiguration";
import UFSystemConfiguration from "./UFSystemConfiguration";

const Configuration = React.memo(() => (
  <>
    <UFConfigurationStyled className='g-0'>
      <UFSystemDesignDiagram />
      <Col lg={12} md={12} sm={12} className='configuration'>
        <div className='system-module-wrapper'>
          {/* System Configuration Card */}
          <UFSystemConfiguration />
          {/* Module Selection Card */}
          <UFModuleSelection tabIndex={0} />
        </div>
        {/* Selected Configuration Card */}
        <UFSelectedConfiguration />
        {/* Recommended Configurations */}
        <UFRecommendedConfiguration />
      </Col>
    </UFConfigurationStyled>
  </>
));

Configuration.displayName = "UF Configuration";
export default Configuration;
