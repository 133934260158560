import { reportStrings } from "@constants/report.strings";

import { RACKS_PER_UNIT } from "@features/feedwater/uf/constants/UFConstants";
import { useReportUtils } from "@features/report-new/useReportUtils";

import {
  T_RACK_CLASSIFICATION,
  WPUfModuleDesignTableColumns,
  WPUfModuleDesignTableRowsData,
  WPUfSystemSizeModuleDetailsTableRowsData as rowsData,
} from "./WPUfSystemSizeModuleDetailsTable.constants";

export const useWPUfSystemSizeModuleDetailsTableData = () => {
  const { ufReportRespData, formatValue, formatUnitLabel, formatConvertValue } = useReportUtils();
  const { uf_system_module } = ufReportRespData || {};

  const moduleType = uf_system_module?.module_design?.type;

  const convertToUfSystemSizeModuleDetailsTableData = () => {
    const data = rowsData.map(singleRowData => {
      const { unitLabel, unitValueKey, moduleLabel, moduleUnitType, moduleValueKey, moduleUnitPrecision } =
        singleRowData;
      const { units: UnitsRespData, module_details: moduleDetails } = uf_system_module;
      const isModuleName = moduleValueKey === "module_name";

      const moduleValue = moduleDetails?.[moduleValueKey]?.metric || moduleDetails?.[moduleValueKey];
      return {
        unitLabel,
        unitValue: formatValue(UnitsRespData?.[unitValueKey], 0), // precision is 0
        moduleLabel,
        moduleUnit: formatUnitLabel(moduleUnitType),
        moduleValue: isModuleName ? moduleValue : formatConvertValue(moduleValue, moduleUnitType, moduleUnitPrecision),
      };
    });

    return data;
  };

  const isTRack = moduleType?.classification === T_RACK_CLASSIFICATION;

  const convertToUfModuleDesignDetailsTableData = () => {
    const { module_design } = uf_system_module;
    if (isTRack) {
      return [];
    }
    const data = WPUfModuleDesignTableRowsData.reduce((acc, rowKey) => {
      const { text: unitValue, name: unitLabel, display } = module_design[rowKey] || {};

      if (!display) return acc;

      acc.push({
        unitLabel,
        unitValue: formatValue(unitValue, 0), // precision is 0
      });

      return acc;
    }, []);

    return data;
  };

  // table is shown only when display flag is true in the API response
  const showModuleDesignTable = moduleType?.display || false;

  const getTRackData = ({ units, module_design }) => {
    const value = {
      prefix: reportStrings.trOption,
      x: units.modules_per_unit,
      y: module_design.racks.text === RACKS_PER_UNIT.TR1 ? 2 : 4,
      z: module_design.racks.text === RACKS_PER_UNIT.TR4 ? 2 : 1,
    };
    return Object.values(value).join("-");
  };

  // modifying the column header name based on the API
  const getModuleDesignTableColumns = () => {
    const { units, module_design } = uf_system_module;
    const columns = [...WPUfModuleDesignTableColumns];
    columns[0].headerName = isTRack ? reportStrings.tRackTm : reportStrings.integraPacIpDesign;
    columns[1].headerName = isTRack ? getTRackData({ units, module_design }) : "";
    return columns;
  };

  return {
    convertToUfSystemSizeModuleDetailsTableData,
    convertToUfModuleDesignDetailsTableData,
    showModuleDesignTable,
    getModuleDesignTableColumns,
  };
};
