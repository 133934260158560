import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, { ReactFlowProvider } from "reactflow";

import { TECHNOLOGIES } from "@constants/global.constants";
import { UNIT_TYPES } from "@constants/units.constant";

import { useChemicalAdjustment } from "@hooks/useChemicalAdjustment";
import useGetProjectDetails from "@hooks/useGetProjectDetails";
import useGlobalDataHandler from "@hooks/useGlobalDataHandler";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUpto2Digits, isProdEnv, isSelectedTreatment } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import CloseBlackIcon from "@common/icons/CloseBlackIcon";
import InfoPictonBlueIcon from "@common/icons/InfoPictonBlueIcon";
import { updateUfReportLoading } from "@common/ReportUFSlice";
import CustomButton from "@common/styles/components/buttons/standard/CustomButton";
import TechButtons from "@common/styles/components/buttons/techButtons/TechButtons";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import { colors } from "@common/styles/Theme";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import { MyError } from "@common/utils/ErrorCreator";
import ErrorPopup from "@common/utils/ErrorPopup";
import { updateUnitTypeFlow } from "@common/utils/GlobalUnitConversionSlice";

import { useLazyGetAllDataQuery, useUpdateDataMutation } from "@services/apiConfig";

import { updateShowFinalParameterTab, updateViewReport } from "../ix/IXDSlice";
import { isIXTechnology } from "../ix/IXDUtilityFunction";

import CustomeEdge from "./customnodes/CustomeEdge";
import DeleteNode from "./customnodes/DeleteNode";
import EndPointNode from "./customnodes/EndPointNode";
import Technology from "./customnodes/technology.json";
import TechnologyNode from "./customnodes/TechnologyNode";
import FeedTechnologyStyled from "./FeedTechnologyStyled";
import {
  addNode,
  deleteNode,
  onConnect,
  onEdgesChange,
  onNodesChange,
  setLoading,
  setUpdateCanvas,
  setUpdateCategory,
} from "./processDiagramSlice";

import "reactflow/dist/style.css";
import "./index.css";

let id = 0;
const getId = () => `${id++}`;

const Logger = DupontLogger("FeedTechnology");

const FeedTechnology = () => {
  const { isTechDisabled } = useGlobalDataHandler();
  const [notification, setNotification] = useState(true);
  const [techAddedError, setTechAddedError] = useState(false);
  const [updateData, response] = useUpdateDataMutation();
  const [techCate, setTechCat] = useState([]);
  const [getCateData, responseCateData] = useLazyGetAllDataQuery();
  const dispatch = useDispatch();
  const reactFlowWrapper = useRef(null);
  const {
    nodes,
    edges,
    isLoading,
    techNolist,
    technologyAdded,
    feedWaterData,
    canvasStyle,
    selectedEndNode,
    feedFlowRate,
    productFlowRate,
    lstTechnologyLists,
    needToRetriveData,
    systemDesignCaseTreatmentVM,
  } = useSelector(state => state.processDiagramSlice);
  const FeedStreamData = useSelector(state => state.Feedsetupdetailsdatapanel.streamData);

  const StreamStoreData = FeedStreamData.lstrequestsavefeedwater[0]?.streams;
  const { ufReportLoading } = useSelector(state => state.ReportUF);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const userId = useSelector(state => state.userInfo.data.UserId);
  const { getCategoryData } = useSelector(state => state.processDiagramSlice);
  const projectID = ProjectInfoStore?.projectID ? ProjectInfoStore.projectID : 1;
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { getSystemDesign, fetchUFDetails } = useGetProjectDetails();
  const { convertToMetric } = useUnitConversion();

  const { fetchUFChemicalAdjustmentFieldsData } = useChemicalAdjustment();

  const nodeTypes = useMemo(
    () => ({
      endPointNode: EndPointNode,
      techNode: TechnologyNode,
      deleteType: DeleteNode,
    }),
    [],
  );

  const isInersect = ({ position }) => {
    const { width } = canvasStyle;
    const { x, y } = position;
    return x > width - 201.94 && x < width && y > 228 && y < 346;
  };
  useEffect(() => {
    dispatch(updateUnitTypeFlow(unit.selectedUnits[1]));
  }, [unit.selectedUnits[1]]);

  useEffect(() => {
    if (projectID !== 1 || getCategoryData) {
      getCateData(`masterdata/api/v1/CategoryTreatment?projectID=${projectID}`);
      handleResize();
    }
  }, [projectID, getCategoryData]);

  useEffect(() => {
    if (responseCateData.isError) {
      throw new MyError("CategoryTreatment Api Error", responseCateData.error.status, "ApiError");
    }
    if (responseCateData.isSuccess) {
      const temp = [];
      responseCateData.data.map(cat => {
        const catName = isIXTechnology(cat.treatmentName) ? "IX" : cat.treatmentName;
        if (cat.isSelected === 1 && !isTechDisabled(catName)) {
          temp.push(cat.treatmentName);
        }
      });
      setTechCat(temp);
      dispatch(setUpdateCategory(false));
    }
  }, [responseCateData]);

  const handleClose = () => {
    setNotification(false);
  };

  const onDragStart = (event, nodeType) => {
    const transferData = JSON.stringify(nodeType);
    event.dataTransfer.setData("application/reactflow", transferData);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  useEffect(() => {
    const currViewPort = reactFlowInstance?.getViewport?.();
    if (currViewPort && currViewPort.x !== 0) {
      reactFlowInstance.setViewport({ x: 0, y: 0, zoom: 1 });
    }
  }, [reactFlowInstance]);

  const onDrop = event => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const { type, ...data } = JSON.parse(event.dataTransfer.getData("application/reactflow"));

    if (typeof type === "undefined" || !type) {
      return;
    }
    if (data.label === "Adjust Final pH") {
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const id = getId();
      const newNode = {
        id: id,
        type,
        position,
        data: data,
      };
      dispatch(addNode(newNode));
      dispatch(updateViewReport("false"));
      dispatch(updateShowFinalParameterTab(false));
    } else {
      if (technologyAdded) {
        setTechAddedError(true);
      } else {
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        const id = getId();
        const newNode = {
          id: id,
          type,
          position,
          data: data,
        };
        dispatch(addNode(newNode));
        dispatch(updateViewReport("false"));
        dispatch(updateShowFinalParameterTab(false));
      }
    }
    event.preventDefault();
  };

  const isValidConnection = edge => {
    const { target, source } = edge;
    const targetAvailable = edges.find(item => item.target === target);
    const sourceAvailable = edges.find(item => item.source === source);
    return !targetAvailable && !sourceAvailable;
  };
  //dj---------------------------------------------------------

  const handleResize = () => {
    if (reactFlowWrapper.current) {
      const containerWidth = reactFlowWrapper.current.offsetWidth;
      dispatch(
        setUpdateCanvas({
          width: containerWidth,
          height: reactFlowWrapper.current.offsetHeight,
        }),
      );
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // handleResize();
  useEffect(() => {
    handleResize();
  }, [feedWaterData, reactFlowWrapper, ufReportLoading]);
  //--------------------------------------------------------
  useEffect(() => {
    if (needToRetriveData) {
      const flowValue = selectedEndNode == "startNode" ? feedFlowRate : productFlowRate;
      updateData({
        Method: "masterdata/api/v1/SystemDesign",
        feedFlow: selectedEndNode != "startNode",
        flowValue: convertUpto2Digits(convertToMetric(flowValue, UNIT_TYPES.FLOW)),
        caseID: feedWaterData.caseID,
        projectID: feedWaterData.projectID,
        waterTypeID: feedWaterData.waterTypeID,
        userID: userId,
        processMap: { nodes: nodes, edges: edges },
        lstTechnologyLists,
      });
    }
  }, [needToRetriveData]);

  // when the treatment is changed refetch the system design to get treatment details
  useEffect(() => {
    if (response.isSuccess) {
      fetchSysDesignAndUFdetails();
    }
  }, [response]);

  const fetchSysDesignAndUFdetails = async () => {
    try {
      setLoading(true);
      const { systemDesignCaseTreatmentVM } = await getSystemDesign({
        caseID: feedWaterData.caseID,
        projectID: feedWaterData.projectID,
        userID: userId,
      });

      const caseTreatmentID = systemDesignCaseTreatmentVM?.[0]?.caseTreatmentID;

      if (isSelectedTreatment(TECHNOLOGIES.UF, systemDesignCaseTreatmentVM)) {
        fetchUFChemicalAdjustmentFieldsData(caseTreatmentID);
        await fetchUFDetails({
          caseID: feedWaterData.caseID,
          projectID: feedWaterData.projectID,
          userID: userId,
          treatmentObjID: caseTreatmentID,
          feedWaterDataResp: StreamStoreData,
        });
      }
    } catch (error) {
      Logger.error("Error refetching system design or uf details:", error);
    }
    dispatch(updateUfReportLoading(false));
    setLoading(false);
  };

  const isUFReportDataLoading = () =>
    ufReportLoading && systemDesignCaseTreatmentVM.find(item => item.treatmentName == "UF");

  const isIXDDisabled = ({ label }) =>
    (isProdEnv() && label === TECHNOLOGIES.IXD) || !techCate.includes(label) === !techNolist.includes(label);

  return (
    <>
      <FeedTechnologyStyled className='g-0'>
        {notification && (
          <div className='notification'>
            <div className='wrapper'>
              <InfoPictonBlueIcon />
              <ul className='notification-details'>
                <li>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                    label='You are viewing technologies as per your preference and can edit them from Project Settings.'
                  />
                </li>
                <li>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                    label='Specify either Feed flow rate OR Product flow rate.'
                  />
                </li>
                <li>
                  <CustomHeading
                    fontFamily='DiodrumRegular'
                    fontSize='14px'
                    fontWeight='400'
                    color={colors.Black}
                    label='Select technologies by dragging and dropping the icons from the top panel.'
                  />
                </li>
              </ul>
            </div>
            <CustomButton label={<CloseBlackIcon />} id='closeBtn' onClick={handleClose} />
          </div>
        )}
        <ErrorPopup
          show={techAddedError}
          close={() => setTechAddedError(false)}
          message={"Only one technology can be added!"}
        />

        <div className='water-technology'>
          {Technology.data.map((tech, index) => (
            <StyledCard className={tech.className} key={index} borderRadius='4px'>
              <div className='process-name'>
                <CustomHeading
                  label={tech.techName}
                  fontFamily='DiodrumRegular'
                  fontSize='12px'
                  fontWeight='400'
                  color={colors.Black}
                />
              </div>
              <div className='tech-btn-group'>
                {tech.subTech.map((item, ind) => (
                  <div
                    key={ind}
                    onDragStart={event =>
                      onDragStart(event, {
                        ...item.nodeData,
                        treatmentObjID: item.treatmentObjID,
                      })
                    }
                    draggable={techCate.includes(item.label)}
                  >
                    <TechButtons
                      label={item.label}
                      disabled={isIXDDisabled(item)}
                      isDraggable
                      isArrow={item.isArrow}
                      id={item.id}
                      onClick={() => (technologyAdded ? setTechAddedError(true) : null)}
                    />
                  </div>
                ))}
              </div>
            </StyledCard>
          ))}
          <StyledCard className='bulk-demineralization' borderRadius='4px'>
            <div className='process-name'>
              <CustomHeading
                label='Water Chemistry Adjustments'
                fontFamily='DiodrumRegular'
                fontSize='12px'
                fontWeight='400'
                color={colors.Black}
              />
            </div>
            <div className='tech-btn-group'>
              <div
                onDragStart={event =>
                  onDragStart(event, {
                    label: "Adjust Final pH",
                    color: "green",
                    type: "techNode",

                    treatmentObjID: 12,
                  })
                }
                draggable={false}
              >
                <TechButtons label='Adjust Final pH' disabled={true} isArrow={false} id='APH' isDraggable={true} />
              </div>
            </div>
          </StyledCard>
        </div>

        <DynamicLoadder isLoading={isLoading || isUFReportDataLoading()}>
          <div className='feed-water-process'>
            <div className='sys-diagram-wrapper'>
              <div className='dndflow'>
                <ReactFlowProvider>
                  <div className='reactflow-wrapper' ref={reactFlowWrapper}>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      nodeTypes={nodeTypes}
                      edgeTypes={{ customeEdge: CustomeEdge }}
                      onNodeDragStop={(e, node) => {
                        if (isInersect(node)) dispatch(deleteNode(node));
                      }}
                      onNodesChange={e => dispatch(onNodesChange(e))}
                      onEdgesChange={e => dispatch(onEdgesChange(e))}
                      onConnect={e => dispatch(onConnect(e))}
                      onInit={setReactFlowInstance}
                      onDrop={onDrop}
                      onDragOver={onDragOver}
                      preventScrolling={false}
                      isValidConnection={edge => isValidConnection(edge)}
                      deleteKeyCode={"Delete"}
                      panOnDrag={false}
                      panOnScroll={false}
                      zoomOnScroll={false}
                      zoomOnDoubleClick={false}
                      zoomOnPinch={false}
                      onNodesDelete={node => dispatch(deleteNode(node[0]))}
                      nodeExtent={[
                        [0, 0],
                        [canvasStyle.width, 346],
                      ]}
                      translateExtent={[
                        [0, 0],
                        [canvasStyle.width, 346],
                      ]}
                      Viewport={{ x: 0, y: 0, zoom: 1 }}
                    ></ReactFlow>
                  </div>
                </ReactFlowProvider>
              </div>
            </div>
          </div>
        </DynamicLoadder>
      </FeedTechnologyStyled>
    </>
  );
};

export default FeedTechnology;
