import React, { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useSearchFunction from "@hooks/useSearchFilter";

import { resetFilter, updateHomeFilterData } from "@common/GlobalDataSlice";
import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import CloseIcon from "@common/icons/CloseIcon";
import ErrorSearchIcon from "@common/icons/ErrorSearchIcon";
import GridViewActiveIcon from "@common/icons/GridViewActiveIcon";
import GridViewHoveredIcon from "@common/icons/GridViewHoveredIcon";
import GridViewIcon from "@common/icons/GridViewIcon";
import ListViewActiveIcon from "@common/icons/ListViewActiveIcon";
import ListViewHoverIcon from "@common/icons/ListViewHoverIcon";
import ListViewIcon from "@common/icons/ListViewIcon";
import SearchIcon from "@common/icons/SearchIcon";
import ErrorMessage from "@common/styles/components/headings/ErrorMessage";
import SearchInputBox from "@common/styles/components/inputs/SearchInputBox";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import CustomSelect from "@common/styles/components/selects/CustomSelect";

import { TECH_IX, TECH_IXMB } from "@features/feedwater/ix/constants/IXDConstants";

import { btnlist, sortData2, updateFlag, updateLoader, updateOrder, updatetitle } from "../home/CardListSlice";
import ViewAllFolder from "../home/ViewAllFolder";
import ViewAllFolderListView from "../home/ViewAllFolderListView";
import { Folderbtnlist, FolderupdateOrder, sortFolderData } from "../home/ViewAllFolderSlice";

import CardDetails from "./CardDetails";
import CardList from "./CardList";
import SearchFilterStyled from "./SearchFilterStyled";

const SearchFilter = ({ showSideMenu }) => {
  const { marketSegments } = useSelector(state => state.globalAppData);
  const {
    isIX: ixCheck,
    isUF: ufCheck,
    selectedBrand: selectedSegmentValue,
  } = useSelector(state => state.globalAppData.filter);
  const [viewfolder, setviewfolder] = useState(true);
  const StoreSidLeftpanel = useSelector(state => state.leftpanel.data);
  const { flag } = useSelector(state => state.cardlist);
  const ref = useRef([]);
  const [cardDetail, setCardDetail] = useState(true);
  const [txtvalue, setTxtvalue] = useState("");
  const [sortlable, setsortlable] = useState(flag);
  const [isFocused, setIsFocused] = useState(null);
  const [isError, setIsError] = useState(false);

  const getInitialState = () => {
    const value = null;
    return value;
  };

  useEffect(() => {
    setsortlable(flag);
  }, [flag]);

  const filterSort = [
    { label: "Date Created", value: "Date Created" },
    { label: "Last Modified", value: "Last Modified" },
    { label: "Project Name (ascending)", value: "Project Name (ascending)" },
    { label: "Project Name (descending)", value: "Project Name (descending)" },
  ];

  const filterSortFolderList = [
    { label: "Date Created", value: "Date Created" },
    { label: "Last Modified", value: "Last Modified" },
    { label: "Folder Name (ascending)", value: "Folder Name (ascending)" },
    { label: "Folder Name (descending)", value: "Folder Name (descending)" },
  ];

  const [mouseEnter, setMouseEnter] = useState(null);
  const [txtbrand, setTextbrand] = useState(getInitialState);
  const StoreData = useSelector(state => state.cardlist.data);
  const TempStoreData = useSelector(state => state.cardlist.Temdata);
  const FolderTempStoreData = useSelector(state => state.Folderview.Temdata);
  const FolderStoreData = useSelector(state => state.Folderview.data);

  const dispatch = useDispatch([]);
  const [Technology, setTechnology] = useState({
    technologes: [],
    response: [],
  });

  const searchFunction = useSearchFunction();

  useEffect(() => {
    dispatch(updateLoader(true));
  }, [dispatch]);

  useEffect(() => {
    btnclear();
  }, [StoreSidLeftpanel]);

  useEffect(() => {
    if (StoreSidLeftpanel == "masterdata/api/v1/FolderList") {
      if (sortlable.includes("Project")) {
        const temp = sortlable.replace("Project", "Folder");
        setsortlable(temp);
        RadioChange({ target: { value: temp } });
      }
      if (sortlable == "Last Modified") {
        setsortlable("Date Created");
        RadioChange({ target: { value: "Date Created" } });
      }
    } else {
      if (sortlable.includes("Folder")) {
        const temp = sortlable.replace("Folder", "Project");
        setsortlable(temp);
        RadioChange({ target: { value: temp } });
      }
    }
  }, [StoreSidLeftpanel]);

  const CallData = () => {
    setCardDetail(true);
    setviewfolder(true);
    RadioChange({ target: { value: sortlable } });
  };
  const CallDetail = () => {
    setCardDetail(false);
    setviewfolder(false);
    RadioChange({ target: { value: sortlable } });
  };

  const txtchange = e => {
    const inputValue = e.target.value;

    const regex = /^[a-zA-Z0-9-_\s]+$/;

    if (inputValue === "") {
      btnCleartext();
      setTxtvalue("");
      setIsError(false);
    } else if (regex.test(inputValue)) {
      setTxtvalue(inputValue);
      setIsError(false);
    } else {
      setIsError(true);
      setTxtvalue(inputValue);
    }
  };
  const btnCleartext = () => {
    if (StoreSidLeftpanel == "masterdata/api/v1/FolderList") {
      dispatch(Folderbtnlist(FolderTempStoreData));
    } else {
      dispatch(btnlist(TempStoreData));
    }
  };
  const SearchTxt = () => {
    if (txtvalue === "") {
      setTxtvalue("");
    }
    let _RecentProject;
    if (StoreSidLeftpanel == "masterdata/api/v1/FolderList") {
      if (txtvalue !== "") {
        _RecentProject = FolderTempStoreData.filter(data =>
          data.folderName?.toUpperCase().includes(txtvalue.toUpperCase()),
        );
        dispatch(Folderbtnlist(_RecentProject));
      } else {
        dispatch(Folderbtnlist(FolderTempStoreData));
      }
    } else {
      if (txtvalue !== "") {
        _RecentProject = TempStoreData.filter(
          data =>
            data.projectName?.toUpperCase().includes(txtvalue.toUpperCase()) ||
            data.tagName?.toUpperCase().includes(txtvalue.toUpperCase()),
        );
        dispatch(btnlist(_RecentProject));
      } else {
        dispatch(btnlist(TempStoreData));
      }
    }
  };
  const handleDropdownSelect = e => {
    setsortlable(e);
  };
  const RadioChange = e => {
    if (StoreSidLeftpanel == "masterdata/api/v1/FolderList") {
      if (FolderTempStoreData.length > 0) {
        if (e.target.value !== null || e.target.value !== undefined) {
          setsortlable(e.target.value);
          if (e.target.value === "Date Created") {
            dispatch(FolderupdateOrder("A"));
            dispatch(updateFlag("Date Created"));
            dispatch(sortFolderData({ flag: "CD", data: FolderStoreData }));
            dispatch(sortData2({ flag: "CD", data: StoreData }));
          } else if (e.target.value === "Last Modified") {
            dispatch(FolderupdateOrder("A"));
            dispatch(updateFlag("Last Modified"));
            dispatch(sortFolderData({ flag: "MD", data: FolderStoreData }));
            dispatch(sortData2({ flag: "MD", data: StoreData }));
          } else if (e.target.value === "Folder Name (ascending)") {
            dispatch(updateOrder("A"));
            dispatch(FolderupdateOrder("A"));
            dispatch(updateFlag("Folder Name (ascending)"));
            dispatch(sortFolderData({ flag: "PN", data: FolderStoreData }));
            dispatch(sortData2({ flag: "PN", data: StoreData }));
          } else {
            dispatch(FolderupdateOrder("D"));
            dispatch(updateOrder("D"));
            dispatch(updateFlag("Folder Name (descending)"));
            dispatch(sortFolderData({ flag: "PN", data: FolderStoreData }));
            dispatch(sortData2({ flag: "PN", data: StoreData }));
          }
        }
      }
    } else {
      if (TempStoreData.length > 0) {
        if (e.target.value !== null || e.target.value !== undefined) {
          setsortlable(e.target.value);
          if (e.target.value === "Last Modified") {
            dispatch(updateOrder("A"));
            dispatch(updateFlag("Last Modified"));
            dispatch(sortData2({ flag: "MD", data: StoreData }));
          }
          if (e.target.value === "Date Created") {
            dispatch(updateOrder("A"));
            dispatch(updateFlag("Date Created"));
            dispatch(sortData2({ flag: "CD", data: StoreData }));
          }
          if (e.target.value === "Project Name (ascending)") {
            dispatch(updateOrder("A"));
            dispatch(updateFlag("Project Name (ascending)"));
            dispatch(sortData2({ flag: "PN", data: StoreData }));
          }
          if (e.target.value === "Project Name (descending)") {
            dispatch(updateOrder("D"));
            dispatch(updateFlag("Project Name (descending)"));
            dispatch(sortData2({ flag: "PN", data: StoreData }));
          }
        }
      }
    }
  };
  const drpChange = e => {
    const targetValue = e.target.value;
    dispatch(updateHomeFilterData({ selectedBrand: targetValue }));
    setTextbrand(e.target.value);
    setTimeout(() => {
      searchFunction(Technology, targetValue, TempStoreData, txtbrand);
    }, 1);
  };
  const btnclear = () => {
    setTechnology({
      technologes: [],
      response: [],
    });
    setTextbrand(null);
    dispatch(resetFilter());
    dispatch(btnlist(TempStoreData));
    dispatch(updatetitle("PN"));
    dispatch(updateOrder("A"));
    searchFunction(Technology, null, TempStoreData, txtbrand);
  };
  useEffect(() => {
    searchFunction(Technology, null, TempStoreData, txtbrand);
  }, [Technology]);

  const handleChange = e => {
    const { value, checked } = e.target;
    const { technologes } = Technology;
    if (value == TECH_IX || value == TECH_IXMB) {
      dispatch(updateHomeFilterData({ isIX: checked }));
    } else {
      dispatch(updateHomeFilterData({ isUF: checked }));
    }
    if (checked) {
      setTechnology({
        technologes: [...technologes, value],
        response: [...technologes, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setTechnology({
        technologes: technologes.filter(e => e !== value),
        response: technologes.filter(e => e !== value),
      });
    }
  };

  const handleHover = id => {
    setMouseEnter(id);
  };

  const handleUnHover = () => {
    setMouseEnter(null);
  };

  const handleFocus = id => {
    setIsFocused(id);
  };

  const handleBlur = () => {
    setIsFocused(null);
  };
  const handleKeyDown = e => {
    switch (e.key) {
      case "Enter": {
        let _RecentProject;
        if (txtvalue === "") {
          setTxtvalue("");
        }

        if (StoreSidLeftpanel == "masterdata/api/v1/FolderList") {
          if (txtvalue !== "") {
            _RecentProject = FolderTempStoreData.filter(data =>
              data.folderName.toUpperCase().includes(txtvalue.toUpperCase()),
            );
            dispatch(Folderbtnlist(_RecentProject));
          } else {
            dispatch(Folderbtnlist(FolderTempStoreData));
          }
        } else {
          if (txtvalue !== "") {
            _RecentProject = TempStoreData.filter(
              data =>
                data.projectName.toUpperCase().includes(txtvalue.toUpperCase()) ||
                data.tagName.toUpperCase().includes(txtvalue.toUpperCase()),
            );
            dispatch(btnlist(_RecentProject));
          } else {
            dispatch(btnlist(TempStoreData));
          }
        }
        break;
      }
    }
  };

  return (
    <>
      <SearchFilterStyled showSideMenu={showSideMenu}>
        <Row className='search-filter'>
          <Col lg={6} md={6} sm={6} xs={6}>
            <div className='search-box'>
              {StoreSidLeftpanel == "masterdata/api/v1/FolderList" ? (
                <>
                  <SearchInputBox
                    type='text'
                    placeholder={!isError ? "Search Folders" : "wrong text"}
                    onChange={e => txtchange(e)}
                    icon={!isError ? <SearchIcon /> : <ErrorSearchIcon />}
                    unitBgColor='transparent'
                    onSearch={() => SearchTxt()}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(1)}
                    isFocused={isFocused === 1}
                    inputText={isError ? <CloseCircleRedIcon /> : txtvalue != "" ? <CloseCircleGreenIcon /> : ""}
                    isError={isError}
                    disabled={false}
                    value={txtvalue}
                    onKeyDown={handleKeyDown}
                  />
                  <ErrorMessage
                    errorIcon={true}
                    style={{ visibility: isError ? "visible" : "hidden" }}
                    texMsg={"Please check your input or clear this field."}
                  />
                </>
              ) : (
                <>
                  <SearchInputBox
                    type='text'
                    placeholder={!isError ? "Search Projects" : "wrong text"}
                    onChange={e => txtchange(e)}
                    icon={!isError ? <SearchIcon /> : <ErrorSearchIcon />}
                    unitBgColor='transparent'
                    onSearch={() => SearchTxt()}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(1)}
                    isFocused={isFocused === 1}
                    inputText={isError ? <CloseCircleRedIcon /> : txtvalue != "" ? <CloseCircleGreenIcon /> : ""}
                    isError={isError}
                    disabled={false}
                    value={txtvalue}
                    onKeyDown={handleKeyDown}
                  />
                  <ErrorMessage
                    style={{ visibility: isError ? "visible" : "hidden" }}
                    texMsg={"Please check your input or clear this field."}
                  />
                </>
              )}
            </div>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6} className='d-flex filter'>
            {StoreSidLeftpanel == "masterdata/api/v1/FolderList" ? (
              ""
            ) : (
              <Dropdown className='filter-dropdown'>
                <Dropdown.Toggle variant='success' id='dropdown-basic'>
                  Filter
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <h6>By Technology</h6>
                  <div className='checkbox-group d-flex'>
                    <Form.Group className='mb-3' controlId='formBasicCheckbox'>
                      <Form.Check
                        type='checkbox'
                        name='Technology'
                        checked={ufCheck}
                        ref={element => {
                          ref.current[0] = element;
                        }}
                        value='UF'
                        label='UF'
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicCheckbox'>
                      <Form.Check
                        type='checkbox'
                        name='Technology'
                        ref={element => {
                          ref.current[2] = element;
                        }}
                        checked={ixCheck}
                        value='IX'
                        label='IX'
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <h6 className='market-segment-filter'>By Project Market Segment</h6>
                  <CustomSelect name='marketSegmentID' onChange={drpChange} value={selectedSegmentValue} required>
                    <option>All</option>
                    {marketSegments?.map((data, i) => (
                      <option key={i} value={data.marketSegmentID}>
                        {data.segmentName}
                      </option>
                    ))}
                    ;
                  </CustomSelect>
                  <div className='clear-filter'>
                    <button onClick={btnclear}>
                      Clear Filter <CloseIcon />
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <Dropdown className='sort-dropdown' onSelect={handleDropdownSelect}>
              <Dropdown.Toggle variant='success' id='dropdown-basic'>
                Sort:<p className='selected-option'>{sortlable}</p>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {StoreSidLeftpanel == "masterdata/api/v1/FolderList" ? (
                  <div className='checkbox-group'>
                    {filterSortFolderList?.map(SortData => (
                      <CustomRadio
                        type='radio'
                        fontFamily='NotoSansRegular'
                        lineHeight='22px'
                        className='sort-radios'
                        name='radiosort'
                        label={SortData.label}
                        key={SortData.label}
                        value={SortData.value}
                        checked={SortData.value === sortlable}
                        onClick={RadioChange}
                      />
                    ))}
                  </div>
                ) : (
                  <div className='checkbox-group'>
                    {filterSort?.map(SortData => (
                      <CustomRadio
                        fontFamily='NotoSansRegular'
                        lineHeight='22px'
                        type='radio'
                        className='sort-radios'
                        name='radiosort'
                        label={SortData.label}
                        key={SortData.label}
                        value={SortData.value}
                        checked={SortData.value === sortlable}
                        onClick={RadioChange}
                      />
                    ))}
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>
            {StoreSidLeftpanel == "masterdata/api/v1/FolderList" ? (
              <>
                <div
                  className='grid-icon'
                  onClick={CallData}
                  onMouseMove={() => handleHover("g")}
                  onMouseLeave={handleUnHover}
                >
                  {viewfolder === true ? (
                    <GridViewActiveIcon />
                  ) : mouseEnter === "g" ? (
                    <GridViewHoveredIcon />
                  ) : (
                    <GridViewIcon />
                  )}
                </div>
                <div
                  className='list-icon'
                  onClick={CallDetail}
                  onMouseMove={() => handleHover("l")}
                  onMouseLeave={handleUnHover}
                >
                  {viewfolder === false ? (
                    <ListViewActiveIcon />
                  ) : mouseEnter === "l" ? (
                    <ListViewHoverIcon />
                  ) : (
                    <ListViewIcon />
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  className='grid-icon'
                  onClick={CallData}
                  onMouseMove={() => handleHover("g")}
                  onMouseLeave={handleUnHover}
                >
                  {viewfolder === true ? (
                    <GridViewActiveIcon />
                  ) : mouseEnter === "g" ? (
                    <GridViewHoveredIcon />
                  ) : (
                    <GridViewIcon />
                  )}
                </div>
                <div
                  className='list-icon'
                  onClick={CallDetail}
                  onMouseMove={() => handleHover("l")}
                  onMouseLeave={handleUnHover}
                >
                  {viewfolder === false ? (
                    <ListViewActiveIcon />
                  ) : mouseEnter === "l" ? (
                    <ListViewHoverIcon />
                  ) : (
                    <ListViewIcon />
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </SearchFilterStyled>
      {StoreSidLeftpanel == "masterdata/api/v1/FolderList" ? (
        viewfolder ? (
          <ViewAllFolder />
        ) : (
          <ViewAllFolderListView />
        )
      ) : cardDetail ? (
        <CardDetails />
      ) : (
        <CardList />
      )}
    </>
  );
};

export default SearchFilter;
