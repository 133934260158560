import { useEffect, useMemo, useState } from "react";

import { CustomModalButtons } from "@constants/global.constants";

import { convertUptoDigits } from "@utils/appUtils";

import CustomModal from "@components/CustomModal";
import IconButton from "@components/IconButton";
import { BackIcon } from "@components/SVGs";
import { useToastNotification } from "@components/WPToastNotification";

import { CHEMICAL_FIELDS } from "../chemicalLibrary.constants";
import CHEMICALS_LIBRARY_STRINGS from "../chemicalLibrary.strings";
import useChemicalLibrary from "../ChemicalLibraryHooks/useChemicalLibrary";
import useChemicalUnitConversion from "../ChemicalLibraryHooks/useChemicalUnitConversion";

import ChemicalLibAddEditDisplaySettings from "./ChemicalLibAddEditDisplaySettings";
import { ChemicalLibDropdown, ChemicalLibInputBox } from "./ChemicalLibFormControls";
import {
    CHEMICAL_DENSITY_RANGE,
    CHEMICAL_WITH_FIXED_DENSITY,
    EXCLUDED_CONTROLS,
    SYSTEM_CHEMICAL_DISABLE_FIELDS,
    SYSTEM_CHEMICAL_LIST,
} from "./ChemicalLibraryAddEditDialog.constants";

import "./ChemicalLibraryAddEditDialog.scss";

const Header = ({ isEdit, onClose }) => (
    <div>
        <IconButton tooltip={CHEMICALS_LIBRARY_STRINGS.backBtnTitle} onClick={onClose}>
            <BackIcon />
        </IconButton>
        {isEdit ? CHEMICALS_LIBRARY_STRINGS.editChemicalDlgHeading : CHEMICALS_LIBRARY_STRINGS.newChemicalDlgHeading}
    </div>
);

const ChemicalLibraryAddEditDialog = ({ onClose, formData }) => {
    const { isEdit, defaultFormControls } = formData;
    const { showSuccessNotification, showErrorNotification } = useToastNotification();
    const { convertChemicalDensity, convertChemicalCurrency } = useChemicalUnitConversion();
    const { loaderText, chemicalCategoryData, fetchChemicalCategory, addEditChemical, calculateChemicalBulkDensity } =
        useChemicalLibrary();

    const [inValidFields, setInvalidFields] = useState([]);
    const [formControls, setFormControls] = useState(defaultFormControls);

    useEffect(() => {
        fetchChemicalCategory();
    }, []);

    useEffect(() => {
        const { chemicalCategories } = chemicalCategoryData;
        if (chemicalCategories) {
            const newControls = { ...formControls };
            newControls.category.options = chemicalCategories;
            setFormControls(newControls);
        }
    }, [chemicalCategoryData]);

    const filteredControls = useMemo(
        () => Object.values(formControls).filter(control => !EXCLUDED_CONTROLS.includes(control.id)),
        [formControls],
    );

    const isFieldDisabled = (chemical, fieldId) => {
        let isDisabled = !SYSTEM_CHEMICAL_LIST.includes(chemical) && SYSTEM_CHEMICAL_DISABLE_FIELDS.includes(`${fieldId}`);

        if (fieldId === CHEMICAL_FIELDS.bulkDensity) {
            isDisabled = CHEMICAL_WITH_FIXED_DENSITY.includes(chemical);
        }
        return isDisabled;
    };

    const updateControlsData = data => {
        const {
            chemical: { options },
        } = formControls;
        return Object.keys(formControls).reduce((acc, id) => {
            const item = { ...formControls[id], value: data[id] };

            if (id === CHEMICAL_FIELDS.bulkConcentration) {
                item.range = CHEMICAL_DENSITY_RANGE[data.name] || { min: 0, max: 100 };
            } else if (id === CHEMICAL_FIELDS.chemical) {
                item.options = options;
            }

            item.disabled = isFieldDisabled(data.name, id);
            acc[id] = item;
            return acc;
        }, {});
    };

    const handleModalClose = async ({ target: { id } }) => {
        if (id !== CustomModalButtons.CONFIRM) {
            return onClose();
        }

        const data = Object.values(formControls).reduce((acc, { id, value }) => {
            let formattedValue = value;
            if (id === CHEMICAL_FIELDS.bulkDensity) {
                formattedValue = convertChemicalDensity(value, true);
            } else if (id === CHEMICAL_FIELDS.bulkPrice) {
                formattedValue = convertChemicalCurrency(value, true);
            }
            return { ...acc, [id]: formattedValue };
        }, {});

        if (!isEdit) {
            data.id = undefined;
            data.isSystem = false;
        }
        const { isSuccess, message } = await addEditChemical(data);

        if (isSuccess) {
            showSuccessNotification(message);
            onClose();
        } else {
            showErrorNotification(message);
        }
    };

    const handleControlChange = event => {
        const { id, data } = event;
        let newControls = { ...formControls };
        if (id === CHEMICAL_FIELDS.category) {
            const chemicalOptions = chemicalCategoryData.chemicalsByCategoryId[data.id];
            newControls = updateControlsData(chemicalOptions[0]);
            newControls.category.value = data.name;
            newControls.chemical.options = chemicalOptions;
            newControls.chemical.disabled = false;
            newControls = formatControls(newControls);
        } else if (id === CHEMICAL_FIELDS.chemical) {
            newControls = formatControls(updateControlsData(data));
        } else if (id === CHEMICAL_FIELDS.symbol) {
            const { value } = data;
            const concentration = newControls.bulkConcentration.value;
            newControls.symbol.value = value;
            newControls.displayName.value = `${value} (${concentration})`;
        } else {
            newControls = { ...newControls, [id]: { ...newControls[id], value: data.value } };
        }
        setFormControls(newControls);
    };

    const getDensityValue = chemDetail => {
        const isFixedDensityChem = chemDetail ? CHEMICAL_WITH_FIXED_DENSITY.includes(chemDetail.name) : false;
        if (isFixedDensityChem) {
            const density = calculateChemicalBulkDensity(chemDetail);
            const convertedDensity = convertChemicalDensity(density);
            return convertUptoDigits(convertedDensity, 4);
        }
        return chemDetail.bulkDensity;
    };

    const formatControls = (formControls, bulkConcentration, formatPrice = true) => {
        const { bulkPrice, bulkDensity, categoryId, chemical, symbol } = formControls;
        bulkConcentration = bulkConcentration || formControls.bulkConcentration.value;
        const chemDetail = {
            categoryId: categoryId.value,
            name: chemical.value,
            bulkDensity: bulkDensity.value,
            bulkConcentration,
        };
        const newControls = { ...formControls };
        symbol.value = symbol.value || chemical.value;
        newControls.bulkDensity.value = getDensityValue(chemDetail);
        newControls.bulkPrice.value = formatPrice ? convertChemicalCurrency(bulkPrice.value) : bulkPrice.value;
        newControls.symbol = symbol;
        newControls.displayName.value = symbol.value + `(${bulkConcentration})`;
        return newControls;
    };

    const handleBlur = ({ id, value, isInvalid }) => {
        if (id === CHEMICAL_FIELDS.bulkConcentration && !isInvalid) {
            const newControls = formatControls({ ...formControls }, value, false);
            setFormControls(newControls);
        }
        setInvalidFields(prev => (isInvalid ? [...prev, id] : prev.filter(fieldId => fieldId !== id)));
    };

    const isFormInvalid = useMemo(
        () => !formControls.category.value || Boolean(inValidFields.length),
        [formControls, inValidFields],
    );

    return (
        <CustomModal
            header={<Header isEdit={isEdit} onClose={onClose} />}
            confirmBtn={CHEMICALS_LIBRARY_STRINGS.saveChemical}
            onModalClose={handleModalClose}
            width='850px'
            confirmBtnDisabled={isFormInvalid}
            isLoading={Boolean(loaderText)}
            loadingText={loaderText}
            hideCloseIcon
        >
            <div className='add-edit-chemical-form--container'>
                {filteredControls.map(control => (
                    <div className='column' key={control.id}>
                        {control.type === "dropdown" ? (
                            <ChemicalLibDropdown control={control} onControlChange={handleControlChange} />
                        ) : (
                            <ChemicalLibInputBox control={control} onControlChange={handleControlChange} onBlur={handleBlur} />
                        )}
                    </div>
                ))}
                <ChemicalLibAddEditDisplaySettings controls={formControls} onControlChange={handleControlChange} />
            </div>
        </CustomModal>
    );
};

export default ChemicalLibraryAddEditDialog;
