import html2pdf from "html2pdf.js";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUfCalcReport } from "src/api/reportApi/reportApi";

import { COAGULANT_CHEMICAL_MAPPING } from "@constants/chemical-symbol.constants";
import { APP_TOAST_NOTIFICATION } from "@constants/message.constants";
import { reportStrings } from "@constants/report.strings";

import { isEmptyObject } from "@utils/appUtils";

import { useFullScreenLoader } from "@components/WPFullScreenLoader";
import { useToastNotification } from "@components/WPToastNotification";

import { setUfCalcReportApiResp } from "@common/ReportSlice";

import { formatReportPayload } from "@features/feedwater/uf/ufUtils";

import useProjectDetails from "./useProjectDetails";
import useUFConfig from "./useUFConfig";

export const useReport = () => {
  const { getConfigPayload } = useUFConfig();
  const { projectDetails, activeCaseId: caseID } = useProjectDetails();
  const { showFullScreenLoader, hideFullScreenLoader } = useFullScreenLoader();

  const { projectID, projectName, activeCaseName } = projectDetails;
  const dispatch = useDispatch();

  const { showSuccessNotification, showErrorNotification } = useToastNotification();

  const { fetchUFConfig } = useUFConfig();

  const { error: ufCalcReportErr, isLoading: ufCalcReportLoading } = useSelector(
    state => state.report.ufCalcReportApiResp,
  );
  const { calcEngineData, isUFConfigLoading } = useSelector(state => state.UFStore);

  const isUfReportErr = Boolean(ufCalcReportErr) || isEmptyObject(calcEngineData);

  const isUfReportLoading = isUFConfigLoading || ufCalcReportLoading;

  const formatReportResp = (reportData, symbol) => {
    const isFe = symbol?.includes("Fe");
    const isSymbolOverrided = COAGULANT_CHEMICAL_MAPPING[symbol];
    if (isSymbolOverrided) {
      reportData.flow_details.feed_coag.name = symbol;
      reportData.pump_electrical.feed_PACl.name = symbol;
      if (isFe) {
        reportData.Chemicals.FeCl3.symbol = symbol;
        reportData.Chemicals.FeCl3_Feed.symbol = symbol;
        reportData.uf_Storage_tanks.Ferric_chloride.symbol = symbol;
      } else {
        reportData.Chemicals.PACl.symbol = symbol;
        reportData.Chemicals.PACl_Feed.symbol = symbol;
        reportData.uf_Storage_tanks.Poly_aluminium_chloride.symbol = symbol;
      }
    }
    return reportData;
  };

  const getUfCalcReportData = async () => {
    try {
      dispatch(setUfCalcReportApiResp({ isLoading: true }));
      const { requestConfig } = getConfigPayload();
      const { ufReport, updatedChemical } = formatReportPayload(requestConfig);
      const response = await getUfCalcReport({ ufReport, projectID, caseID });
      const data = formatReportResp(response.data, updatedChemical);
      dispatch(setUfCalcReportApiResp({ data, error: null }));
    } catch (err) {
      dispatch(setUfCalcReportApiResp({ data: null, error: err }));
    }
    dispatch(setUfCalcReportApiResp({ isLoading: false }));
  };

  const getUfConfig = () => {
    // If UF Config is already fetched, no need to fetch it again
    if (!isEmptyObject(calcEngineData)) {
      return;
    }
    fetchUFConfig(null, true);
  };

  const getUfReportData = () => {
    getUfConfig();
    getUfCalcReportData();
  };

  const convertToPdf = async ({ autoPrint }) => {
    await new Promise(resolve => setTimeout(resolve, 0)); // added to show downloading loader immediately
    const formattedDateTimeNow = moment().format("YYYY-MM-DD:HH:mm:ss");
    const reportName = `${projectName}_${activeCaseName}_${formattedDateTimeNow}.pdf`;

    const element = document.getElementById("content-to-print");

    // Clone the element to avoid modifying the original element and making the changes visible in the PDF
    const clonedElement = element.cloneNode(true);
    clonedElement.style.padding = "0px";
    const reportPdfHeader = clonedElement.querySelector("#reportPdfHeader");
    reportPdfHeader.style.display = "flex";

    /* The purpose of this manipulation is to replace the input fields with divs when converting to canvas
    The reason behind this is that the input fields texts are having misalignment issue in the canvas */
    clonedElement.querySelectorAll("input").forEach(input => {
      if (input.id === "productFlowRate" || input.id === "feedFlowRate") {
        const div = document.createElement("div");
        div.textContent = input.value;
        div.style.backgroundColor = input.hasAttribute("disabled") ? "rgb(225, 225, 225)" : "white";
        div.classList = input.classList;
        input.replaceWith(div);
      }
    });

    const options = {
      margin: 0.25,
      filename: reportName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 2,
      },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    await html2pdf()
      .from(clonedElement)
      .set(options)
      .toPdf()
      .get("pdf")
      .then(pdf => {
        if (autoPrint) {
          pdf.autoPrint();
          // TODO: move to util
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = pdf.output("bloburl");
          document.body.appendChild(iframe);
          iframe.onload = () => {
            iframe.contentWindow.print();
          };
          const cleanup = () => {
            document.body.removeChild(iframe);
            window.removeEventListener("focus", cleanup);
          };
          window.addEventListener("focus", cleanup);
        } else {
          pdf.save(reportName);
        }
      });
  };

  const createPdf = async ({ autoPrint }) => {
    try {
      await convertToPdf({ autoPrint });
      if (!autoPrint) {
        showSuccessNotification(APP_TOAST_NOTIFICATION.REPORT_DOWNLOADED);
      }
    } catch (error) {
      showErrorNotification(APP_TOAST_NOTIFICATION.REPORT_DOWNLOAD_FAILED);
    } finally {
      hideFullScreenLoader();
    }
  };

  const generatePDF = ({ autoPrint }) => {
    showFullScreenLoader(reportStrings.generatingReport);
    // delay to flow component to re-render to pdf dimensions before generating pdf
    setTimeout(() => {
      createPdf({ autoPrint });
    }, 150);
  };

  return { getUfReportData, generatePDF, isUfReportErr, isUfReportLoading };
};
