/* eslint-disable quotes */
export const reportStrings = {
  ufFlowDetails: "UF Flow Details",
  feed: "Feed",
  product: "Product",
  source: "Source",
  tempDesign: "Temperature : Design",
  turbidity: "Turbidity",
  organicsTOC: "Organics (TOC)",
  organicsKMnO4: "Organics (COD as KMnO₄)",
  tss: "TSS",
  tds: "TDS",
  sdi: "SDI₁₅",
  ph: "pH",
  additionalFeedInfo: "Additional Feed Water Information",
  duration: "Duration",
  flux: "Flux",
  flow: "Flow",
  interval: "Interval",
  filtration: "Filtration",
  instantaneous: "Instantaneous",
  trains: "trains",
  average: "Average",
  net: "Net",
  backwash: "Backwash",
  acidCEB: "Acid CEB",
  alkaliCEB: "Alkali CEB",
  disinfectionCEB: "Disinfection CEB",
  miniCip: "mini-CIP",
  cip: "CIP",
  membraneIntegrationTesting: "Membrane Integration Testing",
  bw: "BW",
  cebDisinf: "CEB (disinf.)",
  cipSls: "CIP SLS",
  miniCipSls: "mini-CIP SLS",
  peakFlow: "Peak Flow",
  averageFlow: "Average Flow",
  FeedGross: "Feed (Gross)",
  FeedUserFor: "Feed water used for",
  pretreatment: "Pretreatment",
  forwardFlushProcessStreams: "Forward Flush & Process streams",
  feedNet: "Feed (Net)",
  filtrateGross: "Filtrate (Gross)",
  filtrateUsedForCleaning: "Filtrate used for cleaning",
  filtrateNet: "Filtrate (Net)",
  air: "Air",
  air2: "Air 2",
  backwashBW: "Backwash (BW)",
  forwardFlush: "Forward Flush (FF)",
  ceb1Acid: "CEB 1 (Acid)",
  ceb2AlkaliOxidant: "CEB 2 (Alkali and/or Oxidant)",
  ceb3Disinfection: "CEB 3 (Disinfection)",
  miniCipRecycle: "mini-CIP Recycle",
  cipRecycle: "CIP Recycle",
  footNotes: "Foot Notes",
  remarks: "Remarks",
  maxPossibleFlowRate: "Maximum possible flow rate",
  ceb: "CEB",
  ufOperatingConditions: "UF Operating Conditions",
  UfWaterQuality: "UF Water Quality",
  standbyOption: "Standby Option",
  standbyTankOption: "Standby Tank Option",
  forwardFlushWaterSource: "Forward Flush Water Source",
  backwashWaterSource: "Backwash Water Source",
  cebWaterSource: "CEB Water Source",
  miniCipWaterSource: "mini-CIP Water Source",
  cipWaterSource: "CIP Water Source",
  ufConfigurationOptions: "UF Configuration Options",
  ufSystemOverview: "UF System Overview",
  ufDesignWarnings: "UF Design Warnings",
  systemDiagram: "System Diagram",
  ultraFiltration: "Ultrafiltration",
  sideNavSelectMsg: "You may select checkboxes below to view the details of a specific parameter on the right side.",
  generatingReport: "Generating Report",
  ufSummaryReport: "Ultrafiltration Summary Report",
  ufPressureRatings: "UF Pressure Ratings",
  process: "Process",
  tmp: "TMP",
  ok: "OK",
  foulingMaxDp: "Fouling Max DP",
  pipingDp: "Piping DP",
  filtratePressure: "Filtrate Pressure",
  feedPressure: "Feed Pressure",
  pressureRating: "Pressure Rating",
  t: "T",
  minTemp: "Minimum Temp.",
  designTemp: "Design Temp.",
  maxTemp: "Maximum Temp.",
  pressureRatingsTableFootNotes1: "At Actual, average flux",
  pressureRatingsTableFootNotes2:
    "Sum of TMP, fouling ∆P, piping ∆P and filtrate pressure. Does not include pressure drop at the strainer. Pressure drops are based on user inputs. Default values should not be used for pump sizing",
  pressureRatingsTableFootNotes3:
    "Comparison of Feed pressure to Pressure rating--a conservative comparison due to piping losses between the feed pump and module inlet.",
  ufPumpHydraulicElectricalCost: "UF Pump Hydraulics and Electrical Cost",
  meteringPump: "Metering Pump",
  pump: "Pump",
  cipSolutionHeating: "CIP Solution Heating",
  airCompressorScour: "Air Compressor (Scour)",
  airCompressorAdbw: "Air Compressor (ADBW)",
  electricalValves: "Electrical Valves",
  plcAndInstrumentation: "PLC and Instrumentation",
  totalElectricalCost: "Total Electrical Cost",
  peakFlowRate: "Peak Flowrate",
  avgPressure: "Average Pressure",
  mechanicalPower: "Mechanical Power",
  electricalPower: "Electrical Power",
  energy: "Energy",
  cost: "Cost",
  ufStorageTanks: "UF Storage Tanks",
  storageTanksTableFootNotesNetFiltrate: "Storage Tank sized to maintain constant net filtrate flow.",
  storageTanksTableFootNotesBwDemand: "Storage Tank sized for BW demand only.",
  storageTanksTableFootNotes2:
    "The mimimum recommended volume for chemical storage tanks is sized for ${count} days of storage.",
  name: "Name",
  bulkConc: "Bulk Conc.",
  minRecommVolume: "Minimum Recommended Volume",
  water: "Water",
  cipTank: "CIP Tank",
  miniCipTank: "mini-CIP Tank",
  chemicalStorage: "Chemical Storage",
  ufSystemSizeModuleDetails: "UF System Size and Module Details",
  moduleDetails: "Module Details",
  units: "Units",
  onlineUnits: "Online Units",
  standbyUnits: "Standby Units",
  redundantUnits: "Redundant Units",
  totalUnits: "Total Units",
  maxOfflineUnits: "Max Offline Units",
  modulePerUnit: "Modules per Unit",
  totalModules: "Total Modules",
  tRackTm: "T-Rack<sup>TM</sup> Design",
  trOption: "TR",
  integraPacIpDesign: "IntegraTec™<sup>TM</sup> IP Design",
  area: "Area",
  length: "Length",
  diameter: "Diameter",
  weightEmpty: "Weight (empty)",
  weightFilled: "Weight (Water Filled)",
  waterVolume: "Water Volume",
  moduleType: "Module Type",
  chemicals: "Chemicals",
  unitCost: "Unit Cost",
  dose: "Dose",
  volume: "Volume",
  numUnits: "# Units",
  modules: "# Modules",
  systemFlowRate: "System Flow Rate",
  trainFlowRate: "Train Flow Rate",
  ufSystemRecovery: "UF System Recovery",
  utilityWater: "Utility Water",
  online: "Online",
  standBy: "Standby",
  redundant: "Redundant",
  perUnit: "Per Unit",
  total: "Total",
  grossFeed: "Gross Feed",
  netProduct: "Net Product",
  ufUtilityChemicalCosts: "UF Utility and Chemical Costs",
  ceb1: "CEB1",
  ceb2: "CEB2",
  ceb3: "CEB3",
  totalChemicalCost: "Total Chemical Cost",
  processFlowDiagram: "Process Flow Diagram",
  electricity: "Electricity",
  peakPower: "Peak Power",
  electricityUnitCost: "Electricity Unit Cost",
  electricityCost: "Electricity Cost",
  specificEnergy: "Specific Energy",
  nonProductFeedWater: "Non-Product Feed Water",
  wasteWaterDisposal: "Waste Water Disposal",
  totalServiceWaterCost: "Total Service Water Cost",
  averageFlowRate: "Average Flow Rate",
  hourlyCost: "Hourly Cost",
  dailyCost: "Daily Cost",
  utilityAndChemicalCost: "Utility and Chemical Cost",
  specificWaterCost: "Specific Water Cost",
  serviceWater: "Service Water",
  disinfection: "Disinfection",
  downloadReport: "Download Report",
  printReport: "Print Report",
  reportDisclaimer: `Information provided is offered in good faith, but without guarantees. Users of such information assume all
   risk and liability and expressly release DuPont de Nemours Inc and its subsidiaries, officers and agents from any and all 
   liability. Because use conditions and applicable laws may differ from one location to another and may change with time, users 
   of information set forth herein or generated during use of WAVEPRO are responsible for determining suitability of the 
   information. Neither The DuPont nor its subsidiaries assume any liability for results obtained or damages incurred from the 
   use of information provided and TO THE FULLEST EXTENT PERMITTED BY LAW, EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESSED OR 
   IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Users will not export or re-export any
   information or technology received from The DuPont or its subsidiaries, or the direct products or designs based upon such 
   information or technology in violation of the export-control or customs laws or regulations of any country, including those of 
   the United States of America. DuPont™, the DuPont Oval Logo, and all products denoted with ® or ™ are trademarks or registered 
   trademarks of DuPont or its affiliates. Copyright © 2024 DuPont.DOWEX™, DOWEX MONOSPHERE ™, DOWEX MARATHON™, DOWEX UPCORE™ are 
   a trademark of The Dow Chemical Company used under license by DuPont.`,
  operatingConditionsTableFootNotes1:
    "Backwash Duration also includes Wait Duration, Pump Ramp and Valve Open/Close Action Duration.",
  //UF Parameters table strings
  pumpAndValveConditions: "Pump and Valve Conditions",
  feedPump: "Feed Pump",
  backwashPump: "Backwash Pump",
  chemDosingPump: "Chem. Dosing pump",
  cebDosingPump: "CEB dosing pump",
  cipRecyclePump: "CIP recycle pump",
  mCipRecyclePump: "mini-CIP recycle pump",
  feedValve: "Feed valve",
  filtrateValve: "Filtrate valve",
  concentrateValve: "Concentrate valve",
  drainBottomValve: "Drain Bottom valve",
  drainTopValve: "Drain Top valve",
  backwashInletValve: "Backwash Inlet valve",
  drainValve: "Drain valve",
  airInletValve: "Air Inlet valve",
  flowRate: "Flow Rate",
  forwardFlushAtStartUp: "Forward Flush at Start-Up",
  operating: "Operating",
  filtrationMode: "Filtration Mode",
  airInlet: "Air Inlet",
  draining: "Draining",
  backwash1: "Backwash 1",
  backwash2: "Backwash 2",
  stop: "Stop",
  backwashWithAirScrub: "Backwash with Air Scrub",
  soak: "Soak",
  fillingAtStartup: "Filling at Startup",
  backwashDrainBottom: "Backwash Drain Bottom",
  backwashDrainTop: "Backwash Drain Top",
  backwashBeforeCEB: "Backwash Before CEB",
  cebInjectionDrainBottom: "CEB Injection Drain Bottom",
  cebInjectionDrainTop: "CEB Injection Drain Top",
  rinse1: "Rinse 1",
  rinse2: "Rinse 2",
  cipRecycle1: "CIP Recycle 1",
  cipRecycle2: "CIP Recycle 2",
  oValueRemark: '"o" = valve or pump is opened or operating.',
  cipManuallyRemark: "CIP is done manually.",
  cebFrequencyRemark:
    "Frequency of CEB is based on feed water source and quality. Refer to DuPont UF Design Guidelines.",
  cebStepRemark: "For a CEB, follow Steps ${steps}.",
  valveOpeningClosingRemark:
    "The valve opening and closing time for each process step should be considered when programming is designed.",
  cipFrequencyRemark: "Frequency of CIP is 1-3 months, adjusted according to operating conditions.",
  cipIngeFrequencyRemark: "Frequency of CIP is 3-12 months, adjusted according to operating conditions.",
  startCipBwRemark: "Start CIP with backwash sequence; complete CIP with backwash sequence.",
  forwardFlushFlowFootNote: "Forward flush flow rate displayed on per-module basis.",
  mayWastePermeateRemark:
    "May need to waste a portion of permeate to remove residual chemicals, depending on design and application.",
  airFlowFootNote: "Air flow rate displayed on per-module basis.",
  filtrationModeBwRemark:
    "The filtration mode follows Steps ${steps}. Backwash can be repeated several times according to the fouling degree of UF membrane modules.",
  ingeBwRemark1: "The filtration mode follows Steps 2-4-5-2. Steps 3 & 6 optional.",
  ingeCebRemark1: "Frequency of CEB is based on feed water source and quality.",
  ingeCebRemark2:
    "For a CEB, follow Steps 1-2-3-4-5-6-7-1. Backwash before CEB skipped for 230 lmh chemical backwash (injection) flux.",
  ingeCipRemark3:
    "A CIP should be performed if the permeability of the system falls below 100 - 150 l/m²h/bar (4 - 6 GFD/psi).",
  bwFootNote1:
    "Use of chemical dosing pump during backwash is based on feed water source and quality. Refer to DuPont UF Design Guidelines.",
  bwFootNote3:
    "Use of air scour and frequency is based on feed water source and quality.  Air flow rate displayed on per-module basis.",
  bwFootNote5:
    "If taken out of operation, add preservative and close all valves. Stop should occur only after backwash.",
  cebFootNote1:
    "Chemicals and concentrations used during CEB are based on feed water source and quality. Refer to DuPont UF Design Guidelines.",
  cipStepDurationFootNote:
    "This step and duration is shown for a single chemical cleaning. If acid and base cleaning are both required, repeat Steps ${steps}.",
  cipFootNote4: "CIP recycle flow rate displayed on a per-module basis.",
  mCipFootNote4: "mini-CIP recycle flow rate displayed on a per-module basis.",
  cipDurationLongerFootNote:
    "The duration of this step might be longer, up to overnight (12 hours), if the fouling is severe.",
  ingeBwFootNote1:
    "Coagulation rate (if applicable) subject to optimization based on feed water pH, composition, and treatment objectives.",
  forwardFlushOptional: "Forward flush is optional. Forward flush flow rate equal filtration flow.",
  ingeBwFootNote3:
    "Stop should occur only after backwash if taken out of operation. Preservation steps mandatory for downtime > 24 h.",
  ingeBwFootNote4: "Filling step only required for commissioning and after maintenance work.",
  ingeBwFootNote5:
    "During 5 sec (valve change from Drain Bottom Valve open to Drain Top Valve open), both valves are open.",
  ingeCebFootNote1: "Chemicals and concentrations used during CEB are based on feed water source and quality.",
  ingeCebFootNote2:
    "Chemical injection (backwash) time during CEB is depending on plant design and matter of optimization during commissioning.",
  ingeCebFootNote3:
    "Caustic CEB (CEB1.1A) default pH 12,0; maximum pH 13,0. Acid CEB (CEB1.2/2) default pH 2,3; minimum pH 1,0.",
  ingeCebFootNote4:
    "Seawater applications: Oxidative CEB (CEB1.1B) with 20 - 50 ppm free chlorine @ pH 9.5 (mandatory, by addition of NaOH).",
  ingeCipFootNote1: "Draining before CIP mandatory for seawater application.",
  ingeCipFootNote3:
    "CIP recycle flow rate calculates as: # of UF modules x surface area / module x 25 l/m²h (12 GFD) = volume flow rate @ minimum 1 bar.",
  ingeCipFootNote4: "CIP recycle rates feed-feed / feed-filtrate ≈ 50% / 50%.",
  mCipHighFrequencyRemark:
    "Due to relatively high frequency of mini-CIP (i.e. typically from 1 to 3 times per week), it is recommended to automate the process in order to reduce labor.",
  mCipAuxillaryRemark:
    "The existing auxiliary system used for the standard CIP is employed to perform more frequent but shorter chemical cleanings or mini-CIP, so there is no need of additional installation or hardware.",
  mCipDurationRemark:
    "The total duration of the mini-CIP is typically 30 minutes and includes a regular Backwash pre-cleaning, a heated chemical solution recirculation step with a soaking period in between (with intermittend Air Scour), and a final Backwash post-cleaning.",
  mCipSubstituteRemark:
    "Note that the mini-CIP substitutes the CEB, not the standard intensive CIP program which still might be needed regularly.",
  repeatStepRemark: "This step should be repeated ${steps} times.",
  normalModeBwParameters: "Normal Mode and Backwash Parameters",
  normalWithUxaBwParameters: "Normal Mode with UXA Backwash Parameters",
  cebUxaParameters: "CEB with UXA Backwash Parameters",
  cipUxaParameters: "CIP with UXA Backwash Parameters",
  mCipUxaParameters: "mini-CIP with UXA Backwash Parameters",
  operation: "Operation",
  returnToOpertaion: "Return to Operation",
  processModeOpertaion: "Process Mode of Operation",
  backwashBw: "Backwash (B/W)",
  byGravity: "By Gravity",
  backwashParameters: "Backwash Parameters",
  cebParameters: "CEB Parameters",
  cipParameters: "CIP Parameters",
  mCipParameters: "mini-CIP Parameters",
  cebFullForm: "Chemically Enhanced Backwash (CEB)",
  reportNotGenerated: "Report not generated",
  reportGenerationErrMsg:
    "There is an error while loading the report content. Please reload the page or try again after some time.",
  none: "None",
};
