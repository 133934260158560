import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequest, postRequest } from "src/api";

import API_URLS from "@constants/api.urls";
import {
  CHEMICAL_ADJUSTMENT_MAPPING,
  CHEMICAL_CONCENTRATION,
  ChemicalTypesMappig,
  LSI_MAX_TDS,
  UFChemcicalFieldsMapping,
} from "@constants/chemicalAdjustment.constant";

import { convertUpto2Digits } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { setUFChemicalAdjustment, updateUFChemicalAdjustmentData } from "@features/feedwater/uf/UFSlice";

import useFeedSetupDataHandler from "./useFeedSetupDataHandler";
import useUFChemicalsHandler from "./useUFChemicalsHandler";

export const useChemicalAdjustment = () => {
  const Logger = DupontLogger("useChemicalAdjustment");
  const dispatch = useDispatch();

  const { feedStreamData } = useFeedSetupDataHandler();
  const { getCoagulantChemicals, getChemicalDoseValue } = useUFChemicalsHandler();
  const { lstTechnologyLists } = useSelector(state => state.processDiagramSlice) || [];
  const { data: ChemicalData } = useSelector(state => state.UFStore.ufChemicalAdjustment) || null;
  const { chemicalListByCategory, chemicalListById } = useSelector(state => state.projectInfo.projectChemicalCosts);

  const isSDI = useMemo(() => feedStreamData.totalDissolvedSolids >= LSI_MAX_TDS, [feedStreamData]);

  const findItemByName = (list, name) => {
    const item = list?.find(item => item.name === name);
    return (item && item.mgL) || "0.00";
  };

  const co3 = useMemo(() => findItemByName(feedStreamData.anions, "CO₃"), [feedStreamData.anions]);
  const ca = useMemo(() => findItemByName(feedStreamData.cations, "Ca"), [feedStreamData.cations]);

  const waterSubType = useMemo(() => feedStreamData.waterSubTypeID, [feedStreamData]);

  const { caseTreatmentID: caseTreatmentId } = useMemo(
    () => lstTechnologyLists.find(({ technologyID, isDeleted }) => technologyID == 1 && !isDeleted) || {},
    [lstTechnologyLists],
  );

  const isPhDownEnabled = useMemo(
    () =>
      feedStreamData &&
      (feedStreamData.estimatedConductivity > 0 ||
        feedStreamData.totalDissolvedSolids > 0 ||
        feedStreamData.chargeBalance > 0),
    [feedStreamData.estimatedConductivity, feedStreamData.totalDissolvedSolids, feedStreamData.chargeBalance],
  );

  const temperatureData = useMemo(
    () => [
      { id: "minimumTemp", label: "Minimum", value: feedStreamData.tempMin },
      { id: "designTemp", label: "Design", value: feedStreamData.tempDesign },
      { id: "maximumTemp", label: "Maximum", value: feedStreamData.tempMax },
      { id: "specify", label: "Specify", value: feedStreamData.tempDesign },
    ],
    [feedStreamData],
  );

  const calculateAndUpdateCoagulant = waterSubtypeId => {
    const coagulant = getCoagulantChemicals(waterSubtypeId);
    dispatch(updateUFChemicalAdjustmentData({ coagulant }));
    saveUFChemicalAdjustmentData({ ...ChemicalData, coagulant });
  };

  const getDefaultPayload = streamData => {
    const {
      anions,
      cations,
      neutrals,
      chargeBalance,
      estimatedConductivity,
      ph,
      pH,
      ph25,
      totalDissolvedSolids,
      totalDissolvedSolutes,
      tempDesign,
    } = streamData || feedStreamData;
    return {
      methodName: "normal",
      anions,
      cations,
      neutrals,
      chargeBalance,
      estimatedConductivity,
      ph: ph || pH,
      ph25,
      totalDissolvedSolids,
      totalDissolvedSolutes,
      chemicalAdjustment: [
        {
          CaSO4: 0,
          BaSO4: 0,
          SrSO4: 0,
          CaF2: 0,
          SiO2: 0,
          MgOH2: 0,
          LSI: 0,
          SDI: 0,
          pH: ph || pH,
          ionicStrength: 0,
        },
      ],
      adjustmentType: 0,
      degas: 0,
      initialCO2: 0,
      totalCO2: 0,
      equilibrateWith: 0,
      addReagent: 0,
      LSITarg: 0,
      SDITarg: 0,
      designTemp: tempDesign,
    };
  };

  const calculateChemicalAdjustment = async (data = {}, streamData = null) => {
    try {
      const payload = { ...getDefaultPayload(streamData), ...data };
      return await postRequest(API_URLS.calculateChemicalAdjustment, payload);
    } catch (error) {
      Logger.error("Error while calculating checmicalAdjustment Data: ", error);
    }
  };

  const getUFChemicalAdjustmentData = async treatmentID => {
    try {
      if (!ChemicalData) {
        const queryParams = { caseTreatmentId: treatmentID || caseTreatmentId };
        const { data } = await getRequest(API_URLS.chemicalAdjustment, queryParams);
        const { phDown, oxidant } = data;
        const coagulant = data.coagulant || getCoagulantChemicals(waterSubType);
        dispatch(setUFChemicalAdjustment({ data, chemicalAdjustment: null, isUFAdjustmentFetched: true }));
        return { phDown, oxidant, coagulant };
      } else {
        return { ...ChemicalData };
      }
    } catch (error) {
      Logger.error("Error while getting checmicalAdjustment Data: ", error);
    }
  };

  const saveUFChemicalAdjustmentData = async data => {
    try {
      const payload = { caseTreatmentId, ...data };
      await postRequest(API_URLS.chemicalAdjustment, payload);
    } catch (error) {
      Logger.error("Error while saving checmicalAdjustment Data: ", error);
    }
  };

  const isLsiSdiEnabled = () => ca > 0 && co3 > 0;

  const formatChemicalsResponse = chemicalData =>
    Object.keys(CHEMICAL_ADJUSTMENT_MAPPING).reduce((acc, key) => {
      acc[CHEMICAL_ADJUSTMENT_MAPPING[key]] = Math.round(chemicalData[key]);
      return acc;
    }, {});

  const formatChemicalDataForUF = chemicalData =>
    Object.keys(UFChemcicalFieldsMapping).reduce((acc, key) => {
      acc[UFChemcicalFieldsMapping[key]] = chemicalData[key];
      if (key === "chemicalAdjustment") {
        acc[UFChemcicalFieldsMapping[key]] = [formatChemicalsResponse(chemicalData[key][0])];
      }
      return acc;
    }, {});

  const fetchUFChemicalAdjustmentFieldsData = async treatmentId => {
    const data = await getUFChemicalAdjustmentData(treatmentId);
    let result = { data };
    let phConcentration = 0;
    let chemicalAdjustment = null;
    const { data: feed_water } = await calculateChemicalAdjustment(); // Before Adjustment Data
    const feedData = { feed_water, adjusted_water: null };
    result = { ...result, feedData };
    if (data.phDown) {
      const { phDown } = data;
      const phChemical = chemicalListById?.[phDown.chemicalId];

      const payload = {
        ph: phDown.ph,
        addReagent: ChemicalTypesMappig[phChemical.name],
        LSITarg: (!isSDI && phDown.lsiSdi) || "0",
        SDITarg: (isSDI && phDown.lsiSdi) || "0",
        designTemp: +temperatureData[1].value,
      };

      const { data: adjusted_water } = await calculateChemicalAdjustment(payload, feed_water); // After Adjustment
      if (adjusted_water) {
        const { addReagent, ...respData } = adjusted_water;
        phConcentration = CHEMICAL_CONCENTRATION[phChemical.symbol] * addReagent * 1000;
        phConcentration = convertUpto2Digits(phConcentration);
        chemicalAdjustment = formatChemicalDataForUF(respData);
        result.feedData.adjusted_water = adjusted_water;
      }
    }
    dispatch(setUFChemicalAdjustment({ chemicalAdjustment, phConcentration, ...result }));
    return result;
  };

  return {
    isSDI,
    feedStreamData,
    isPhDownEnabled,
    temperatureData,
    chemicalListById,
    chemicalListByCategory,
    isLsiSdiEnabled,
    getChemicalDoseValue,
    formatChemicalDataForUF,
    getUFChemicalAdjustmentData,
    calculateAndUpdateCoagulant,
    calculateChemicalAdjustment,
    saveUFChemicalAdjustmentData,
    fetchUFChemicalAdjustmentFieldsData,
  };
};
