import { CHEMICAL_SYMBOLS } from "@constants/chemical-symbol.constants";

const CHEMICALS_LIBRARY_STRINGS = {
  chemicalLibDlgHeading: "Chemical Library",
  projectCostDlgHeading: "Project Costs",
  newChemicalDlgHeading: "New Chemical",
  editChemicalDlgHeading: "Edit Chemical",
  addChemicalBtnText: "Add Chemical",
  refreshList: "Refresh List",
  chemicalLibDlgSubHeading: "Handle the chemicals for all your projects here",
  projectCostDlgSubHeading: "Edit prices of chemicals and operations",
  reviewBeforeAction: "Review before {%} chemical",
  questionIconTooltip: "Help",
  saveChanges: "Save Changes",
  displayName: "Display Label",
  name: "Chemical",
  symbol: "Symbol",
  category: "Category",
  bulkConcentration: "Concentration",
  bulkDensity: "Density",
  bulkPrice: "Price",
  costType: "Type",
  showDropDown: "View in all projects",
  actions: "Actions",
  open: "Open",
  edit: "Edit",
  delete: "Delete",
  cancelBtn: "Cancel",
  deleting: "deleting",
  updating: "updating",
  backBtnTitle: "Back",
  loadingData: "Loading data",
  refreshingList: "Refreshing list",
  savingChemical: "Saving chemical",
  updatingChemical: "Updating chemical",
  deletingChemical: "Deleting chemical",
  deletingProjects: "Deleting projects",
  updatingOperatingCosts: "Updating operating costs",
  checkingChemicalUsage: "Checking chemical usage in projects",
  helpDialogTitle: "Chemical Library & Costs Help",
  addEditChemicals: "Add/Edit Chemicals",
  makePriceNewDefault: "Make these prices as new default",
  makeCostNewDefault: "Make utility costs as new default",
  utilityCostHeading: "Utility Costs",
  makeUtilityCostBtnFlag: "makeUtilityCostBtn",
  setAsDefaultBtn: "Set as default",
  defaultUtilityCostUpdated: "Updating default utility costs",
  defaultChemicalPricesUpdated: "Updating default chemical prices",
  chemicalPriceHeaderUpdated: "Do you want to update default chemical prices?",
  chemicalPriceDescUpdated: "Currents prices of chemicals from this project will be set as your default chemical prices",
  utilityCostHeaderUpdated: "Do you want to update default utility costs?",
  utilityCostDescUpdated: "Currents prices of utilities from this project will be set as your default utility prices",
  saveChemical: "Save Chemical",
  displaySettings: "Visibility & Label",
  showInAllProjectsTitle: "How do you want to see this chemical in projects?",
  showInAllProjectsDescription:
    "Turning off visibility will hide it in all projects, for old projects where it’s already used you need to choose another",
  dropdownLabelTitle: "Label in Projects",
  dropdownLabelDescription: "This is how you’ll see the chemical in the dropdown list. Keep it short always.",
  deleteProjectWarningMsg:
    "Please make sure to either replace the chemical with other or remove the project if it’s not required.",
  chemicalAlreadyUsed: "{%} already being used in other projects",
  rawWater: "Raw Water",
  wasteWaterDisposal: "Waste Water Disposal",
  electricity: "Electricity",
  deletChemicalBtn: "Delete Chemical",
  deleteChemicalDlgTitle: "Delete chemical?",
  deleteChemicalDlgDescription: "Are you sure you want to delete to chemical?",
  deleteProjDlgTitle: "Delete {%}?",
  currentProj: "(Current)",
  deleteProjDlgDescription:
    "Deleting this project or projects will permanently remove them, and this action cannot be undone. If you're sure, proceed with deletion",
  projectCostNegError: "Only positive numbers are allowed in this field.",
  projectCostZeroError: "Enter a price greater than zero to proceed.",
  projectCostRequiredError: "This field cannot be empty; please enter a valid price."
};

export const CHEMICAL_USAGE_STRINGS = {
  projectName: "Project Name",
  customer: "Customer",
  lastModified: "Last Modified",
  createdDate: "Created Modified",
  actions: "Actions",
};

export const CHEMICAL_LIBRARY_HELP_DATA = [
  {
    section: "New Chemicals",
    description: `Any new chemicals have to be created in the chemical library menu. Once added these new 
    chemicals can be seen in your project costs list. You can choose from the chemicals list that 
    WavePro has or you can create custom ones on your own.`,
  },
  {
    section: "Changing Operation Costs",
    description: `The costs of these chemicals in WavePro are based on the values that can be generic to the 
    water business. We advise you to change these costs inside the project rather changing in the 
    chemical library. Changing the prices in chemical library doesn’t affect/change the prices in 
    your project which is already using these chemicals.`,
  },
  {
    section: "Editing Chemical Concentration/Density",
    description: `We generally advise you not to change concentration very frequently. You can create any 
    number of concentrations for a given chemical. You can also have different prices for a chemical 
    in different projects since costs are specific to individual project.`,
  },
  {
    section: "Deleting a chemical",
    description: `Any chemical that is already present in multiple projects can’t be deleted, you are advised 
    to review the projects where it’s used and change it to something else before deleting an chemical 
    that is already used in existing projects.`,
  },
];

// declaring chemical list here
// this list is currently used in reports and can be used at other places as well
export const CHEMICALS_LIST = {
  hydrochloricAcid: {
    name: "Hydrochloric Acid",
    symbol: "HCl",
  },
  sulfuricAcid: {
    name: "Sulfuric Acid",
    symbol: "H₂SO₄",
  },
  sodiumHydroxide: {
    name: "Sodium Hydroxide",
    symbol: "NaOH",
  },
  sodiumChloride: {
    name: "Sodium Chloride",
    symbol: "NaCl",
  },
  sodiumMetabisulfite: {
    name: "Sodium Metabisulfite",
    symbol: "Na₂S₂O₅",
  },
  sodiumHexametaphosphate: {
    name: "Sodium Hexametaphosphate",
    symbol: "Na₆P₆O₁₈",
  },
  sodiumCarbonate: {
    name: "Sodium Carbonate",
    symbol: "Na₂CO₃",
  },
  sodiumHypochlorite: {
    name: "Sodium Hypochlorite",
    symbol: "NaOCl",
  },
  citricAcid: {
    name: "Citric Acid",
    symbol: "C₆H₈O₇",
  },
  oxalicAcid: {
    name: "Oxalic Acid",
    symbol: "C₂O₄H₂",
  },
  sodiumLaurylSulfate: {
    name: "Sodium Lauryl Sulfate",
    symbol: "SLS",
  },
  polyaluminiumChloride: {
    name: "Polyaluminium Chloride",
    symbol: CHEMICAL_SYMBOLS.polyaluminiumChloride,
  },
  ferricChloride: {
    name: "Ferric Chloride",
    symbol: "FeCl₃",
  },
  ferricIon: {
    name: "Ferric Ion",
    symbol: CHEMICAL_SYMBOLS.ferricIon,
  },
  polyaluminiumIon: {
    name: "Aluminium Ion",
    symbol: CHEMICAL_SYMBOLS.aluminiumIon,
  },
};

export default CHEMICALS_LIBRARY_STRINGS;
