import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TabList, TabPanel, Tabs } from "react-tabs";

import useFeedSetupDataHandler from "@hooks/useFeedSetupDataHandler";
import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFConfig from "@hooks/useUFConfig";
import useUnitConversion from "@hooks/useUnitConversion";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import TabletCloseMenuIcon from "@common/icons/TabletCloseMenuIcon";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";
import DynamicLoadder from "@common/utils/DynamicLoadder";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import { updateGlobalUnits, updateUnitFlag } from "@common/utils/GlobalUnitConversionSlice";

import { useCreateDataMutation } from "@services/apiConfig";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";
import {
  handleCalcEngineResponse,
  setActiveTab,
  setUfDataUpdate,
  setUFProductFilter,
  updateMenuIconHeader,
  updateTabletMenuIcon,
  updateUFStoreData,
} from "../uf/UFSlice";

import { getModules, handleSortingOperations, searchFilterLogic } from "./UFConfiguration/UFHelper";
import AdditionalSetting from "./AdditionalSetting";
import Backwash from "./Backwash";
import CEB from "./CEB";
import CIP from "./CIP";
import Design from "./Design";
import UFConfiguration from "./UFConfiguration";
import UFMiniCip from "./UFMiniCip";
import UFStyled from "./UFStyled";

const UF = () => {
  const dispatch = useDispatch();
  const { fetchUFConfig } = useUFConfig();
  const { convertUnit } = useUnitConversion();
  const { isValueInPh } = useUFChemicalsHandler();
  const { fetchWaterTypes } = useFeedSetupDataHandler();

  const [getCebLSIValue, responseCebLSIValue] = useCreateDataMutation();
  const [getCipLSIValue, responseCipLSIValue] = useCreateDataMutation();
  const [getMiniCipLSIValue, responseMiniCipLSIValue] = useCreateDataMutation();
  const [POSTUFAutoSaveData] = useCreateDataMutation();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfCalculated, setIsConfCalculated] = useState(false);

  const {
    activeTab,
    ufModules,
    data: UFData,
    calcEngineData,
    activeUFModule,
    ufProductFilter,
    isForDrinkingWater,
    isUFDetailsFetched,
    calcEngineDataRefreshCount,
  } = useSelector(state => state.UFStore);

  const { activeSortOrder, activeSearch, selectedModuleCategory } = ufProductFilter;
  const StreamStoreData = useSelector(
    state => state.Feedsetupdetailsdatapanel.streamData.lstrequestsavefeedwater[0]?.streams[0],
  );
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { unitTypeFlux } = useSelector(state => state.GUnitConversion);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);

  const { data: ProjectInfoStore, projectChemicalCosts } = useSelector(state => state.projectInfo);
  const UserInfoStore = useSelector(state => state.userInfo.data);

  const projectChemicals = useMemo(() => projectChemicalCosts?.projectChemicals || [], [projectChemicalCosts]);

  const tempDesign = useSelector(
    state => state.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]?.tempDesign,
  );

  const { waterSubTypeID = "1", waterTypeID } = StreamStoreData || {};
  const projectID = ProjectInfoStore.projectID;
  const caseID = ProjectInfoStore.caseId;
  const loggedInUserID = UserInfoStore.UserId;
  const updateStoreData = data => dispatch(updateUFStoreData(data));
  const StoreDataFeed = useSelector(state => state.Feedsetupdetailsdatapanel.streamData);

  useEffect(() => {
    const filteredModules = getModules(selectedModuleCategory, ufModules);
    const filterdSearchedData = searchFilterLogic(activeSearch, filteredModules);
    let sortedModules = handleSortingOperations(activeSortOrder, filterdSearchedData);
    const isModulePresent = sortedModules.some(module => module.ufmoduleId == UFData.uFModuleID);

    if (!isModulePresent) {
      const moduleToAdd = ufModules.find(module => module.ufmoduleId == UFData.uFModuleID);
      if (moduleToAdd) {
        sortedModules = [...sortedModules, moduleToAdd];
      }
    }
    dispatch(setUFProductFilter({ filteredModules: sortedModules, localFilterdModules: sortedModules }));
  }, [UFData.uFModuleID]);

  useEffect(() => {
    const flux_Filter_actual = convertUnit(calcEngineData.flux_Filter_actual, 4, unitTypeFlux);

    const newUnits = {
      unitTypeFlux: unit.selectedUnits[4],
      unitTypeFlow: unit.selectedUnits[1],
      unitTypeGasFlow: unit.selectedUnits[18],
      unitTypePressure: unit.selectedUnits[3],
      unitTypePower: unit.selectedUnits[9],
      unitTypeTemp: unit.selectedUnits[2],
    };
    dispatch(handleCalcEngineResponse({ ...calcEngineData, ["flux_Filter_actual"]: flux_Filter_actual }));
    dispatch(updateGlobalUnits(newUnits));
    dispatch(updateUnitFlag(false));
  }, [unit.selectedUnits]);

  useEffect(() => {
    if (calcEngineDataRefreshCount > -1 && isUFDetailsFetched) {
      fetchUFConfig();
    }
  }, [calcEngineDataRefreshCount, isUFDetailsFetched]);

  useEffect(() => {
    if (activeUFModule?.tRack && !isConfCalculated && calcEngineData?.n_modules_target) {
      setTimeout(() => {
        dispatch(
          updateUFStoreData({
            calcEngineDataRefreshCount: 1,
            ingeTROption: null,
            isCustomConfigAvail: true,
          }),
        );
      }, 250);// timeout needs to make sure redux has been updated
      setIsConfCalculated(true);
    }
  }, [calcEngineData?.n_modules_target]);

  useEffect(() => {
    fetchWaterTypes(waterTypeID, waterSubTypeID);
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsError(false);
    }
  };

  const updateUFSubMenus = name => {
    if (UFData.uFModuleID == "" || UFData.uFModuleID == "0") {
      setIsError(true);
      setErrorMessage("No Modules Selected");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
    dispatch(setActiveTab(name));
    AutoSaveUFData();
  };

  const AutoSaveUFData = async () => {
    const data = {
      userID: UFData.userID,
      projectID: UFData.projectID,
      caseID: UFData.caseID,
      treatmentName: "UF",
      treatmentObjID: UFData.treatmentObjID,
      fromTreatmentObjID: UFData.fromTreatmentObjID,
      treatmentSeqNo: UFData.treatmentSeqNo,
      recoveryTypeID: parseInt(UFData.recoveryTypeID),
      recovery: Number(UFData.recovery),
      feed: Number(UFData.feed),
      automatic: UFData.automatic,
      recoveryRo: Number(UFData.recoveryRo),
      compactionTemperature: Number(UFData.compactionTemperature),
      isCompactionFlux: UFData.isCompactionFlux,
      uFDesignFluxID: parseInt(UFData.uFDesignFluxID),
      caseTreatmentID: parseInt(UFData.caseTreatmentID),
      filtrateFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.filtrateFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      backwashFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.backwashFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      cEBFlux: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.cEBFlux, "LMH", unit.selectedUnits[4]).toFixed(2),
      ),
      forwardFlushFlow: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.forwardFlushFlow, "m³/h", unit.selectedUnits[1]).toFixed(
          2,
        ),
      ),
      airFlow: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.airFlow, "Nm³/h", unit.selectedUnits[18]).toFixed(2),
      ),
      aerationAirFlow: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.aerationAirFlow,
          "Nm³/h",
          unit.selectedUnits[18],
        ).toFixed(2),
      ),
      recycleFlowRate: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.recycleFlowRate, "m³/h", unit.selectedUnits[1]).toFixed(
          2,
        ),
      ),
      recycleFlowRate_MiniCIP: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.recycleFlowRate_MiniCIP,
          "m³/h",
          unit.selectedUnits[1],
        ).toFixed(2),
      ),
      uFModuleID: parseInt(UFData.uFModuleID),
      flow_FF1: Number(UFData.flow_FF1),
      flow_FF2: Number(UFData.flow_FF2),
      flow_FF3: Number(UFData.flow_FF3),
      flow_FF4: Number(UFData.flow_FF4),
      aDBWDisplacement: Number(UFData.aDBWDisplacement),
      fTLDisplacement: Number(UFData.fTLDisplacement),
      typicalWaitDuration_Dupont: Number(UFData.typicalWaitDuration_Dupont),
      typicalPumpRamp_Dupont: Number(UFData.typicalPumpRamp_Dupont),
      typicalWaitDuration_Inge: Number(UFData.typicalWaitDuration_Inge),
      typicalPumpRamp_Inge: Number(UFData.typicalPumpRamp_Inge),
      typicalWaitDuration_Memcor: Number(UFData.typicalWaitDuration_Memcor),
      typicalPumpRamp_Memcor: Number(UFData.typicalPumpRamp_Memcor),
      uFDesignCycleIntervalsID: parseInt(UFData.uFDesignCycleIntervalsID),
      backwash_design: Number(UFData.backwash_design),
      airScour_design: Number(UFData.airScour_design),
      acidCEB: Number(UFData.acidCEB),
      alkaliOxidantCEB: Number(UFData.alkaliOxidantCEB),
      cIP: Number(UFData.cIP),
      miniCIP: Number(UFData.miniCIP),
      disinfectionCEB: Number(UFData.disinfectionCEB),
      t_CEB_Rinse12: Number(UFData.t_CEB_Rinse12),
      t_CEB_Rinse2: Number(UFData.t_CEB_Rinse2),
      t_BW1_CEBrinse: Number(UFData.bwRinseDrainTop),
      t_BW2_CEBrinse: Number(UFData.bwRinseDrainBottom),
      bwRinseDrainTop: Number(UFData.bwRinseDrainTop),
      bwRinseDrainBottom: Number(UFData.bwRinseDrainBottom),
      uFBWCEBStandbyOptionID: parseInt(UFData.uFBWCEBStandbyOptionID),
      bWPerCEBstandbyTrains: parseInt(UFData.bWPerCEBstandbyTrains),
      uFConfigurationID: parseInt(UFData.uFConfigurationID),
      uFCIPStandbyTrainOptionID: parseInt(UFData.uFCIPStandbyTrainOptionID),
      cIPstandbyTrains: parseInt(UFData.cIPstandbyTrains),
      integraPackDesign_Ind: UFData.integraPackDesign_Ind,
      drinkingWaterInd: UFData.drinkingWaterInd,
      membraneintergrityoption_Ind: UFData.membraneintergrityoption_Ind,
      modulesPerSkid: parseInt(UFData.modulesPerSkid),
      modulesPerTrain: parseInt(UFData.modulesPerTrain),
      offlinetimepertrain: parseInt(UFData.offlinetimepertrain),
      onlineTrains: parseInt(UFData.onlineTrains),
      redundantStandbyTrains: parseInt(UFData.redundantStandbyTrains),
      skids: parseInt(UFData.skids),
      skidsPerTrain: parseInt(UFData.skidsPerTrain),
      uFStorageTankOptionID: parseInt(UFData.uFStorageTankOptionID),
      totalModules: parseInt(UFData.totalModules),
      totalTrains: parseInt(UFData.totalTrains),
      redundantTrains: parseInt(UFData.redundantTrains),
      isBWCEBStandbyTrainsEnabled: UFData.isBWCEBStandbyTrainsEnabled,
      radTR1: UFData.radTR1,
      radTR2: UFData.radTR2,
      radTR3: UFData.radTR3,
      radMR1: UFData.radMR1,
      radMR2: UFData.radMR2,
      radMR3: UFData.radMR3,
      uFFiltrationID: Number(UFData.uFFiltrationID),
      backwash_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.backwash_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      acidCEB_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.acidCEB_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      alkaliCEB_Filtration: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.alkaliCEB_Filtration,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      cIP_Filtration: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.cIP_Filtration, "bar", unit.selectedUnits[3]).toFixed(2),
      ),
      miniCIP_Filtration: Number(UFData.miniCIP_Filtration),
      strainerRecovery: Number(UFData.strainerRecovery),
      strainerSize: Number(UFData.strainerSize),
      tocRejection: Number(UFData.tocRejection),
      uFTanksID: Number(UFData.uFTanksID),
      chemicalStorageTime: Number(UFData.chemicalStorageTime),
      bWTankRefillRate: Number(UFData.bWTankRefillRate),
      filterateTank: Number(UFData.filterateTank),
      bWTank: Number(UFData.bWTank),
      cIPTank: Number(UFData.cIPTank),
      uFEquipmentPressureID: Number(UFData.uFEquipmentPressureID),
      maxAirScourPressure: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.maxAirScourPressure,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      maxAirProcPressure: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.maxAirProcPressure,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      filteratePressure: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.filteratePressure, "bar", unit.selectedUnits[3]).toFixed(
          2,
        ),
      ),
      nonIntegraPacTrainPresDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.nonIntegraPacTrainPresDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      integraPacFiltrationPreDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.integraPacFiltrationPreDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      backwashPipingPreDrop: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.backwashPipingPreDrop,
          "bar",
          unit.selectedUnits[3],
        ).toFixed(2),
      ),
      cIPPipingPreDrop: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.cIPPipingPreDrop, "bar", unit.selectedUnits[3]).toFixed(
          2,
        ),
      ),
      uFPowerID: Number(UFData.uFPowerID),
      pLCPowerReqPertrain: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.pLCPowerReqPertrain,
          "kW",
          unit.selectedUnits[9],
        ).toFixed(2),
      ),
      volvePowerReqPerTrain: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          UFData.volvePowerReqPerTrain,
          "kW",
          unit.selectedUnits[9],
        ).toFixed(2),
      ),
      uFValvesID: Number(UFData.uFValvesID),
      valvesPerTrain: Number(UFData.valvesPerTrain),
      valveOpenCloseDuration: Number(UFData.valveOpenCloseDuration),
      uFCEBID: Number(UFData.uFCEBID),
      uFCEBWaterTypeID: parseInt(UFData.uFCEBWaterTypeID),
      ceb_AirScour: Number(UFData.ceb_AirScour),
      backWash1_CEB: Number(UFData.backWash1_CEB),
      backWash2_CEB: Number(UFData.backWash2_CEB),
      cEBTemperature: parseInt(UFData.cEBTemperature),
      chemicalSoakingDuration_CEB: parseInt(UFData.chemicalSoakingDuration_CEB),
      drain: Number(UFData.drain),
      forwardFlush: Number(UFData.forwardFlush),
      designTemperature_Ind: UFData.designTemperature_Ind,
      ceb_LSI: Number(UFData.ceb_LSI),
      sameAsBackwash_Ind: UFData.sameAsBackwash_Ind,
      alkaliEnabled_Ind_CEB: UFData.alkaliEnabled_Ind_CEB,
      organicEnabled_Ind_CEB: UFData.organicEnabled_Ind_CEB,
      oxidantEnabled_Ind_CEB: UFData.oxidantEnabled_Ind_CEB,
      mineralEnabled_Ind_CEB: UFData.mineralEnabled_Ind_CEB,
      disOxidantEnabled_Ind_CEB: UFData.disOxidantEnabled_Ind_CEB,
      mineralValue_CEB: Number(UFData.mineralValue_CEB),
      organicValue_CEB: Number(UFData.organicValue_CEB),
      oxidantValue_CEB: Number(UFData.oxidantValue_CEB),
      alkaliValue_CEB: Number(UFData.alkaliValue_CEB),
      disOxidantValue_CEB: Number(UFData.disOxidantValue_CEB),
      alkaliChemId_CEB: UFData.alkaliChemId_CEB.toString(),
      mineralChemId_CEB: UFData.mineralChemId_CEB.toString(),
      organicChemId_CEB: UFData.organicChemId_CEB.toString(),
      oxidantChemId_CEB: UFData.oxidantChemId_CEB.toString(),
      disOxidantChemId_CEB: UFData.disOxidantChemId_CEB.toString(),
      alkaliValueInPh_Ind_CEB: UFData.alkaliValueInPh_Ind_CEB,
      mineralValueInPh_Ind_CEB: UFData.mineralValueInPh_Ind_CEB,
      uFCIPID: Number(UFData.uFCIPID),
      bWStepInCIP: parseInt(UFData.bWStepInCIP),
      chemicalSoakingDuration_CIP: Number(UFData.chemicalSoakingDuration_CIP),
      uFCIPWaterTypeID: parseInt(UFData.uFCIPWaterTypeID),
      heatingStepDuration: Number(UFData.heatingStepDuration),
      cip_LSI: Number(UFData.cip_LSI),
      recycleDuration: Number(UFData.recycleDuration),
      recycleTemperature: Number(
        GlobalUnitConversion(GlobalUnitConversionStore, UFData.recycleTemperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      ),
      rinseBWCycle: parseInt(UFData.rinseBWCycle),
      cIPRinseSoakCycle: Number(UFData.cIPRinseSoakCycle),
      alkaliEnabled_Ind_CIP: UFData.alkaliEnabled_Ind_CIP,
      organicEnabled_Ind_CIP: UFData.organicEnabled_Ind_CIP,
      oxidantEnabled_Ind_CIP: UFData.oxidantEnabled_Ind_CIP,
      mineralEnabled_Ind_CIP: UFData.mineralEnabled_Ind_CIP,
      oxidant2Enabled_Ind_CIP: UFData.oxidant2Enabled_Ind_CIP,
      mineralValue_CIP: Number(UFData.mineralValue_CIP),
      organicValue_CIP: Number(UFData.organicValue_CIP),
      oxidantValue_CIP: Number(UFData.oxidantValue_CIP),
      alkaliValue_CIP: Number(UFData.alkaliValue_CIP),
      oxidant2Value_CIP: Number(UFData.oxidant2Value_CIP),
      alkaliChemId_CIP: UFData.alkaliChemId_CIP.toString(),
      mineralChemId_CIP: UFData.mineralChemId_CIP.toString(),
      organicChemId_CIP: UFData.organicChemId_CIP.toString(),
      oxidantChemId_CIP: UFData.oxidantChemId_CIP.toString(),
      oxidant2ChemId_CIP: UFData.oxidant2ChemId_CIP?.toString(),
      alkaliValueInPh_Ind_CIP: UFData.alkaliValueInPh_Ind_CIP,
      mineralValueInPh_Ind_CIP: UFData.mineralValueInPh_Ind_CIP,
      uFBackWashID: parseInt(UFData.uFBackWashID),
      bWTemperature: Number(UFData.bWTemperature),
      oxidantEnabled_Ind_BW: UFData.oxidantEnabled_Ind_BW,
      uFBWWaterTypeID: parseInt(UFData.uFBWWaterTypeID),
      uFBWFlushWaterTypeID: parseInt(UFData.uFBWFlushWaterTypeID),
      uFBWProtocolID: parseInt(UFData.uFBWProtocolID),
      oxidantChemId_BW: UFData.oxidantChemId_BW.toString(),
      oxidantValue_BW: Number(UFData.oxidantValue_BW),
      backwash_AirScour: Number(UFData.backwash_AirScour),
      backWash1_backWash: Number(UFData.backWash1_backWash),
      backWash2_backWash: Number(UFData.backWash2_backWash),
      drain_backWash: Number(UFData.drain_backWash),
      forwardFlush_backWash: Number(UFData.forwardFlush_backWash),
      lF: Number(UFData.lF),
      t_FTL: Number(UFData.t_FTL),
      t_BWBtnAirScour: parseInt(UFData.t_BWBtnAirScour),
      uFMiniCIPID: Number(UFData.uFMiniCIPID),
      bWStepInMiniCIP: Number(UFData.bWStepInMiniCIP),
      rinseBWCycle_MiniCIP: parseInt(UFData.rinseBWCycle_MiniCIP),
      chemicalSoakingDuration_MiniCIP: Number(UFData.chemicalSoakingDuration_MiniCIP),
      uFMiniCIPWaterTypeID: parseInt(UFData.uFMiniCIPWaterTypeID),
      heatingStepDuration_MiniCIP: Number(UFData.heatingStepDuration_MiniCIP),
      lSI_MiniCIP: Number(UFData.lSI_MiniCIP),
      recycleDuration_MiniCIP: Number(UFData.recycleDuration_MiniCIP),
      recycleTemperature_MiniCIP: Number(UFData.recycleTemperature_MiniCIP),
      cIPRinseSoakCycle_MiniCIP: Number(UFData.cIPRinseSoakCycle_MiniCIP),
      alkaliEnabled_Ind_MiniCIP: UFData.alkaliEnabled_Ind_MiniCIP,
      organicEnabled_Ind_MiniCIP: UFData.organicEnabled_Ind_MiniCIP,
      oxidantEnabled_Ind_MiniCIP: UFData.oxidantEnabled_Ind_MiniCIP,
      mineralEnabled_Ind_MiniCIP: UFData.mineralEnabled_Ind_MiniCIP,
      oxidant2Enabled_Ind_MiniCIP: UFData.oxidant2Enabled_Ind_MiniCIP,
      mineralValue_MiniCIP: Number(UFData.mineralValue_MiniCIP),
      organicValue_MiniCIP: Number(UFData.organicValue_MiniCIP),
      oxidantValue_MiniCIP: Number(UFData.oxidantValue_MiniCIP),
      alkaliValue_MiniCIP: Number(UFData.alkaliValue_MiniCIP),
      oxidant2Value_MiniCIP: Number(UFData.oxidant2Value_MiniCIP),
      alkaliChemId_MiniCIP: UFData.alkaliChemId_MiniCIP.toString(),
      mineralChemId_MiniCIP: UFData.mineralChemId_MiniCIP.toString(),
      organicChemId_MiniCIP: UFData.organicChemId_MiniCIP.toString(),
      oxidantChemId_MiniCIP: UFData.oxidantChemId_MiniCIP.toString(),
      oxidant2ChemId_MiniCIP: UFData.oxidant2ChemId_MiniCIP.toString(),
      alkaliValueInPh_Ind_MiniCIP: UFData.alkaliValueInPh_Ind_MiniCIP,
      mineralValueInPh_Ind_MiniCIP: UFData.mineralValueInPh_Ind_MiniCIP,
      ufSpecialFeatureID: parseInt(UFData.ufSpecialFeatureID),
      pUFTechnologyID: parseInt(UFData.pUFTechnologyID),
    };
    const MethodName = { Method: "uf/api/v1/AutoSaveUFData" };
    const UFRequestDetails = {
      ...MethodName,
      ...data,
      ["drinkingWater_Ind"]: isForDrinkingWater,
      ["userID"]: loggedInUserID,
      ["projectID"]: projectID,
      ["caseID"]: caseID,
      WaterSubTypeID: StoreDataFeed.lstrequestsavefeedwater[0].streams[0].waterSubTypeID,
    };
    await POSTUFAutoSaveData(UFRequestDetails);
    dispatch(setUfDataUpdate(false));
  };

  const createJsonForTheLSI = ph => ({
    designTemp: tempDesign,
    methodName: "normal",
    ph: ph,
    ph25: 0,
    chargeBalance: 0,
    totalDissolvedSolutes: 0,
    totalDissolvedSolids: 0,
    estimatedConductivity: 0,
    degas: 0,
    initialCO2: 0,
    equilibrateWith: 0,
    totalCO2: 0,
    LSITarg: "1",
    SDITarg: 0,
    adjustmentType: 0,
    addReagent: 0,
    cations: StreamStoreData.cations,
    anions: StreamStoreData.anions,
    neutrals: StreamStoreData.neutrals,
    chemicalAdjustment: [
      {
        CaSO4: 0,
        BaSO4: 0,
        SrSO4: 0,
        CaF2: 0,
        SiO2: 0,
        MgOH2: 0,
        LSI: 0,
        SDI: 0,
        ionicStrength: 0,
        pH: StreamStoreData?.pH || 0,
      },
    ],
  });

  useEffect(() => {
    const chemical = projectChemicals.find(chem => chem.id == UFData.alkaliChemId_CEB);

    if (isValueInPh && chemical) {
      getCebLSIValue({
        Method: "masterdata/api/v1/CalculateChemicalAdjustment",
        ...createJsonForTheLSI(UFData.alkaliValue_CEB <= 14 ? UFData.alkaliValue_CEB : 12),
      });
    } else {
      updateStoreData({ data: { ceb_LSI: 0 } });
    }
  }, [UFData.alkaliValue_CEB, UFData.alkaliChemId_CEB, UFData.alkaliEnabled_Ind_CEB]);

  useEffect(() => {
    const chemical = projectChemicals.find(chem => chem.id == UFData.alkaliChemId_CIP);
    if (isValueInPh && chemical) {
      getCipLSIValue({
        Method: "masterdata/api/v1/CalculateChemicalAdjustment",
        ...createJsonForTheLSI(UFData.alkaliValue_CIP <= 14 ? UFData.alkaliValue_CIP : 12),
      });
    } else {
      updateStoreData({ data: { cip_LSI: 0 } });
    }
  }, [UFData.alkaliValue_CIP, UFData.alkaliChemId_CIP, UFData.alkaliEnabled_Ind_CIP]);

  useEffect(() => {
    const chemical = projectChemicals.find(chem => chem.id == UFData.alkaliChemId_MiniCIP);
    if (isValueInPh && chemical) {
      getMiniCipLSIValue({
        Method: "masterdata/api/v1/CalculateChemicalAdjustment",
        ...createJsonForTheLSI(UFData.alkaliValue_MiniCIP <= 14 ? UFData.alkaliValue_MiniCIP : 12),
      });
    } else {
      updateStoreData({ data: { lSI_MiniCIP: 0 } });
    }
  }, [UFData.alkaliValue_MiniCIP, UFData.alkaliChemId_MiniCIP, UFData.alkaliEnabled_Ind_MiniCIP]);

  useEffect(() => {
    if (responseCebLSIValue.isSuccess) {
      // dispatch(updateCebData())
      const cebLsi = responseCebLSIValue.data.chemicalAdjustment[0].LSI;
      updateStoreData({ data: { ceb_LSI: cebLsi == "NaN" ? 0 : Number(cebLsi).toFixed(2) } });
    }
  }, [responseCebLSIValue]);
  useEffect(() => {
    if (responseCipLSIValue.isSuccess) {
      // dispatch(updateCebData())
      const cebLsi = responseCipLSIValue.data.chemicalAdjustment[0].LSI;
      updateStoreData({ data: { cip_LSI: cebLsi == "NaN" ? 0 : Number(cebLsi).toFixed(2) } });
    }
  }, [responseCipLSIValue]);

  useEffect(() => {
    if (responseMiniCipLSIValue.isSuccess) {
      // dispatch(updateCebData())
      const miniCipLsi = responseMiniCipLSIValue.data.chemicalAdjustment[0].LSI;
      updateStoreData({ data: { lSI_MiniCIP: miniCipLsi == "NaN" ? 0 : Number(miniCipLsi).toFixed(2) } });
    }
  }, [responseMiniCipLSIValue]);
  const headerMenuIconStatus = useSelector(state => state.UFStore.headerMenuIcon);
  const [tabletView, setTabletView] = useState(false);
  const handleCloseSideMenu = () => {
    // setShowSideMenu(!showSideMenu);
    dispatch(updateMenuIconHeader(!headerMenuIconStatus));
  };

  const handleResize = () => {
    if (window.innerWidth <= 1200) {
      setTabletView(true);
      dispatch(updateTabletMenuIcon(tabletView));
    } else {
      setTabletView(false);
      dispatch(updateTabletMenuIcon(tabletView));
    }
  }; //show side menu for width >=1300

  useEffect(() => {
    handleResize(); // set initial state based on window size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabletView]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
    dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
    dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
  };
  const selectPanel = activeTab => {
    switch (activeTab) {
      case "Design":
        return (
          <TabPanel>
            <Design />
          </TabPanel>
        );
      case "Configuration":
        return (
          <TabPanel>
            <UFConfiguration />
          </TabPanel>
        );
      case "Backwash":
        return (
          <TabPanel>
            <Backwash />
          </TabPanel>
        );
      case "CEB":
        return (
          <TabPanel>
            <CEB />
          </TabPanel>
        );
      case "CIP":
        return (
          <TabPanel>
            <CIP />
          </TabPanel>
        );
      case "Additional Settings":
        return (
          <TabPanel>
            <AdditionalSetting />
          </TabPanel>
        );
      case "miniCIP":
        return (
          <TabPanel>
            <UFMiniCip />
          </TabPanel>
        );
      default:
        break;
    }
  };

  return (
    <DynamicLoadder isLoading={!isUFDetailsFetched}>
      <>
        <UFStyled tabletView={tabletView} headerMenuIconStatus={headerMenuIconStatus}>
          <Tabs>
            <div className='uf-tablist-column tablist_overlay'>
              <div className='wrapper'>
                {tabletView && (
                  <div className='tablet_menu_view'>
                    <div>
                      <button className='close_icon_btn' onClick={handleCloseSideMenu}>
                        <TabletCloseMenuIcon />
                      </button>
                    </div>
                    <WaveProBrandLogo handleNavigate={handleNavigate} />
                  </div>
                )}
                <TabList>
                  <div
                    onClick={() => updateUFSubMenus("Design")}
                    className={`tab ${activeTab == "Design" ? "selected" : ""}`}
                  >
                    Design
                  </div>
                  <div
                    onClick={() => updateUFSubMenus("Configuration")}
                    className={`tab ${activeTab == "Configuration" ? "selected" : ""}`}
                  >
                    Configuration
                  </div>
                  <div
                    onClick={() => updateUFSubMenus("Backwash")}
                    className={`tab ${activeTab == "Backwash" ? "selected" : ""}`}
                  >
                    Backwash
                  </div>
                  {(UFData.ufSpecialFeatureID == 1 || UFData.ufSpecialFeatureID == 3) && (
                    <div
                      onClick={() => updateUFSubMenus("CEB")}
                      className={`tab ${activeTab == "CEB" ? "selected" : ""}`}
                    >
                      CEB
                    </div>
                  )}
                  {(UFData.ufSpecialFeatureID == 2 || UFData.ufSpecialFeatureID == 3) && (
                    <div
                      onClick={() => updateUFSubMenus("miniCIP")}
                      className={`tab ${activeTab == "miniCIP" ? "selected" : ""}`}
                    >
                      mini-CIP
                    </div>
                  )}

                  <div
                    onClick={() => updateUFSubMenus("CIP")}
                    className={`tab ${activeTab == "CIP" ? "selected" : ""}`}
                  >
                    CIP
                  </div>
                  <div
                    onClick={() => updateUFSubMenus("Additional Settings")}
                    className={`tab ${activeTab == "Additional Settings" ? "selected" : ""}`}
                  >
                    Additional Settings
                  </div>
                </TabList>
              </div>
            </div>
            <div className='uf-tabPanel-column'>{selectPanel(activeTab)}</div>
          </Tabs>
        </UFStyled>
        {isError && (
          <ProjectErrorPopup
            show={isError}
            close={() => {
              setIsError(false);
            }}
            message={errorMessage}
          />
        )}
      </>
    </DynamicLoadder>
  );
};

export default UF;
