import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useFPAValidation from "@hooks/useFPAValidation";
import useUnitConversion from "@hooks/useUnitConversion";

import { capitalize } from "@utils/appUtils";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import CalcEngineInputBox from "@common/styles/components/inputs/CalcEngineInputBox";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import { colors } from "@common/styles/Theme";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import { IXD_STRING, RESIN_ID, VESSEL_ID } from "./constants/IXDConstants";
import {
  calculateFreeboardMinimum,
  calculateVesselWallThickness,
  formatToFourDecimals,
  getSwellingValue,
  isInertCalculationRequired,
  roundToFourDecimals,
} from "./IXConfiguration/IXhelper";
import FinalParameterAdjustmentStyled from "./FinalParameterAdjustmentStyled";
import {
  setIXDUpdate,
  updatelistFinalParamAdj,
  updateVesselCylindricalHeight,
  updatevesselwallThickness,
} from "./IXDSlice";
import SystemDiagram from "./SystemDiagram";

const FinalParameterAdjustment = () => {
  const [isFocused, setIsFocused] = useState(null);
  const [errorOperator, setErrorOperator] = useState({
    show: false,
    message: "",
  });
  const dispatch = useDispatch();
  const { convertAndFormatLength } = useFPAValidation();
  const ixStore = useSelector(state => state.IXStore.data);
  const { cationResin, anionResin, vessel1, vessel2, vessel3, vessel4, selectedResinList } = ixStore || {};
  const { convertUnit, convertUnitNumber } = useUnitConversion();
  const ixStoreObj = useSelector(state => state.IXStore);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const units = useSelector(state => state.projectInfo?.projectConfig?.unitConfig?.selectedUnits);
  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const [finalInput, setFinalInput] = useState(false);
  const [swellingValueData, setSwellingValueData] = useState([]);
  const [swellingValueData2, setSwellingValueData2] = useState([]);
  const [swellingValueData3, setSwellingValueData3] = useState([]);
  const [swellingValueData4, setSwellingValueData4] = useState([]);

  const [getSwellingValues, responseSwellingValues] = useLazyGetAllDataQuery();
  const [getSwellingValues2, responseSwellingValues2] = useLazyGetAllDataQuery();
  const [getSwellingValues3, responseSwellingValues3] = useLazyGetAllDataQuery();
  const [getSwellingValues4, responseSwellingValues4] = useLazyGetAllDataQuery();
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore ? UserInfoStore.UserId : 1;
  const ixResinID1 = ixStore.selectedResinList[0].ixResinID1;
  const ixResinID2 = ixStore.selectedResinList[0].ixResinID2;
  const ixResinID3 = ixStore.selectedResinList[1].ixResinID1;
  const ixResinID4 = ixStore.selectedResinList[1].ixResinID2;
  const coversionForLength = (value, flag) => {
    if (flag) {
      if (units[8] === "cm") {
        return value * 0.01;
      } else if (units[8] === "in") {
        return value * 0.0254;
      } else {
        return value * 0.001;
      }
    } else {
      if (units[8] === "cm") {
        return value * 100;
      } else if (units[8] === "in") {
        return value * 39.3700787402;
      } else {
        return value * 1000;
      }
    }
  };

  const calculationRequiredData = {
    cation: cationResin,
    anion: anionResin,
    v1: vessel1,
    v2: vessel2,
    v3: vessel3,
    v4: vessel4,
    inert1: selectedResinList[0]?.inert || 0,
    inert2: selectedResinList[1]?.inert || 0,
  };
  const inertBedHeightCalculation = (innerDiameter, vessel, vesselNo) => {
    const indexValue = parseInt(vessel[vessel.length - 1]);
    const Height_Reference_SAC =
      ixStoreObj.listFinalParamAdj[indexValue - 1] &&
      coversionForLength(ixStoreObj.listFinalParamAdj[indexValue - 1].resinBedStandardHeight, true);
    let inertBedHeight =
      ixStoreObj.listFinalParamAdj[indexValue - 1] &&
      coversionForLength(ixStoreObj.listFinalParamAdj[indexValue - 1].inertBedHeight, true);

    const containsInert = isInertCalculationRequired(calculationRequiredData, vesselNo);
    if (containsInert) {
      if (ixStore[vessel] == VESSEL_ID.UPCORE_ID && ixStore[vessel] != null) {
        const var1 = Math.max(0.15, 0.1 + 0.1 * innerDiameter);
        const var2 = 0.0125 * Math.pow(innerDiameter, 2);
        inertBedHeight = var1 - var2;
      } else if (
        (ixStore[vessel] == VESSEL_ID.AIR_BLOCK_ID || ixStore[vessel] == VESSEL_ID.WATER_BLOCK_ID) &&
        ixStore[vessel] != null
      ) {
        inertBedHeight = 0.25;
      } else if (ixStore[vessel] == VESSEL_ID.AMBERPACK_ID && ixStore[vessel] != null) {
        inertBedHeight = Math.max(0.15, 0.05 + 0.05 * innerDiameter);
      } else if (
        ((ixStore[vessel] == VESSEL_ID.MB_INTERNAL_ID || ixStore[vessel] == VESSEL_ID.MB_EXTERNAL_ID) &&
          ixStore[vessel] != null) ||
        (ixStore[vessel] == 2 && ixStore[vessel] != null && vessel === "vessel1")
      ) {
        inertBedHeight = Math.max(0.15, 0.05 + 0.05 * innerDiameter);
        if (Height_Reference_SAC < 0.5) {
          inertBedHeight = 0.2;
        } else if (Height_Reference_SAC < 1.0) {
          inertBedHeight = 0.15 + 0.1 * Height_Reference_SAC;
        } else {
          inertBedHeight = 0.25;
        }
      }
    } else {
      return 0;
    }

    return inertBedHeight;
  };

  const inertResinVolumeCalculation = (inertBedHeight, vesselArea) => {
    const inertResinVolume = inertBedHeight * vesselArea;
    return inertResinVolume;
  };

  const freeboardCalculation = (
    inertBedHeight,
    vessel,
    index1,
    { heightDelivered, heightReference, heightRegen, heightExhaust },
    index,
    checkData,
  ) => {
    const Target_Freeboard_PB = 0.025;
    const Target_Freeboard_BW = 1.0;
    let Height_BW;
    const indexValue = index1;
    const resinName = ixStoreObj[`resinName${indexValue}`];
    const vesselNo = Number(vessel[vessel.length - 1]);

    const isMatchingProcess =
      (["Demineralization", "Condensate Polishing"].includes(ixStoreObj.Demineralization[0].ixProcessName) &&
        ["SAC", "SBA"].includes(resinName)) ||
      (ixStoreObj.Demineralization[0].ixProcessName === "Brackish Softening WAC(H/Na)" && resinName === "WAC");
    const baseHeight = isMatchingProcess ? heightRegen : heightDelivered;
    const resinBedHeightProperty = isMatchingProcess ? "resinBedHeightAsRegenerated" : "resinBedHeightAsDelivered";
    if (
      (cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID && ixStoreObj.listFinalParamAdj[index].isCation) ||
      (anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID && !ixStoreObj.listFinalParamAdj[index].isCation) ||
      cationResin === RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID
    ) {
      const [index0, index1] = checkData;
      if (index0 === index) {
        Height_BW = baseHeight + coversionForLength(ixStoreObj.listFinalParamAdj[index1][resinBedHeightProperty], true);
      } else if (index1 === index) {
        Height_BW = baseHeight + coversionForLength(ixStoreObj.listFinalParamAdj[index0][resinBedHeightProperty], true);
      }
    } else {
      Height_BW = baseHeight || 0;
    }

    let Resin_Height_Max;
    const isCation =
      cationResin &&
      (cationResin == RESIN_ID.WAC_RESIN_ID ||
        cationResin == RESIN_ID.SAC_RESIN_ID ||
        cationResin == RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID ||
        cationResin == RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ||
        cationResin == RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID) &&
      ixStoreObj.listFinalParamAdj[index].isCation;
    const isAnion =
      anionResin &&
      (anionResin == RESIN_ID.WBA_RESIN_ID ||
        anionResin == RESIN_ID.SBA_RESIN_ID ||
        anionResin == RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID ||
        anionResin == RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ||
        anionResin == RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID) &&
      !ixStoreObj.listFinalParamAdj[index].isCation;

    if (isCation || isAnion) {
      Resin_Height_Max = Math.max(heightDelivered, heightReference, heightRegen, heightExhaust);
    } else {
      const [index0, index1] = checkData;

      const newdata = {
        resinBedHeightAsDelivered: heightDelivered,
        resinBedStandardHeight: heightReference,
        resinBedHeightAsRegenerated: heightRegen,
        resinBedHeightAsExhausted: heightExhaust,
      };
      const calculateData = (params, index0, index1, newData) => {
        if (index0 === index) {
          return (
            ixStoreObj.listFinalParamAdj[index1] &&
            coversionForLength(ixStoreObj.listFinalParamAdj[index1][params], true) + newData[params]
          );
        } else if (index1 === index) {
          return (
            ixStoreObj.listFinalParamAdj[index0] &&
            coversionForLength(ixStoreObj.listFinalParamAdj[index0][params], true) + newData[params]
          );
        }
      };
      const resinBedStandardHeightCombined = calculateData("resinBedHeightAsDelivered", index0, index1, newdata);

      const resinBedHeightAsRegeneratedCombined = calculateData("resinBedStandardHeight", index0, index1, newdata);

      const resinBedHeightAsExhaustedCombined = calculateData("resinBedHeightAsRegenerated", index0, index1, newdata);

      const resinBedHeightAsDeliveredCombined = calculateData("resinBedHeightAsExhausted", index0, index1, newdata);
      Resin_Height_Max = Math.max(
        resinBedStandardHeightCombined,
        resinBedHeightAsRegeneratedCombined,
        resinBedHeightAsExhaustedCombined,
        resinBedHeightAsDeliveredCombined,
      );
    }
    const Freeboard_minimum = calculateFreeboardMinimum(
      ixStore,
      vessel,
      Height_BW,
      Target_Freeboard_BW,
      Resin_Height_Max,
      Target_Freeboard_PB,
    );
    const vesselCylindricalHeight = Resin_Height_Max + inertBedHeight + Freeboard_minimum;
    const freeboard = vesselCylindricalHeight - Resin_Height_Max - inertBedHeight;
    return [vesselCylindricalHeight, freeboard];
  };

  /**
   * This function(calculateAndPrepareObject) performs calculations when the user changes any of the input fields.
   * It is triggered after the final parameter adjustment screen is loaded, ensuring that
   * all adjustments made by the user are taken into account for recalculating the necessary
   * parameters. This includes calculating the inert bed height, resin volumes in different states,
   * and adjusting for vessel dimensions and characteristics based on the user's input.
   */
  function calculateAndPrepareObject(
    finalobj,
    name,
    value,
    innerDiameter,
    vessel,
    vesselNo,
    vesselArea,
    resinVolumeAsDelivered,
    swellingSold,
    swellingRegen,
    swellingExhaust,
    vesselWallThickness,
    GlobalUnitConversionStore,
    unit,
    index,
    checkData2 = [],
  ) {
    const vesselConditionMet =
      (cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID && ixStoreObj.listFinalParamAdj[index].isCation) ||
      (anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID && !ixStoreObj.listFinalParamAdj[index].isCation) ||
      cationResin === RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID;

    let inertBedHeight;
    let inertResinVolume;
    if (name === "inertBedHeight") {
      inertBedHeight = coversionForLength(value, true);
      inertResinVolume = inertResinVolumeCalculation(coversionForLength(value, true), vesselArea);
    } else {
      inertBedHeight = inertBedHeightCalculation(innerDiameter, vessel, vesselNo);
      inertResinVolume = inertResinVolumeCalculation(inertBedHeight, vesselArea);
    }
    const heightDelivered = resinVolumeAsDelivered / vesselArea;
    const heightReference = heightDelivered / swellingSold;
    const heightRegen = heightReference * swellingRegen;
    const heightExhaust = heightReference * swellingExhaust;
    const [vesselCylindricalHeight, freeBoard] = freeboardCalculation(
      inertBedHeight,
      vessel,
      vesselNo,
      {
        heightDelivered,
        heightReference,
        heightRegen,
        heightExhaust,
      },
      index,
      checkData2,
    );
    if (name == "vesselDiameter") {
      if (value < validations("vesselDiameter")[0] || value > validations("vesselDiameter")[1])
        return { ...finalobj, [name]: value };
    }
    let tempVesselCylindricalData = coversionForLength(vesselCylindricalHeight, false);

    if (vesselConditionMet) {
      checkData2.map(item => {
        const tempVesselCylindricalObj = ixStoreObj.saveVesselCylindricalHeight[`vesselCylindricalHeight${item}`];
        tempVesselCylindricalData = tempVesselCylindricalObj.isUserInput
          ? finalobj.vesselCylindricalHeight
          : coversionForLength(vesselCylindricalHeight, false);
        dispatch(
          updateVesselCylindricalHeight({
            value: coversionForLength(vesselCylindricalHeight, false),
            index: item,
            freeBoard: coversionForLength(freeBoard, false),
            vesselName: `Vessel${vesselNo}`,
          }),
        );
      });
    } else {
      const tempVesselCylindricalObj = ixStoreObj.saveVesselCylindricalHeight[`vesselCylindricalHeight${index}`];
      tempVesselCylindricalData = tempVesselCylindricalObj.isUserInput
        ? finalobj.vesselCylindricalHeight
        : coversionForLength(vesselCylindricalHeight, false);
      dispatch(
        updateVesselCylindricalHeight({
          value: coversionForLength(vesselCylindricalHeight, false),
          index: index,
          freeBoard: coversionForLength(freeBoard, false),
          vesselName: `Vessel${vesselNo}`,
        }),
      );
    }

    return {
      ...finalobj,
      [name]: value,
      vesselWallThickness:
        name === IXD_STRING.VESSEL_WALL_THICKNESS ? value : convertAndFormatLength(vesselWallThickness),
      resinBedHeightAsDelivered: convertAndFormatLength(heightDelivered),
      resinBedStandardHeight: convertAndFormatLength(heightReference),
      resinBedHeightAsExhausted: convertAndFormatLength(heightExhaust),
      resinBedHeightAsRegenerated: convertAndFormatLength(heightRegen),
      inertBedHeight: name === IXD_STRING.INERT_BED_HEIGHT ? value : convertAndFormatLength(inertBedHeight),
      inertResinVolume: formatToFourDecimals(convertUnit(inertResinVolume, SELECTED_UNIT.VOLUME, UNITS.CUBIC_METER, 0)),
      freeBoard: convertAndFormatLength(freeBoard),
      vesselCylindricalHeight: formatToFourDecimals(tempVesselCylindricalData),
      minVesselCylindricalHeight: convertAndFormatLength(vesselCylindricalHeight),
      minFreeBoard: convertAndFormatLength(freeBoard),
    };
  }

  const handleSpecialCase = (e, vesselNo, checkData = [0, 1]) => {
    dispatch(setIXDUpdate(true));
    const copy = checkData;
    const { name, value } = e.target;
    const vessel = `vessel${vesselNo}`;

    const calcuteData = (
      inertBedHeight,
      vessel,
      vesselNo,
      Height_BW,
      { heightDelivered, heightReference, heightRegen, heightExhaust },
    ) => {
      const Target_Freeboard_PB = 0.025;
      const Target_Freeboard_BW = 1.0;
      const Resin_Height_Max = Math.max(heightDelivered, heightReference, heightRegen, heightExhaust);
      const Freeboard_minimum = calculateFreeboardMinimum(
        ixStore,
        vessel,
        Height_BW,
        Target_Freeboard_BW,
        Resin_Height_Max,
        Target_Freeboard_PB,
      );
      const vesselCylindricalHeight = Resin_Height_Max + inertBedHeight + Freeboard_minimum;
      const freeboard = vesselCylindricalHeight - Resin_Height_Max - inertBedHeight;
      return [vesselCylindricalHeight, freeboard];
    };
    const newData = checkData.map(index => {
      const resinName = ixStoreObj[`resinName${index}`];
      let swellingData = [];
      let swellingSold = 0;
      let swellingRegen = 0;
      let swellingExhaust = 0;

      if (index === 0) {
        swellingData = swellingValueData;
      } else if (index === 1) {
        swellingData = swellingValueData2;
      } else if (index === 2) {
        swellingData = swellingValueData3;
      } else {
        swellingData = swellingValueData4;
      }
      if (ixStoreObj.Demineralization[0].ixProcessName !== "Demineralization") {
        swellingSold = getSwellingValue(swellingData, IXD_STRING.NACL_CYCLE_SOLD);
        swellingRegen = getSwellingValue(swellingData, IXD_STRING.NACL_CYCLE_REGEN);
        swellingExhaust = getSwellingValue(swellingData, IXD_STRING.NACL_CYCLE_EXHAUST);
      } else {
        swellingSold = getSwellingValue(swellingData, IXD_STRING.OH_CYCLE_SOLD);
        swellingRegen = getSwellingValue(swellingData, IXD_STRING.OH_CYCLE_REGEN);
        swellingExhaust = getSwellingValue(swellingData, IXD_STRING.OH_CYCLE_EXHAUST);
      }
      const finalobj = ixStoreObj.listFinalParamAdj[index];
      const resinVolumeAsDelivered = convertUnitNumber(
        name == "resinVolumeAsDelivered" ? Number(value) : finalobj.resinVolumeAsDelivered,
        UNITS.CUBIC_METER,
        SELECTED_UNIT.VOLUME,
        0,
      );
      let vesselWallThickness = coversionForLength(
        name == "vesselWallThickness" ? Number(value) : ixStoreObj.listFinalParamAdj[vesselNo - 1].vesselWallThickness,
        true,
      );
      const vesselDiameter = coversionForLength(
        name == "vesselDiameter" ? Number(value) : finalobj.vesselDiameter,
        true,
      );
      if (name === "vesselDiameter") {
        vesselWallThickness = calculateVesselWallThickness(vesselDiameter);
      }
      const innerDiameter = vesselDiameter - 2 * vesselWallThickness;
      const vesselArea = Math.PI * Math.pow(innerDiameter / 2, 2);
      let heightDelivered = coversionForLength(finalobj.resinBedHeightAsDelivered, true);
      let heightReference = coversionForLength(finalobj.resinBedStandardHeight, true);
      let heightRegen = coversionForLength(finalobj.resinBedHeightAsRegenerated, true);
      let heightExhaust = coversionForLength(finalobj.resinBedHeightAsExhausted, true);
      let inertBedHeight;
      let inertResinVolume;
      if (name === "inertBedHeight") {
        inertBedHeight = coversionForLength(value, true);
        inertResinVolume = inertResinVolumeCalculation(coversionForLength(value, true), vesselArea);
      } else {
        inertBedHeight = inertBedHeightCalculation(innerDiameter, vessel, vesselNo);
        inertResinVolume = inertResinVolumeCalculation(inertBedHeight, vesselArea);
      }
      heightDelivered = resinVolumeAsDelivered / vesselArea;
      heightReference = heightDelivered / swellingSold;
      heightRegen = heightReference * swellingRegen;
      heightExhaust = heightReference * swellingExhaust;
      let Height_BW;
      if (
        (ixStoreObj.Demineralization[0].ixProcessName === "Demineralization" &&
          (resinName === "SAC" || resinName === "SBA")) ||
        (ixStoreObj.Demineralization[0].ixProcessName === "Condensate Polishing" &&
          (resinName === "SAC" || resinName === "SBA")) ||
        (ixStoreObj.Demineralization[0].ixProcessName === "Brackish Softening WAC(H/Na)" && resinName === "WAC")
      ) {
        Height_BW = heightRegen || 0;
      } else {
        Height_BW = heightDelivered || 0;
      }

      const [vesselCylindricalHeight, freeBoard] = calcuteData(inertBedHeight, vessel, vesselNo, Height_BW, {
        heightDelivered,
        heightReference,
        heightRegen,
        heightExhaust,
      });

      const tempVesselCylindricalObj0 = ixStoreObj.saveVesselCylindricalHeight[`vesselCylindricalHeight${index}`];
      const tempVesselCylindricalData0 = tempVesselCylindricalObj0.isUserInput
        ? finalobj.vesselCylindricalHeight
        : coversionForLength(vesselCylindricalHeight);
      dispatch(
        updateVesselCylindricalHeight({
          value: convertAndFormatLength(vesselCylindricalHeight),
          index: index,
          freeBoard: convertAndFormatLength(freeBoard),
          vesselName: `Vessel${vesselNo}`,
        }),
      );
      return {
        ...finalobj,
        subindex: index,
        [name]: value,
        Height_BW: Height_BW,
        vesselWallThickness:
          name === IXD_STRING.VESSEL_WALL_THICKNESS ? value : convertAndFormatLength(vesselWallThickness),
        resinBedHeightAsDelivered: convertAndFormatLength(heightDelivered),
        resinBedStandardHeight: convertAndFormatLength(heightReference),
        resinBedHeightAsExhausted: convertAndFormatLength(heightExhaust),
        resinBedHeightAsRegenerated: convertAndFormatLength(heightRegen),
        inertBedHeight: name === IXD_STRING.INERT_BED_HEIGHT ? value : convertAndFormatLength(inertBedHeight),
        inertResinVolume: formatToFourDecimals(
          convertUnit(inertResinVolume, SELECTED_UNIT.VOLUME, UNITS.CUBIC_METER, 0),
        ),
        freeBoard: convertAndFormatLength(freeBoard),
        vesselCylindricalHeight: formatToFourDecimals(tempVesselCylindricalData0),
      };
    });
    if (newData.length <= 0) return;
    if (
      (cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID && checkData == [0, 1]) ||
      (anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID && checkData == [2, 3]) ||
      cationResin === RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID
    ) {
      const [vesselCylindricalHeight, freeBoard] = calcuteData(
        coversionForLength(newData[0].inertBedHeight, true),
        vessel,
        vesselNo,
        Number(newData[0].Height_BW) + Number(newData[1].Height_BW),
        {
          heightDelivered: coversionForLength(
            Number(newData[0].resinBedHeightAsDelivered) + Number(newData[1].resinBedHeightAsDelivered),
            true,
          ),
          heightReference: coversionForLength(
            Number(newData[0].resinBedStandardHeight) + Number(newData[1].resinBedStandardHeight),
            true,
          ),
          heightRegen: coversionForLength(
            Number(newData[0].resinBedHeightAsExhausted) + Number(newData[1].resinBedHeightAsExhausted),
            true,
          ),
          heightExhaust: coversionForLength(
            Number(newData[0].resinBedHeightAsRegenerated) + Number(newData[1].resinBedHeightAsRegenerated),
            true,
          ),
        },
      );
      const finalobj = ixStoreObj.listFinalParamAdj;
      const tempVesselCylindricalObj0 =
        ixStoreObj.saveVesselCylindricalHeight[`vesselCylindricalHeight${newData[0].subindex}`];
      const tempVesselCylindricalData0 = tempVesselCylindricalObj0.isUserInput
        ? finalobj[newData[0].subindex].vesselCylindricalHeight
        : coversionForLength(vesselCylindricalHeight, false);
      dispatch(
        updateVesselCylindricalHeight({
          value: convertAndFormatLength(vesselCylindricalHeight),
          index: newData[0].subindex,
          freeBoard: convertAndFormatLength(freeBoard),
          vesselName: `Vessel${vesselNo}`,
        }),
      );
      dispatch(
        updateVesselCylindricalHeight({
          value: convertAndFormatLength(vesselCylindricalHeight),
          index: newData[1].subindex,
          freeBoard: convertAndFormatLength(freeBoard),
          vesselName: `Vessel${vesselNo}`,
        }),
      );
      newData[0] = {
        ...newData[0],
        freeBoard: convertAndFormatLength(freeBoard),
        vesselCylindricalHeight: formatToFourDecimals(tempVesselCylindricalData0),
      };

      newData[1] = {
        ...newData[1],
        freeBoard: convertAndFormatLength(freeBoard),
        vesselCylindricalHeight: formatToFourDecimals(tempVesselCylindricalData0),
      };
    }
    const finalData = ixStoreObj.listFinalParamAdj.map((item, index) =>
      copy.includes(index) ? newData.find(item => item.subindex === index) : item,
    );

    if (name == "vesselDiameter") {
      if (value < validations("vesselDiameter")[0] || value > validations("vesselDiameter")[1]) {
        const updatedList = ixStoreObj.listFinalParamAdj.map((item, index) =>
          checkData.includes(index) ? { ...item, vesselDiameter: value } : item,
        );
        dispatch(updatelistFinalParamAdj(updatedList));
        return null;
      }
    }

    dispatch(
      updatevesselwallThickness({
        [`vesselWallThickness${copy[0]}`]: newData[0].vesselWallThickness,
      }),
    );
    dispatch(updatelistFinalParamAdj(finalData));
  };
  const handleChange = (e, vesselNo, subIndex, featured, featuredIndex, checkData = [], checkData2 = []) => {
    dispatch(setIXDUpdate(true));
    let swellingData = [];
    let swellingSold = 0;
    let swellingRegen = 0;
    let swellingExhaust = 0;
    const vessel = `vessel${vesselNo}`;
    if (subIndex === 0) {
      swellingData = swellingValueData;
    } else if (subIndex === 1) {
      swellingData = swellingValueData2;
    } else if (subIndex === 2) {
      swellingData = swellingValueData3;
    } else {
      swellingData = swellingValueData4;
    }
    if (ixStoreObj.Demineralization[0].ixProcessName !== "Demineralization") {
      swellingSold = getSwellingValue(swellingData, IXD_STRING.NACL_CYCLE_SOLD);
      swellingRegen = getSwellingValue(swellingData, IXD_STRING.NACL_CYCLE_REGEN);
      swellingExhaust = getSwellingValue(swellingData, IXD_STRING.NACL_CYCLE_EXHAUST);
    } else {
      swellingSold = getSwellingValue(swellingData, IXD_STRING.OH_CYCLE_SOLD);
      swellingRegen = getSwellingValue(swellingData, IXD_STRING.OH_CYCLE_REGEN);
      swellingExhaust = getSwellingValue(swellingData, IXD_STRING.OH_CYCLE_EXHAUST);
    }
    let { value, name } = e.target;
    if (value !== "") {
      value = parseFloat(value.trim(), 10);
    }
    const updatedSubData = ixStoreObj.listFinalParamAdj.map((finalobj, index) => {
      const resinVolumeAsDelivered = convertUnitNumber(
        name == "resinVolumeAsDelivered" ? Number(value) : finalobj.resinVolumeAsDelivered,
        UNITS.CUBIC_METER,
        SELECTED_UNIT.VOLUME,
        0,
      );
      let vesselWallThickness = coversionForLength(
        name == "vesselWallThickness" ? Number(value) : ixStoreObj.listFinalParamAdj[vesselNo - 1].vesselWallThickness,
        true,
      );
      const vesselDiameter = coversionForLength(
        name == "vesselDiameter" ? Number(value) : finalobj.vesselDiameter,
        true,
      );

      if (name === "vesselDiameter") {
        vesselWallThickness = calculateVesselWallThickness(vesselDiameter);
      }
      const innerDiameter = vesselDiameter - 2 * vesselWallThickness;
      const vesselArea = Math.PI * Math.pow(innerDiameter / 2, 2);
      let heightDelivered = coversionForLength(finalobj.resinBedHeightAsDelivered, true);
      let heightReference = coversionForLength(finalobj.resinBedStandardHeight, true);
      let heightRegen = coversionForLength(finalobj.resinBedHeightAsRegenerated, true);
      let heightExhaust = coversionForLength(finalobj.resinBedHeightAsExhausted, true);

      let newResinVolumeValue = 0;
      if (name === "resinVolumeAsDelivered") {
        newResinVolumeValue = value;
      } else {
        newResinVolumeValue = resinVolumeAsDelivered;
      }
      if (
        subIndex === featuredIndex &&
        index === vesselNo &&
        featured === true &&
        (name === "vesselDiameter" || name === "vesselWallThickness")
      ) {
        if (name === "vesselDiameter") {
          return calculateAndPrepareObject(
            finalobj,
            name,
            value,
            innerDiameter,
            vessel,
            vesselNo,
            vesselArea,
            resinVolumeAsDelivered,
            swellingSold,
            swellingRegen,
            swellingExhaust,
            vesselWallThickness,
            GlobalUnitConversionStore,
            unit,
            index,
            checkData2,
          );
        } else if (name === "vesselWallThickness") {
          const inertBedHeight = inertBedHeightCalculation(innerDiameter, vessel, vesselNo);
          const inertResinVolume = inertResinVolumeCalculation(inertBedHeight, vesselArea);

          heightDelivered = newResinVolumeValue / vesselArea;
          heightReference = heightDelivered / swellingSold;
          heightRegen = heightReference * swellingRegen;
          heightExhaust = heightReference * swellingExhaust;
          const [vesselCylindricalHeight, freeBoard] = freeboardCalculation(
            inertBedHeight,
            vessel,
            index,
            {
              heightDelivered,
              heightReference,
              heightRegen,
              heightExhaust,
            },
            index,
            checkData2,
          );

          return {
            ...finalobj,
            [name]: value,
            resinBedHeightAsDelivered: coversionForLength(heightDelivered, false),
            resinBedStandardHeight: coversionForLength(heightReference, false),
            resinBedHeightAsExhausted: coversionForLength(heightExhaust, false),
            resinBedHeightAsRegenerated: coversionForLength(heightRegen, false),
            inertBedHeight: coversionForLength(inertBedHeight, false),
            inertResinVolume: convertUnit(inertResinVolume, SELECTED_UNIT.VOLUME, UNITS.CUBIC_METER, 0),
            freeBoard: coversionForLength(freeBoard, false),
            vesselCylindricalHeight: coversionForLength(vesselCylindricalHeight, false),
            minVesselCylindricalHeight: vesselCylindricalHeight,
            minFreeBoard: freeBoard,
          };
        } else {
          return { ...finalobj, [name]: value };
        }
      }

      if (index === subIndex || checkData.includes(index)) {
        if (name === "vesselDiameter") {
          return calculateAndPrepareObject(
            finalobj,
            name,
            value,
            innerDiameter,
            vessel,
            vesselNo,
            vesselArea,
            resinVolumeAsDelivered,
            swellingSold,
            swellingRegen,
            swellingExhaust,
            vesselWallThickness,
            GlobalUnitConversionStore,
            unit,
            index,
            checkData2,
          );
        } else if (name === "resinVolumeAsDelivered" || name === "vesselWallThickness" || name === "inertBedHeight") {
          return calculateAndPrepareObject(
            finalobj,
            name,
            value,
            innerDiameter,
            vessel,
            vesselNo,
            vesselArea,
            resinVolumeAsDelivered,
            swellingSold,
            swellingRegen,
            swellingExhaust,
            vesselWallThickness,
            GlobalUnitConversionStore,
            unit,
            index,
            checkData2,
          );
        } else if (name === "vesselCylindricalHeight") {
          dispatch(updateVesselCylindricalHeight({ isInput: true, index: index }));
          if (
            (cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID &&
              ixStoreObj.listFinalParamAdj[index].isCation) ||
            (anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID &&
              !ixStoreObj.listFinalParamAdj[index].isCation) ||
            cationResin === RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID
          ) {
            const resinHeightMax = Math.max(
              parseFloat(ixStoreObj.listFinalParamAdj[checkData[0]].resinBedHeightAsDelivered) +
                parseFloat(ixStoreObj.listFinalParamAdj[checkData[1]].resinBedHeightAsDelivered),
              parseFloat(ixStoreObj.listFinalParamAdj[checkData[0]].resinBedStandardHeight) +
                parseFloat(ixStoreObj.listFinalParamAdj[checkData[1]].resinBedStandardHeight),
              parseFloat(ixStoreObj.listFinalParamAdj[checkData[0]].resinBedHeightAsExhausted) +
                parseFloat(ixStoreObj.listFinalParamAdj[checkData[1]].resinBedHeightAsExhausted),
              parseFloat(ixStoreObj.listFinalParamAdj[checkData[0]].resinBedHeightAsRegenerated) +
                parseFloat(ixStoreObj.listFinalParamAdj[checkData[1]].resinBedHeightAsRegenerated),
            );
            const freeboard = value - resinHeightMax - ixStoreObj.listFinalParamAdj[checkData[0]].inertBedHeight;

            return {
              ...finalobj,
              ["freeBoard"]: freeboard,
              [name]: value,
            };
          } else {
            const resinHeightMax = Math.max(
              parseFloat(ixStoreObj.listFinalParamAdj[index].resinBedHeightAsDelivered),
              parseFloat(ixStoreObj.listFinalParamAdj[index].resinBedStandardHeight),
              parseFloat(ixStoreObj.listFinalParamAdj[index].resinBedHeightAsExhausted),
              parseFloat(ixStoreObj.listFinalParamAdj[index].resinBedHeightAsRegenerated),
            );

            const freeboard = value - resinHeightMax - ixStoreObj.listFinalParamAdj[index].inertBedHeight;

            return {
              ...finalobj,
              ["freeBoard"]: freeboard,
              [name]: value,
            };
          }
        } else {
          return { ...finalobj, [name]: value };
        }
      }
      return finalobj;
    });

    if (
      (cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID && ixStoreObj.listFinalParamAdj[subIndex].isCation) ||
      (anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID && !ixStoreObj.listFinalParamAdj[subIndex].isCation) ||
      cationResin === RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID
    ) {
      const { freeBoard, vesselCylindricalHeight } = updatedSubData.find((item, index) => index === subIndex);

      const newData = updatedSubData.map((item, index) => {
        if (checkData2.includes(index)) {
          return {
            ...item,
            freeBoard: freeBoard,
            vesselCylindricalHeight: vesselCylindricalHeight,
            minVesselCylindricalHeight: vesselCylindricalHeight,
            minFreeBoard: freeBoard,
          };
        }
        return item;
      });
      dispatch(updatelistFinalParamAdj(newData));
    } else {
      dispatch(updatelistFinalParamAdj(updatedSubData));
    }
  };
  const validations = field => {
    if (field == "vesselWallThickness") {
      const minRange =
        unit.selectedUnits[8] === "mm"
          ? 0
          : Number(GlobalUnitConversion(GlobalUnitConversionStore, 0, unit.selectedUnits[8], "mm")?.toFixed(2));
      const maxRange =
        unit.selectedUnits[8] === "mm"
          ? 100
          : Number(GlobalUnitConversion(GlobalUnitConversionStore, 100, unit.selectedUnits[8], "mm")?.toFixed(2));
      return [minRange, maxRange];
    }
    if (field == "vesselDiameter") {
      const minRange =
        unit.selectedUnits[8] === "mm"
          ? 100
          : Number(GlobalUnitConversion(GlobalUnitConversionStore, 100, unit.selectedUnits[8], "mm")?.toFixed(2));
      const maxRange =
        unit.selectedUnits[8] === "mm"
          ? 5000
          : Number(GlobalUnitConversion(GlobalUnitConversionStore, 5000, unit.selectedUnits[8], "mm")?.toFixed(2));
      return [minRange, maxRange];
    }
    if (field == "resinVolumeAsDelivered") {
      const minRange =
        unit.selectedUnits[12] === "m³"
          ? 0
          : Number(GlobalUnitConversion(GlobalUnitConversionStore, 0, unit.selectedUnits[12], "m³")?.toFixed(2));
      const maxRange =
        unit.selectedUnits[12] === "m³"
          ? 60
          : Number(GlobalUnitConversion(GlobalUnitConversionStore, 60, unit.selectedUnits[12], "m³")?.toFixed(2));
      return [minRange, maxRange];
    }
  };
  const giveErrorMessage = (label, value) =>
    `The ${label} value entered is outside the allowed range (${value[0]} to ${value[1]}). Please revise your input.`;
  const closeErrorMessag = () => {
    if (errorOperator.index !== null) {
      const newList = ixStoreObj.listFinalParamAdj.map((item, index) => {
        if (index == errorOperator.index) {
          return {
            ...item,
            vesselCylindricalHeight: formatToFourDecimals(item.minVesselCylindricalHeight),
            freeBoard: formatToFourDecimals(item.minFreeBoard),
          };
        }
        return item;
      });
      dispatch(updatelistFinalParamAdj(newList));
    }
    setErrorOperator({
      show: false,
      message: "",
      index: null,
    });
  };
  const handleBlur = (e, subIndex) => {
    const { name, value } = e.target;
    if (name != "vesselCylindricalHeight") {
      if (value < validations(name)[0] || value > validations(name)[1]) {
        const parts = name.split(/(?=[A-Z)])/);
        parts[0] = capitalize(parts[0]);
        const label = parts.join(" ");
        setErrorOperator({
          show: true,
          message: giveErrorMessage(label, validations(name)),
        });
        setTimeout(() => {
          e.target.focus();
        }, 0);
      } else {
        const list = [...ixStoreObj.listFinalParamAdj];
        const newList = list.map((item, index) => {
          if (index === subIndex) {
            return {
              ...item,
              [e.target.name]: formatToFourDecimals(e.target.value),
            };
          }
          return item;
        });

        dispatch(updatelistFinalParamAdj(newList));
        setIsFocused(null);
        setErrorOperator({
          show: false,
          message: "",
        });
      }
    }
  };
  const handleBlurHeight = (event, subIndex) => {
    const { name, value } = event.target;
    const formattedValue = formatToFourDecimals(value);
    const newList = ixStoreObj.listFinalParamAdj.map((item, index) =>
      index === subIndex ? { ...item, [name]: formattedValue } : item,
    );
    dispatch(updatelistFinalParamAdj(newList));
  };
  const finalParameterRadio = e => {
    const list = [...ixStoreObj.listFinalParamAdj];
    list[0] = { ...list[0], ixfpaRadioButtonID: parseInt(e.target.value) };
    dispatch(updatelistFinalParamAdj(list));
  };
  useEffect(() => {
    if (ixStore.cationResin === ixStore.anionResin) {
      setFinalInput(true);
    } else {
      setFinalInput(false);
    }
    try {
      const finalList = ixStoreObj.listFinalParamAdj;
      dispatch(updatelistFinalParamAdj(finalList));
      const allResinsArray = [ixResinID1, ixResinID2, ixResinID3, ixResinID4];
      const filteredList = allResinsArray.filter(item => item);
      const [swellingValue1, swellingValue2, swellingValue3, swellingValue4] = filteredList;

      if (swellingValue1) {
        getSwellingValues(
          `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue1}`,
        );
      }
      if (swellingValue2) {
        getSwellingValues2(
          `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue2}`,
        );
      }
      if (swellingValue3) {
        getSwellingValues3(
          `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue3}`,
        );
      }
      if (swellingValue4) {
        getSwellingValues4(
          `${"ix/api/v1/Resin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixResinID=${swellingValue4}`,
        );
      }
    } catch {
      console.log("Error: Fetch IXDetails data base on ixLeftpanel selectin");
    }
  }, []);
  useEffect(() => {
    if (responseSwellingValues.isSuccess) {
      setSwellingValueData(responseSwellingValues.data);
    }
  }, [responseSwellingValues]);
  useEffect(() => {
    if (responseSwellingValues2.isSuccess) {
      setSwellingValueData2(responseSwellingValues2.data);
    }
  }, [responseSwellingValues2]);
  useEffect(() => {
    if (responseSwellingValues3.isSuccess) {
      setSwellingValueData3(responseSwellingValues3.data);
    }
  }, [responseSwellingValues3]);
  useEffect(() => {
    if (responseSwellingValues4.isSuccess) {
      setSwellingValueData4(responseSwellingValues4.data);
    }
  }, [responseSwellingValues4]);

  const handleFocus = id => {
    setIsFocused(id);
  };
  const data = ixStoreObj?.listFinalParamAdj;
  const dataIndex = 0;

  return (
    <>
      <FinalParameterAdjustmentStyled className='g-0'>
        <SystemDiagram />
        {finalInput === true ? (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {data && data?.length > 1 && (
              <>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 1"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[0].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[0].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 1, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[0].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[0].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[0].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[0].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 101)}
                            onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 101}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex}`}
                            value={data && data[0].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[0].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex}`}
                            value={data && data[0].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[0].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex}`}
                            value={data && data[0].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[0].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex}`}
                            value={data && data[0].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[0].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>

                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[0].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[0].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              onChange={e => handleChange(e, 1, dataIndex, true, 1, [], [0, 1])}
                              name='inertBedHeight'
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && data[0].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex}`}
                              onChange={e => handleChange(e, 1, dataIndex)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[0].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[0].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 201)}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 201}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex}`}
                              value={data && data[0].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[0].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 1}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[1].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[1].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 1}`}
                            onChange={e => handleChange(e, 1, dataIndex + 1, true, 0, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[1].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[1].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[1].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[1].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 105)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 105}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[1].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 1}`}
                            value={data && data[1].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[1].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[1].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[1].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 1}`}
                              value={data && data[1].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[1].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight2${dataIndex + 1}`}
                              value={data && data[1].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleChange(e, 1, dataIndex + 1, true, 0, [], [0, 1])}
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard2${dataIndex + 1}`}
                              value={data && data[1].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[1].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight2${dataIndex + 1}`}
                              onChange={e => handleChange(e, 1, dataIndex + 1)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[1].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[1].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 301)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 301}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>

                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty2${dataIndex + 1}`}
                              value={data && data[1].pressureDropwithRecomQty}
                              name='inertResinVolume'
                              placeholder={data && data[1].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 2}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex}`}
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[0].vesselDiameter) ||
                              (data && data[0].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[0].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[0].vesselDiameter}
                            name='vesselDiameter'
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 113)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                            isFocused={isFocused === dataIndex + 113}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsDelivered) +
                                  parseFloat(data[1].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedStandardHeight) + parseFloat(data[1].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsRegenerated) +
                                  parseFloat(data[1].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsExhausted) +
                                  parseFloat(data[1].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && parseFloat(data[0].inertResinVolume)}
                              name='inertResinVolume'
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onChange={e => handleSpecialCase(e, 1, [0, 1])}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && parseFloat(data[0].freeBoard)}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 0, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[0].vesselCylindricalHeight) +
                                      parseFloat(data[1].vesselCylindricalHeight),
                                  )
                                : data && data[0].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            placeholder={data && data[0].vesselCylindricalHeight}
                            defaultValue=''
                            disabled={ixStore.cationResin === RESIN_ID.SBA_SAC_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 223)}
                            onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 223}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            isError={
                              (data && data[0].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                              (data && data[0].vesselWallThickness > validations("vesselWallThickness")[1])
                            }
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={data[0].vesselWallThickness}
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 909)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                            isFocused={isFocused === dataIndex + 909}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].pressureDropwithRecomQty) +
                                  parseFloat(data[1].pressureDropwithRecomQty),
                              )
                            }
                            placeholder={data && data[0].pressureDropwithRecomQty}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              </>
            )}
          </div>
        ) : (ixStore.cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID ||
            ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID) &&
          (ixStore.anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID ||
            ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID) ? (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {data && data?.length > 1 && (
              <>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 1"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[0].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[0].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 1, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[0].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[0].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[0].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[0].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 105)}
                            onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 105}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex}`}
                            value={data && data[0].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[0].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex}`}
                            value={data && data[0].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[0].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex}`}
                            value={data && data[0].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[0].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex}`}
                            value={data && data[0].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[0].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[0].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[0].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              placeholder={data && data[0].inertBedHeight}
                              defaultValue=''
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && data[0].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex}`}
                              onChange={e => handleChange(e, 1, dataIndex)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[0].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[0].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 223)}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 223}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex}`}
                              value={data && data[0].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[0].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 1}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[1].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[1].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 1}`}
                            onChange={e => handleChange(e, 1, dataIndex + 1, true, 0, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[1].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[1].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[1].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[1].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 166)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 166}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[1].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 1}`}
                            value={data && data[1].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[1].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[1].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[1].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 1}`}
                              value={data && data[1].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[1].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight2${dataIndex + 1}`}
                              value={data && data[1].inertBedHeight}
                              name='inertBedHeight'
                              placeholder={data && data[1].inertBedHeight}
                              defaultValue=''
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard2${dataIndex + 1}`}
                              value={data && data[1].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[1].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight2${dataIndex + 1}`}
                              onChange={e => handleChange(e, 1, dataIndex + 1)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[1].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[1].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 707)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 707}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty2${dataIndex + 1}`}
                              value={data && data[1].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[1].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 2}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex}`}
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[0].vesselDiameter) ||
                              (data && data[0].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[0].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[0].vesselDiameter}
                            name='vesselDiameter'
                            placeholder={data && data[0].vesselDiameter}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 808)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                            isFocused={isFocused === dataIndex + 808}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsDelivered) +
                                  parseFloat(data[1].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedStandardHeight) + parseFloat(data[1].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsRegenerated) +
                                  parseFloat(data[1].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsExhausted) +
                                  parseFloat(data[1].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && parseFloat(data[0].inertResinVolume)}
                              name='inertResinVolume'
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              disabled={ixStore.cationResin === 7}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onChange={e => handleSpecialCase(e, 1, [0, 1])}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && parseFloat(data[0].freeBoard)}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 0, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[0].vesselCylindricalHeight) +
                                      parseFloat(data[1].vesselCylindricalHeight),
                                  )
                                : data && data[0].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            placeholder={data && data[0].vesselCylindricalHeight}
                            defaultValue=''
                            disabled={ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 223)}
                            onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 223}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            isError={
                              (data && data[0].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                              (data && data[0].vesselWallThickness > validations("vesselWallThickness")[1])
                            }
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={data[0].vesselWallThickness}
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 909)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                            isFocused={isFocused === dataIndex + 909}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].pressureDropwithRecomQty) +
                                  parseFloat(data[1].pressureDropwithRecomQty),
                              )
                            }
                            placeholder={data && data[0].pressureDropwithRecomQty}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 3}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 2"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[2].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[2].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex + 2}`}
                            onChange={e => handleChange(e, 2, dataIndex + 2, true, 3, [], [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[2].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[2].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[2].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[2].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 2110)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 2110}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex + 2}`}
                            value={data && data[2].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[2].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex + 2}`}
                            value={data && data[2].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[2].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex + 2}`}
                            value={data && data[2].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[2].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex + 2}`}
                            value={data && data[2].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[2].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex + 2}`}
                              value={data && data[2].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[2].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex + 2}`}
                              value={data && data[2].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleChange(e, 2, dataIndex + 2, true, 3, [], [2, 3])}
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 2}`}
                              value={data && data[2].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[2].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex + 2}`}
                              onChange={e => handleChange(e, 2, dataIndex + 2, true, 3)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[2].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[2].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 795)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 795}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex + 2}`}
                              value={data && data[2].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[2].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 4}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[3].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[3].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 3}`}
                            onChange={e => handleChange(e, 2, dataIndex + 3, true, 2, [], [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[3].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[3].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[3].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[3].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 111)}
                            onBlur={e => handleBlur(e, dataIndex + 3, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 111}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 3}`}
                            value={data && data[3].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[3].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 3}`}
                            value={data && data[3].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[3].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 3}`}
                            value={data && data[3].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[3].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 3}`}
                            value={data && data[3].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[3].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 3}`}
                              value={data && data[3].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[3].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex + 3}`}
                              value={data && data[3].inertBedHeight}
                              name='inertBedHeight'
                              placeholder={data && data[3].inertBedHeight}
                              defaultValue=''
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                              inputText={unit.selectedUnits[8]}
                              onChange={e => handleChange(e, 2, dataIndex + 3, true, 2, [], [2, 3])}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 3, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 3}`}
                              value={data && data[3].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[3].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex + 3}`}
                              name='vesselCylindricalHeight'
                              onChange={e => handleChange(e, 2, dataIndex + 3, true, 2)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[3].vesselCylindricalHeight}
                              placeholder={data && data[3].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 334)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 3, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 334}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex + 3}`}
                              value={data && data[3].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[3].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 5}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex + 1}`}
                            onChange={e => handleSpecialCase(e, 2, [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[2].vesselDiameter) ||
                              (data && data[2].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[2].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[2].vesselDiameter}
                            name='vesselDiameter'
                            placeholder={data && data[2].vesselDiameter}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 541)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "vesselDiameter")}
                            isFocused={isFocused === dataIndex + 541}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured1${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedHeightAsDelivered) +
                                  parseFloat(data[3].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured1"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured1${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedStandardHeight) + parseFloat(data[3].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured1'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured1${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedHeightAsRegenerated) +
                                  parseFloat(data[3].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured1'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured1${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedHeightAsExhausted) +
                                  parseFloat(data[3].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured1'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex + 2}`}
                              value={data && parseFloat(data[2].inertResinVolume)}
                              name='inertResinVolume'
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex + 2}`}
                              value={data && data[2].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleSpecialCase(e, 1, [2, 3])}
                              disabled={ixStore.anionResin === 13}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 2}`}
                              value={data && parseFloat(data[2].freeBoard)}
                              name='freeBoard'
                              placeholder={data && parseFloat(data[2].freeBoard)}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex + 2}`}
                            onChange={e => handleChange(e, 2, dataIndex + 2, true, 3, [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[2].vesselCylindricalHeight) +
                                      parseFloat(data[3].vesselCylindricalHeight),
                                  )
                                : data && data[2].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            disabled={ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 795)}
                            onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 795}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            onChange={e => handleSpecialCase(e, 2, [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={data[2].vesselWallThickness}
                            isError={
                              (data && data[2].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                              (data && data[2].vesselWallThickness > validations("vesselWallThickness")[1])
                            }
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 198)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "vesselWallThickness")}
                            isFocused={isFocused === dataIndex + 198}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].pressureDropwithRecomQty) +
                                  parseFloat(data[3].pressureDropwithRecomQty),
                              )
                            }
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              </>
            )}
          </div>
        ) : (ixStore.cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID ||
            ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID) &&
          (ixStore.anionResin === RESIN_ID.WBA_RESIN_ID || ixStore.anionResin === RESIN_ID.SBA_RESIN_ID) ? (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {data && data?.length > 1 && (
              <>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 1"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[0].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[0].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 1, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[0].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[0].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[0].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[0].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 121)}
                            onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 121}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex}`}
                            value={data && data[0].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[0].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex}`}
                            value={data && data[0].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[0].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex}`}
                            value={data && data[0].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[0].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex}`}
                            value={data && data[0].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[0].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[0].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[0].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleChange(e, 1, dataIndex, true, 1)}
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && data[0].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex}`}
                              onChange={e => handleChange(e, 1, dataIndex)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[0].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[0].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 237)}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 237}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex}`}
                              value={data && data[0].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[0].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 1}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[1].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[1].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 1}`}
                            onChange={e => handleChange(e, 1, dataIndex + 1, true, 0, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[1].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[1].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[1].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[1].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 341)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 341}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[1].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 1}`}
                            value={data && data[1].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[1].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[1].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[1].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 1}`}
                              value={data && data[1].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[1].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight2${dataIndex + 1}`}
                              value={data && data[1].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleChange(e, 1, dataIndex + 1, true, 0)}
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard2${dataIndex + 1}`}
                              value={data && data[1].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[1].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight2${dataIndex + 1}`}
                              onChange={e => handleChange(e, 1, dataIndex + 1)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[1].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[1].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 156)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 156}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty2${dataIndex + 1}`}
                              value={data && data[1].pressureDropwithRecomQty}
                              name='inertResinVolume'
                              placeholder={data && data[1].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 2}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex}`}
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[0].vesselDiameter) ||
                              (data && data[0].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[0].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[0].vesselDiameter}
                            name='vesselDiameter'
                            placeholder={data && data[0].vesselDiameter}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 867)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                            isFocused={isFocused === dataIndex + 867}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsDelivered) +
                                  parseFloat(data[1].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedStandardHeight) + parseFloat(data[1].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsRegenerated) +
                                  parseFloat(data[1].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsExhausted) +
                                  parseFloat(data[1].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[0].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[0].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleSpecialCase(e, 1, [0, 1])}
                              disabled={ixStore.cationResin === 7}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`freeBoard${dataIndex}`}
                            name='freeBoard'
                            value={data && parseFloat(data[0].freeBoard) + parseFloat(data[1].freeBoard)}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 0, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[0].vesselCylindricalHeight) +
                                      parseFloat(data[1].vesselCylindricalHeight),
                                  )
                                : data && data[0].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            disabled={ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 223)}
                            onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 223}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            isError={
                              (data && data[0].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                              (data && data[0].vesselWallThickness > validations("vesselWallThickness")[1])
                            }
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={data && data[0].vesselWallThickness}
                            placeholder={data && data[0].vesselWallThickness}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 461)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                            isFocused={isFocused === dataIndex + 461}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={data && data[0].pressureDropwithRecomQty}
                            placeholder={data && data[0].pressureDropwithRecomQty}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex + 3}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={"Vessel 2"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data[2].resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data[2].resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[2].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                            (data && data[2].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                          }
                          value={data[2].resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data[2].resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 801)}
                          onBlur={e => handleBlur(e, dataIndex + 2, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 801}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data[2].vesselDiameter) ||
                            data[2].vesselDiameter < validations("vesselDiameter")[0] ||
                            data[2].vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data[2].vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data[2].vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 991)}
                          onBlur={e => handleBlur(e, dataIndex + 2, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 991}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex + 2}`}
                          value={data[2].resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data[2].resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex + 2}`}
                          value={data[2].resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data[2].resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex + 2}`}
                          value={data[2].resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data[2].resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex + 2}`}
                          value={data[2].resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data[2].resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${dataIndex + 2}`}
                          value={data[2] && data[2].inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data[2].inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${dataIndex + 2}`}
                          value={data[2].inertBedHeight}
                          name='inertBedHeight'
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${dataIndex + 2}`}
                          value={data[2].freeBoard}
                          name='freeBoard'
                          placeholder={data[2].freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data[2].vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data[2].vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 391)}
                          onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 391}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[1].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                            (data && data[1].vesselWallThickness > validations("vesselWallThickness")[1])
                          }
                          value={data[2].vesselWallThickness}
                          name='vesselWallThickness'
                          placeholder={data[2].vesselWallThickness}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 771)}
                          onBlur={e => handleBlur(e, dataIndex + 2, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 771}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`pressureDropwithRecomQty${dataIndex + 2}`}
                          value={data[2].pressureDropwithRecomQty}
                          name='pressureDropwithRecomQty'
                          placeholder={data[2].pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </div>
        ) : (ixStore.cationResin === RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID ||
            ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID) &&
          ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID ? (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {data && data?.length > 2 && (
              <>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 1"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[0].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[0].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 1, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[0].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[0].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[0].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[0].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 11)}
                            onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 11}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex}`}
                            value={data && data[0].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[0].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex}`}
                            value={data && data[0].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[0].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex}`}
                            value={data && data[0].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[0].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex}`}
                            value={data && data[0].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[0].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[0].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[0].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              placeholder={data && data[0].inertBedHeight}
                              defaultValue=''
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && data[0].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex}`}
                              onChange={e => handleChange(e, 1, dataIndex)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[0].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[0].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 29)}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 29}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex}`}
                              value={data && data[0].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[0].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 1}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[1].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[1].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 1}`}
                            onChange={e => handleChange(e, 1, dataIndex + 1, true, 0, [], [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[1].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[1].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[1].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[1].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 561)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 561}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[1].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 1}`}
                            value={data && data[1].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[1].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[1].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 1}`}
                            value={data && data[1].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[1].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 1}`}
                              value={data && data[1].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[1].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight2${dataIndex + 1}`}
                              value={data && data[1].inertBedHeight}
                              name='inertBedHeight'
                              placeholder={data && data[1].inertBedHeight}
                              defaultValue=''
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard2${dataIndex + 1}`}
                              value={data && data[1].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[1].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight2${dataIndex + 1}`}
                              value={data && data[1].vesselCylindricalHeight}
                              onChange={e => handleChange(e, 1, dataIndex + 1)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              name='vesselCylindricalHeight'
                              placeholder={data && data[1].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 661)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 661}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty2${dataIndex + 1}`}
                              value={data && data[1].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[1].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 2}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex}`}
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[0].vesselDiameter) ||
                              (data && data[0].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[0].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[0].vesselDiameter}
                            name='vesselDiameter'
                            placeholder={data && data[0].vesselDiameter}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 341)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                            isFocused={isFocused === dataIndex + 341}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsDelivered) +
                                  parseFloat(data[1].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedStandardHeight) + parseFloat(data[1].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsRegenerated) +
                                  parseFloat(data[1].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].resinBedHeightAsExhausted) +
                                  parseFloat(data[1].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[0].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[0].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[0].inertBedHeight}
                              name='inertBedHeight'
                              disabled={ixStore.cationResin === 7}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onChange={e => handleSpecialCase(e, 1, [0, 1])}
                              onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && parseFloat(data[0].freeBoard)}
                              name='freeBoard'
                              placeholder={data && data[0].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex}`}
                            onChange={e => handleChange(e, 1, dataIndex, true, 0, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[0].vesselCylindricalHeight) +
                                      parseFloat(data[1].vesselCylindricalHeight),
                                  )
                                : data && data[0].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            placeholder={data && data[0].vesselCylindricalHeight}
                            defaultValue=''
                            disabled={ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 29)}
                            onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 29}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            onChange={e => handleSpecialCase(e, 1, [0, 1])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              data[0].vesselWallThickness < validations("vesselWallThickness")[0] ||
                              data[0].vesselWallThickness > validations("vesselWallThickness")[1]
                            }
                            value={data[0].vesselWallThickness}
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 471)}
                            onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                            isFocused={isFocused === dataIndex + 471}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[0].pressureDropwithRecomQty) +
                                  parseFloat(data[1].pressureDropwithRecomQty),
                              )
                            }
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex + 3}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={"Vessel 2"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data[2].resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data[2].resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[2].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                            (data && data[2].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                          }
                          value={data[2].resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data[2].resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 551)}
                          onBlur={e => handleBlur(e, dataIndex + 2, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 551}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data[2].vesselDiameter) ||
                            data[2].vesselDiameter < validations("vesselDiameter")[0] ||
                            data[2].vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data[2].vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data[2].vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 851)}
                          onBlur={e => handleBlur(e, dataIndex + 2, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 851}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex + 2}`}
                          value={data[2].resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data[2].resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex + 2}`}
                          value={data[2].resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data[2].resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex + 2}`}
                          value={data[2].resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data[2].resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex + 2}`}
                          value={data[2].resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data[2].resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${dataIndex + 2}`}
                          value={data[2] && data[2].inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data[2].inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${dataIndex + 2}`}
                          value={data[2].inertBedHeight}
                          name='inertBedHeight'
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                          inputText={unit.selectedUnits[8]}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          isError={false}
                          onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${dataIndex + 2}`}
                          value={data[2].freeBoard}
                          name='freeBoard'
                          placeholder={data[2].freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data[2].vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data[2].vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 777)}
                          onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 777}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex + 2}`}
                          onChange={e => handleChange(e, dataIndex + 2, dataIndex + 2)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[2].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                            (data && data[2].vesselWallThickness > validations("vesselWallThickness")[1])
                          }
                          value={data && data[2].vesselWallThickness}
                          name='vesselWallThickness'
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 567)}
                          onBlur={e => handleBlur(e, dataIndex + 2, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 567}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`pressureDropwithRecomQty${dataIndex + 2}`}
                          value={data[2].pressureDropwithRecomQty}
                          name='pressureDropwithRecomQty'
                          placeholder={data[2].pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex + 4}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={"Vessel 3"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data[3].resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data[3].resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex + 3}`}
                          onChange={e => handleChange(e, dataIndex + 3, dataIndex + 3)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[3].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                            (data && data[3].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                          }
                          value={data[3].resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data[3].resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 901)}
                          onBlur={e => handleBlur(e, dataIndex + 3, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 901}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex + 3}`}
                          onChange={e => handleChange(e, dataIndex + 3, dataIndex + 3)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data[3].vesselDiameter) ||
                            data[3].vesselDiameter < validations("vesselDiameter")[0] ||
                            data[3].vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data[3].vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data[3].vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 823)}
                          onBlur={e => handleBlur(e, dataIndex + 3, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 823}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex + 3}`}
                          value={data[3].resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data[3].resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex + 3}`}
                          value={data[3].resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data[3].resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex + 3}`}
                          value={data[3].resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data[3].resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex + 3}`}
                          value={data[3].resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data[3].resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${dataIndex + 3}`}
                          value={data[3] && data[3].inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data[3].inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${dataIndex + 3}`}
                          value={data[3].inertBedHeight}
                          name='inertBedHeight'
                          placeholder={data[3].inertBedHeight}
                          defaultValue=''
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 3)}
                          inputText={unit.selectedUnits[8]}
                          onChange={e => handleChange(e, dataIndex + 3, dataIndex + 3)}
                          isError={false}
                          onBlur={event => handleBlurHeight(event, dataIndex + 3, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${dataIndex + 3}`}
                          value={data[3].freeBoard}
                          name='freeBoard'
                          placeholder={data[3].freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex + 3}`}
                          onChange={e => handleChange(e, dataIndex + 3, dataIndex + 3)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data[3].vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data[3].vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 721)}
                          onBlur={event => handleBlurHeight(event, dataIndex + 3, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 721}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex + 3}`}
                          onChange={e => handleChange(e, dataIndex + 3, dataIndex + 3)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[3].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                            (data && data[3].vesselWallThickness > validations("vesselWallThickness")[1])
                          }
                          value={data[3].vesselWallThickness}
                          name='vesselWallThickness'
                          placeholder={data[3].vesselWallThickness}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 654)}
                          onBlur={e => handleBlur(e, dataIndex + 3, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 654}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`pressureDropwithRecomQty${dataIndex + 3}`}
                          value={data[3].pressureDropwithRecomQty}
                          name='pressureDropwithRecomQty'
                          placeholder={data[3].pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </div>
        ) : (ixStore.anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID ||
            ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID) &&
          (ixStore.cationResin === RESIN_ID.WAC_RESIN_ID || ixStore.cationResin === RESIN_ID.SAC_RESIN_ID) ? (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {data && data?.length > 1 && (
              <>
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={"Vessel 1"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data[0].resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data[0].resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[0].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                            (data && data[0].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                          }
                          value={data[0].resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data[0].resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 101)}
                          onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 101}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data[0].vesselDiameter) ||
                            data[0].vesselDiameter < validations("vesselDiameter")[0] ||
                            data[0].vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data[0].vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data[0].vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 201)}
                          onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 201}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex}`}
                          value={data[0].resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data[0].resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex}`}
                          value={data[0].resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data[0].resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex}`}
                          value={data[0].resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data[0].resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex}`}
                          value={data[0].resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data[0].resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${dataIndex}`}
                          value={data[0].inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data[0].inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${dataIndex}`}
                          value={data[0].inertBedHeight}
                          name='inertBedHeight'
                          onChange={e => handleChange(e, 1, dataIndex)}
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${dataIndex}`}
                          value={data[0].freeBoard}
                          name='freeBoard'
                          placeholder={data[0].freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data[0].vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data[0].vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 301)}
                          onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 301}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[0].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                            (data && data[0].vesselWallThickness > validations("vesselWallThickness")[1])
                          }
                          value={data[0].vesselWallThickness}
                          name='vesselWallThickness'
                          placeholder={data[0].vesselWallThickness}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 401)}
                          onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 401}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`pressureDropwithRecomQty${dataIndex}`}
                          value={data[0].pressureDropwithRecomQty}
                          name='pressureDropwithRecomQty'
                          placeholder={data[0].pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 3}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 2"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[1].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[1].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex}`}
                            onChange={e => handleChange(e, 2, dataIndex + 1, true, 1, [], [1, 2])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[1].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[1].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[1].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[1].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 501)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 501}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex}`}
                            value={data && data[1].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[1].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex}`}
                            value={data && data[1].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[1].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex}`}
                            value={data && data[1].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[1].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex}`}
                            value={data && data[1].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[1].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[1].inertResinVolume}
                              name='inertResinVolume'
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[1].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleChange(e, 2, dataIndex + 1, true, 1, [], [1, 2])}
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex}`}
                              value={data && data[1].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[1].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex}`}
                              onChange={e => handleChange(e, 2, dataIndex + 1, true, 1)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[1].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[1].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 11)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 11}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex}`}
                              value={data && data[1].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[1].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 4}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[2].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[2].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 1}`}
                            onChange={e => handleChange(e, 2, dataIndex + 2, true, 0, [], [1, 2])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[2].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[2].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[2].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[2].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(601)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "resinVolumeAsDelivered")}
                            isFocused={isFocused === 601}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 1}`}
                            value={data && data[2].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[2].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 1}`}
                            value={data && data[2].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[2].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 1}`}
                            value={data && data[2].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[2].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 1}`}
                            value={data && data[2].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[2].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 1}`}
                              value={data && data[2].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[2].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight2${dataIndex + 1}`}
                              value={data && data[2].inertBedHeight}
                              name='inertBedHeight'
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                              inputText={unit.selectedUnits[8]}
                              onChange={e => handleChange(e, 2, dataIndex + 2, true, 0, [], [1, 2])}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 1}`}
                              value={data && data[2].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[2].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex + 1}`}
                              name='vesselCylindricalHeight'
                              onChange={e => handleChange(e, 2, dataIndex + 2, true, 0)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[2].vesselCylindricalHeight}
                              placeholder={data && data[2].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 701)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 701}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex + 1}`}
                              name='pressureDropwithRecomQty'
                              value={data && data[2].pressureDropwithRecomQty}
                              placeholder={data && data[2].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 5}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex}`}
                            onChange={e => handleSpecialCase(e, 2, [1, 2])}
                            // onChange={e => handleChange(e, 2, dataIndex + 1, true, 1)}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[1].vesselDiameter) ||
                              (data && data[1].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[1].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[1].vesselDiameter}
                            name='vesselDiameter'
                            placeholder={data && data[1].vesselDiameter}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(801)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "vesselDiameter")}
                            isFocused={isFocused === 801}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[1].resinBedHeightAsDelivered) +
                                  parseFloat(data[2].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[1].resinBedStandardHeight) + parseFloat(data[2].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[1].resinBedHeightAsRegenerated) +
                                  parseFloat(data[2].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[1].resinBedHeightAsExhausted) +
                                  parseFloat(data[2].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex}`}
                              value={data && data[1].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[1].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex}`}
                              value={data && data[1].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleSpecialCase(e, 2, [1, 2])}
                              disabled={ixStore.anionResin === 13}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}

                      {/* <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr> */}
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`freeBoard${dataIndex}`}
                            name='freeBoard'
                            value={data && data[1].freeBoard + data && data[2].freeBoard}
                            placeholder={data && data[1].freeBoard + data && data[2].freeBoard}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                          />
                        </td>
                      </tr>
                      {/* {ixStore.anionResin === 13 ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vessalCylendricHightFeatured${dataIndex}`}
                              value={
                                data &&
                                parseFloat(data[1].vesselCylindricalHeight) +
                                  parseFloat(data[2].vesselCylindricalHeight)
                              }
                              name='vessalCylendricHightFeatured'
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex}`}
                              onChange={e => handleChange(e, 2, dataIndex + 1, true, 1)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[1].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[1].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 11)}
                              onBlur={e => handleBlur(e, dataIndex + 1, "vesselCylindricalHeight")}
                              isFocused={isFocused === dataIndex + 11}
                            />
                          </td>
                        </tr>
                      )} */}
                      {/* <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vessalCylendricHightFeatured${dataIndex}`}
                            value={
                              data &&
                              parseFloat(data[1].vesselCylindricalHeight) + parseFloat(data[2].vesselCylindricalHeight)
                            }
                            name='vessalCylendricHightFeatured'
                            defaultValue=''
                            disabled={ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr> */}

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex}`}
                            onChange={e => handleChange(e, 2, dataIndex + 1, true, 1, [1, 2])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[1].vesselCylindricalHeight) +
                                      parseFloat(data[2].vesselCylindricalHeight),
                                  )
                                : data && data[1].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            disabled={ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 223)}
                            onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 223}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            isError={
                              (data && data[1].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                              (data && data[1].vesselWallThickness > validations("vesselWallThickness")[1])
                            }
                            // onChange={e => handleChange(e, 2, dataIndex + 1, true, 1,[1,2])}
                            onChange={e => handleSpecialCase(e, 2, [1, 2])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={data && data[1].vesselWallThickness}
                            placeholder={data && data[1].vesselWallThickness}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(901)}
                            onBlur={e => handleBlur(e, dataIndex + 1, "vesselWallThickness")}
                            isFocused={isFocused === 901}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[1].pressureDropwithRecomQty) +
                                  parseFloat(data[2].pressureDropwithRecomQty),
                              )
                            }
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              </>
            )}
          </div>
        ) : (ixStore.anionResin === RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID ||
            ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID) &&
          ixStore.cationResin === RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID ? (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {data && data?.length > 3 && (
              <>
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={"Vessel 1"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data[0].resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data[0].resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[0].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                            (data && data[0].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                          }
                          value={data[0].resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data[0].resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 201)}
                          onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 201}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data[0].vesselDiameter) ||
                            data[0].vesselDiameter < validations("vesselDiameter")[0] ||
                            data[0].vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data[0].vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data[0].vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 301)}
                          onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 301}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex}`}
                          value={data[0].resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data[0].resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex}`}
                          value={data[0].resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data[0].resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex}`}
                          value={data[0].resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data[0].resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex}`}
                          value={data[0].resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data[0].resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${dataIndex}`}
                          value={data[0].inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data[0].inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${dataIndex}`}
                          value={data[0].inertBedHeight}
                          name='inertBedHeight'
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                          inputText={unit.selectedUnits[8]}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          isError={false}
                          onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${dataIndex}`}
                          value={data[0].freeBoard}
                          name='freeBoard'
                          placeholder={data[0].freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data[0].vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data[0].vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 401)}
                          onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 401}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex}`}
                          onChange={e => handleChange(e, 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[0].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                            (data && data[0].vesselWallThickness > validations("vesselWallThickness")[1])
                          }
                          value={data[0].vesselWallThickness}
                          name='vesselWallThickness'
                          placeholder={data[0].vesselWallThickness}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 501)}
                          onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 501}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`pressureDropwithRecomQty${dataIndex}`}
                          value={data[0].pressureDropwithRecomQty}
                          name='pressureDropwithRecomQty'
                          placeholder={data[0].pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex + 1}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={"Vessel 2"}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data[1].resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data[1].resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex + 1}`}
                          onChange={e => handleChange(e, 2, dataIndex + 1)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[1].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                            (data && data[1].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                          }
                          value={data[1].resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data[1].resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 601)}
                          onBlur={e => handleBlur(e, dataIndex + 1, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 601}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex + 1}`}
                          onChange={e => handleChange(e, 2, dataIndex + 1)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data[1].vesselDiameter) ||
                            data[1].vesselDiameter < validations("vesselDiameter")[0] ||
                            data[1].vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data[1].vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data[1].vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 701)}
                          onBlur={e => handleBlur(e, dataIndex + 1, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 701}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex + 1}`}
                          value={data[1].resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data[1].resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex + 1}`}
                          value={data[1].resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data[1].resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex + 1}`}
                          value={data[1].resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data[1].resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex + 1}`}
                          value={data[1].resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data[1].resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${dataIndex + 1}`}
                          value={data[1].inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data[1].inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${dataIndex + 1}`}
                          value={data[1].inertBedHeight}
                          name='inertBedHeight'
                          onChange={e => handleChange(e, 2, dataIndex + 1)}
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 2)}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${dataIndex + 1}`}
                          value={data[1].freeBoard}
                          name='freeBoard'
                          placeholder={data[1].freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex + 1}`}
                          onChange={e => handleChange(e, 2, dataIndex + 1)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data[1].vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data[1].vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 801)}
                          onBlur={event => handleBlurHeight(event, dataIndex + 1, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 801}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex + 1}`}
                          onChange={e => handleChange(e, 2, dataIndex + 1)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            (data && data[1].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                            (data && data[1].vesselWallThickness > validations("vesselWallThickness")[1])
                          }
                          value={data[1].vesselWallThickness}
                          name='vesselWallThickness'
                          placeholder={data[1].vesselWallThickness}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 901)}
                          onBlur={e => handleBlur(e, dataIndex + 1, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 901}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`pressureDropwithRecomQty${dataIndex + 1}`}
                          value={data[1].pressureDropwithRecomQty}
                          name='pressureDropwithRecomQty'
                          placeholder={data[1].pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <>
                  <Table className='vessel-wrapper' key={`key1${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 3}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={"Vessel 3"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[2].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRe.gular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[2].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered${dataIndex + 2}`}
                            onChange={e => handleChange(e, 3, dataIndex + 2, true, 3, [], [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[2].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[2].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[2].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[2].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 101)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 101}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered${dataIndex + 2}`}
                            value={data && data[2].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[2].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight${dataIndex + 2}`}
                            value={data && data[2].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[2].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated${dataIndex + 2}`}
                            value={data && data[2].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[2].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted${dataIndex + 2}`}
                            value={data && data[2].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[2].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex + 2}`}
                              value={data && data[2].inertResinVolume}
                              name='inertResinVolume'
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex + 2}`}
                              value={data && data[2].inertBedHeight}
                              name='inertBedHeight'
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 3)}
                              onChange={e => handleChange(e, 3, dataIndex + 2, true, 3, [], [2, 3])}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 2}`}
                              value={data && data[2].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[2].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex + 2}`}
                              onChange={e => handleChange(e, 3, dataIndex + 2, true, 3)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[2].vesselCylindricalHeight}
                              name='vesselCylindricalHeight'
                              placeholder={data && data[2].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 13)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 13}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex + 2}`}
                              value={data && data[2].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[2].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key2${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 4}`}>
                        <td className='vessel-count'>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='NotoSansRegular'
                            color={colors.Black}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label={data[3].resinName}
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={data[3].resinType}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`resinVolumeAsDelivered2${dataIndex + 3}`}
                            onChange={e => handleChange(e, 3, dataIndex + 3, true, 2, [], [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              (data && data[3].resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0]) ||
                              (data && data[3].resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1])
                            }
                            value={data && data[3].resinVolumeAsDelivered}
                            name='resinVolumeAsDelivered'
                            placeholder={data && data[3].resinVolumeAsDelivered}
                            disabled={false}
                            inputText={unit.selectedUnits[12]}
                            onFocus={() => handleFocus(dataIndex + 221)}
                            onBlur={e => handleBlur(e, dataIndex + 3, "resinVolumeAsDelivered")}
                            isFocused={isFocused === dataIndex + 221}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[12] === "m³"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[12] === "m³"
                                ? 60
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      60,
                                      unit.selectedUnits[12],
                                      "m³",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDelivered2${dataIndex + 3}`}
                            value={data && data[3].resinBedHeightAsDelivered}
                            name='resinBedHeightAsDelivered'
                            placeholder={data && data[3].resinBedHeightAsDelivered}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeight2${dataIndex + 3}`}
                            value={data && data[3].resinBedStandardHeight}
                            name='resinBedStandardHeight'
                            placeholder={data && data[3].resinBedStandardHeight}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegenerated2${dataIndex + 3}`}
                            value={data && data[3].resinBedHeightAsRegenerated}
                            name='resinBedHeightAsRegenerated'
                            placeholder={data && data[3].resinBedHeightAsRegenerated}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhausted2${dataIndex + 3}`}
                            value={data && data[3].resinBedHeightAsExhausted}
                            name='resinBedHeightAsExhausted'
                            placeholder={data && data[3].resinBedHeightAsExhausted}
                            defaultValue=''
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume2${dataIndex + 3}`}
                              value={data && data[3].inertResinVolume}
                              name='inertResinVolume'
                              placeholder={data && data[3].inertResinVolume}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex + 3}`}
                              value={data && data[3].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleChange(e, 3, dataIndex + 3, true, 2, [], [2, 3])}
                              disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 3)}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 3, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 3}`}
                              value={data && data[3].freeBoard}
                              name='freeBoard'
                              placeholder={data && data[3].freeBoard}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`vesselCylindricalHeight${dataIndex + 3}`}
                              name='vesselCylindricalHeight'
                              onChange={e => handleChange(e, 3, dataIndex + 3, true, 2)}
                              onKeyDown={evt =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                              }
                              value={data && data[3].vesselCylindricalHeight}
                              placeholder={data && data[3].vesselCylindricalHeight}
                              defaultValue=''
                              disabled={false}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onFocus={() => handleFocus(dataIndex + 3)}
                              onBlur={event => handleBlurHeight(event, dataIndex + 3, IXD_STRING.VESSEL_CYL_HEIGHT)}
                              isFocused={isFocused === dataIndex + 3}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`pressureDropwithRecomQty${dataIndex + 3}`}
                              value={data && data[3].pressureDropwithRecomQty}
                              name='pressureDropwithRecomQty'
                              placeholder={data && data[3].pressureDropwithRecomQty}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[3]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Table className='vessel-wrapper' key={`key3${dataIndex}`}>
                    <tbody>
                      <tr className='vessel-header' id={`borderColor${dataIndex + 5}`}>
                        <td className='vessel-count'></td>
                      </tr>
                      <tr>
                        <td>
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumSemiBold'
                            color={colors.blackTransparency085}
                            fontWeight='700'
                            label='Vessel Features'
                          />
                          <CustomHeading
                            fontSize='14px'
                            fontFamily='DiodrumRegular'
                            color={colors.blackTransparency085}
                            fontWeight='400'
                            label={""}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'></td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselDiameter${dataIndex + 1}`}
                            onChange={e => handleSpecialCase(e, 3, [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            isError={
                              !Number(data && data[2].vesselDiameter) ||
                              (data && data[2].vesselDiameter < validations("vesselDiameter")[0]) ||
                              (data && data[2].vesselDiameter > validations("vesselDiameter")[1])
                            }
                            value={data && data[2].vesselDiameter}
                            name='vesselDiameter'
                            placeholder={data && data[2].vesselDiameter}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 331)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "vesselDiameter")}
                            isFocused={isFocused === dataIndex + 331}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 5000
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      5000,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsDeliveredFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedHeightAsDelivered) +
                                  parseFloat(data[3].resinBedHeightAsDelivered),
                              )
                            }
                            name={"resinBedHeightAsDeliveredFeatured"}
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                            isAutoPopulated={false}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedStandardHeightFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedStandardHeight) + parseFloat(data[3].resinBedStandardHeight),
                              )
                            }
                            name='resinBedStandardHeightFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsRegeneratedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedHeightAsRegenerated) +
                                  parseFloat(data[3].resinBedHeightAsRegenerated),
                              )
                            }
                            name='resinBedHeightAsRegeneratedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <CalcEngineInputBox
                            type='number'
                            id={`resinBedHeightAsExhaustedFeatured${dataIndex}`}
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].resinBedHeightAsExhausted) +
                                  parseFloat(data[3].resinBedHeightAsExhausted),
                              )
                            }
                            name='resinBedHeightAsExhaustedFeatured'
                            disabled={true}
                            inputText={unit.selectedUnits[8]}
                          />
                        </td>
                      </tr>
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertResinVolume${dataIndex + 2}`}
                              value={data && data[2].inertResinVolume}
                              name='inertResinVolume'
                              disabled={true}
                              inputText={unit.selectedUnits[12]}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`inertBedHeight${dataIndex + 2}`}
                              value={data && data[2].inertBedHeight}
                              name='inertBedHeight'
                              onChange={e => handleSpecialCase(e, 1, [2, 3])}
                              disabled={ixStore.anionResin === 13}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                              onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.INERT_BED_HEIGHT)}
                            />
                          </td>
                        </tr>
                      )}
                      {ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID ? (
                        <tr>
                          <td className='vessel-outer-input'></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className='vessel-outer-input'>
                            <InputWithText
                              type='number'
                              id={`freeBoard${dataIndex + 2}`}
                              value={data && parseFloat(data[2].freeBoard)}
                              name='freeBoard'
                              placeholder={data && parseFloat(data[2].freeBoard)}
                              defaultValue=''
                              disabled={true}
                              inputText={unit.selectedUnits[8]}
                              isError={false}
                            />
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselCylindricalHeight${dataIndex + 2}`}
                            onChange={e => handleChange(e, 3, dataIndex + 2, true, 3, [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={
                              ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID
                                ? roundToFourDecimals(
                                    parseFloat(data[2].vesselCylindricalHeight) +
                                      parseFloat(data[3].vesselCylindricalHeight),
                                  )
                                : data && data[2].vesselCylindricalHeight
                            }
                            name='vesselCylindricalHeight'
                            disabled={ixStore.anionResin === RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID}
                            inputText={unit.selectedUnits[8]}
                            isError={false}
                            onFocus={() => handleFocus(dataIndex + 13)}
                            onBlur={event => handleBlurHeight(event, dataIndex + 2, IXD_STRING.VESSEL_CYL_HEIGHT)}
                            isFocused={isFocused === dataIndex + 13}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`vesselWallThickness${dataIndex}`}
                            name='vesselWallThickness'
                            onChange={e => handleSpecialCase(e, 3, [2, 3])}
                            onKeyDown={evt =>
                              ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                            }
                            value={data[2].vesselWallThickness}
                            isError={
                              (data && data[2].vesselWallThickness < validations("vesselWallThickness")[0]) ||
                              (data && data[2].vesselWallThickness > validations("vesselWallThickness")[1])
                            }
                            placeholder={data && data[2].vesselWallThickness}
                            defaultValue=''
                            disabled={false}
                            inputText={unit.selectedUnits[8]}
                            onFocus={() => handleFocus(dataIndex + 441)}
                            onBlur={e => handleBlur(e, dataIndex + 2, "vesselWallThickness")}
                            isFocused={isFocused === dataIndex + 441}
                          />
                          <InputReferenceText
                            className='error'
                            refText={`Ranges ${
                              unit.selectedUnits[8] === "mm"
                                ? 0
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      0,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            } - ${
                              unit.selectedUnits[8] === "mm"
                                ? 100
                                : Number(
                                    GlobalUnitConversion(
                                      GlobalUnitConversionStore,
                                      100,
                                      unit.selectedUnits[8],
                                      "mm",
                                    )?.toFixed(2),
                                  )
                            }`}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='vessel-outer-input'>
                          <InputWithText
                            type='number'
                            id={`pressureDropwithRecomQty${dataIndex}`}
                            name='pressureDropwithRecomQty'
                            value={
                              data &&
                              roundToFourDecimals(
                                parseFloat(data[2].pressureDropwithRecomQty) +
                                  parseFloat(data[3].pressureDropwithRecomQty),
                              )
                            }
                            placeholder={data && data[2].pressureDropwithRecomQty}
                            disabled={true}
                            inputText={unit.selectedUnits[3]}
                            isError={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              </>
            )}
          </div>
        ) : (
          <div className='main-div-container1'>
            <Table className='new-existing-plant-design'>
              <tbody>
                <tr>
                  <td className='main-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='NotoSansRegular'
                      color={colors.PrimaryDarkAquaMarine}
                      fontWeight='400'
                      label='Adjustment Parameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='blank'>-blank</td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin volume, as delivered'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Outer Diameter'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Resin Bed Height as delivered (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Reference Height (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as regenerated (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='as exhausted (calc.)'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Resin Volume'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Inert Bed Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Free Board'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Cylindrical Height'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Vessel Wall Thickness'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='header-title'>
                    <CustomHeading
                      fontSize='14px'
                      fontFamily='DiodrumRegular'
                      color={colors.blackTransparency085}
                      fontWeight='400'
                      label='Pressure Drop with recommended quantity'
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {ixStoreObj?.listFinalParamAdj &&
              ixStoreObj?.listFinalParamAdj?.length > 0 &&
              ixStoreObj?.listFinalParamAdj.map((data, dataIndex) => (
                <Table className='vessel-wrapper' key={dataIndex}>
                  <tbody>
                    <tr className='vessel-header' id={`borderColor${dataIndex}`}>
                      <td className='vessel-count'>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='NotoSansRegular'
                          color={colors.Black}
                          fontWeight='400'
                          label={`Vessel ${dataIndex + 1}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumSemiBold'
                          color={colors.blackTransparency085}
                          fontWeight='700'
                          label={data.resinName}
                        />
                        <CustomHeading
                          fontSize='14px'
                          fontFamily='DiodrumRegular'
                          color={colors.blackTransparency085}
                          fontWeight='400'
                          label={data.resinType}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`resinVolumeAsDelivered${dataIndex + 1}`}
                          onChange={e => handleChange(e, dataIndex + 1, dataIndex)}
                          isError={
                            data.resinVolumeAsDelivered < validations("resinVolumeAsDelivered")[0] ||
                            data.resinVolumeAsDelivered > validations("resinVolumeAsDelivered")[1]
                          }
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data.resinVolumeAsDelivered}
                          name='resinVolumeAsDelivered'
                          placeholder={data.resinVolumeAsDelivered}
                          disabled={false}
                          inputText={unit.selectedUnits[12]}
                          onFocus={() => handleFocus(dataIndex + 401)}
                          onBlur={e => handleBlur(e, dataIndex, "resinVolumeAsDelivered")}
                          isFocused={isFocused === dataIndex + 401}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[12] === "m³"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[12] === "m³"
                              ? 60
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    60,
                                    unit.selectedUnits[12],
                                    "m³",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselDiameter${dataIndex + 1}`}
                          onChange={e => handleChange(e, dataIndex + 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            !Number(data.vesselDiameter) ||
                            data.vesselDiameter < validations("vesselDiameter")[0] ||
                            data.vesselDiameter > validations("vesselDiameter")[1]
                          }
                          value={data.vesselDiameter}
                          name='vesselDiameter'
                          placeholder={data.vesselDiameter}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 435)}
                          onBlur={e => handleBlur(e, dataIndex, "vesselDiameter")}
                          isFocused={isFocused === dataIndex + 435}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 5000
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    5000,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsDelivered${dataIndex + 1}`}
                          value={data.resinBedHeightAsDelivered}
                          name='resinBedHeightAsDelivered'
                          placeholder={data.resinBedHeightAsDelivered}
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isAutoPopulated={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedStandardHeight${dataIndex + 1}`}
                          value={data.resinBedStandardHeight}
                          name='resinBedStandardHeight'
                          placeholder={data.resinBedStandardHeight}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsRegenerated${dataIndex + 1}`}
                          value={data.resinBedHeightAsRegenerated}
                          name='resinBedHeightAsRegenerated'
                          placeholder={data.resinBedHeightAsRegenerated}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <CalcEngineInputBox
                          type='number'
                          id={`resinBedHeightAsExhausted${dataIndex + 1}`}
                          value={data.resinBedHeightAsExhausted}
                          name='resinBedHeightAsExhausted'
                          placeholder={data.resinBedHeightAsExhausted}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertResinVolume${data.dataIndex}`}
                          value={data.inertResinVolume}
                          name='inertResinVolume'
                          placeholder={data.inertResinVolume}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[12]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`inertBedHeight${data.dataIndex}`}
                          value={data.inertBedHeight}
                          name='inertBedHeight'
                          disabled={!isInertCalculationRequired(calculationRequiredData, dataIndex + 1)}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onChange={e => handleChange(e, dataIndex + 1, dataIndex)}
                          onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.INERT_BED_HEIGHT)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`freeBoard${data.dataIndex}`}
                          value={data.freeBoard}
                          name='freeBoard'
                          placeholder={data.freeBoard}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselCylindricalHeight${dataIndex}`}
                          onChange={e => handleChange(e, dataIndex + 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          value={data.vesselCylindricalHeight}
                          name='vesselCylindricalHeight'
                          placeholder={data.vesselCylindricalHeight}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          isError={false}
                          onFocus={() => handleFocus(dataIndex + 473)}
                          onBlur={event => handleBlurHeight(event, dataIndex, IXD_STRING.VESSEL_CYL_HEIGHT)}
                          isFocused={isFocused === dataIndex + 473}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='number'
                          id={`vesselWallThickness${dataIndex}`}
                          onChange={e => handleChange(e, dataIndex + 1, dataIndex)}
                          onKeyDown={evt =>
                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                          }
                          isError={
                            data.vesselWallThickness < validations("vesselWallThickness")[0] ||
                            data.vesselWallThickness > validations("vesselWallThickness")[1]
                          }
                          value={data.vesselWallThickness}
                          name='vesselWallThickness'
                          placeholder={data.vesselWallThickness}
                          defaultValue=''
                          disabled={false}
                          inputText={unit.selectedUnits[8]}
                          onFocus={() => handleFocus(dataIndex + 424)}
                          onBlur={e => handleBlur(e, dataIndex, "vesselWallThickness")}
                          isFocused={isFocused === dataIndex + 424}
                        />
                        <InputReferenceText
                          className='error'
                          refText={`Ranges ${
                            unit.selectedUnits[8] === "mm"
                              ? 0
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    0,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          } - ${
                            unit.selectedUnits[8] === "mm"
                              ? 100
                              : Number(
                                  GlobalUnitConversion(
                                    GlobalUnitConversionStore,
                                    100,
                                    unit.selectedUnits[8],
                                    "mm",
                                  )?.toFixed(2),
                                )
                          }`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='vessel-outer-input'>
                        <InputWithText
                          type='text'
                          name='pressureDropwithRecomQty'
                          id={`pressureDropwithRecomQty${dataIndex}`}
                          placeholder={data?.pressureDropwithRecomQty}
                          value={data?.pressureDropwithRecomQty}
                          defaultValue=''
                          disabled={true}
                          inputText={unit.selectedUnits[3]}
                          isError={false}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              ))}
          </div>
        )}

        <div className='radio-wrapper'>
          <div className='vessel-resin'>
            <CustomRadio
              type='radio'
              label='Use Vessel Geometry and Resin Volume values'
              isError={false}
              name='vesselGeometry'
              id='useVesselGeometry'
              disabled={false}
              onClick={finalParameterRadio}
              checked={ixStoreObj.listFinalParamAdj[0]?.ixfpaRadioButtonID === 1}
              value={1}
            />
          </div>
          <div className='ignore-resin'>
            <CustomRadio
              type='radio'
              label='Use Vessel Geometry values, Ignore Resin Volume values'
              isError={false}
              name='vesselGeometry'
              id='ignoreResin'
              disabled={true}
              onClick={finalParameterRadio}
              checked={ixStoreObj.listFinalParamAdj[0]?.ixfpaRadioButtonID === 2}
              value={2}
            />
          </div>
          <div className='ignore-vessel'>
            <CustomRadio
              type='radio'
              label='Ignore Vessel Geometry and Resin Volume values'
              isError={false}
              name='vesselGeometry'
              id='ignoreVessel'
              disabled={false}
              onClick={finalParameterRadio}
              checked={ixStoreObj.listFinalParamAdj[0]?.ixfpaRadioButtonID === 3}
              value={3}
            />
          </div>
        </div>
        {errorOperator.show && (
          <ProjectErrorPopup show={errorOperator.show} close={closeErrorMessag} message={errorOperator.message} />
        )}
      </FinalParameterAdjustmentStyled>
    </>
  );
};

export default FinalParameterAdjustment;
