import { useDispatch, useSelector } from "react-redux";
import { getCases, getDefaultCurrency, getProjectinfo, getPumps, getUnitOfMeasure } from "src/api";

import { truncateText } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import {
  updateCaseConfig,
  updateProjectCurrency,
  updateProjectInfo,
  updateProjectInfoState,
  updateProjectTitle,
  updatePumpList,
  updateUnitConfig,
} from "@common/ProjectInfoSlice";

export const useGetProjectInit = () => {
  const Logger = DupontLogger("useProjectInit");
  const dispatch = useDispatch();
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const ProjectInfo = useSelector(state => state.projectInfo);

  const { UserId: userID } = UserInfoStore;
  const { projectID = 0 } = ProjectInfoStore;

  const getProjectinfoData = async () => {
    try {
      const { data } = await getProjectinfo({ userID, projectID });
      dispatch(updateProjectInfoState({ type: "projectData", data }));

      const { projectName } = data.projectInfoVM;
      const { caseID } =
        ProjectInfoStore.caseID === 0 || ProjectInfoStore.caseID === undefined
          ? data.projectInfoCaseVM
          : ProjectInfoStore;
      const { caseName } =
        ProjectInfoStore.caseID === 0 || ProjectInfoStore.caseID === undefined ? data.projectInfoCaseVM : ProjectInfo;
      const title = `${truncateText(projectName + " - " + caseName)}`;
      dispatch(updateProjectInfo({ ...ProjectInfoStore, caseId: caseID, projectID }));
      dispatch(updateProjectTitle(title));

      return data;
    } catch (error) {
      Logger.error("Error Project info api:", error);
    }
  };

  const getUnitOfMeasureData = async () => {
    try {
      const { data } = await getUnitOfMeasure({ userID, projectID });
      dispatch(updateUnitConfig(data));
      return data;
    } catch (error) {
      Logger.error("Error Unit of Measure api:", error);
    }
  };

  const getPumpsData = async () => {
    try {
      const { data } = await getPumps({ userID, projectID });
      dispatch(updatePumpList(data));
    } catch (error) {
      Logger.error("Error Pumps api:", error);
    }
  };

  const getCaseTypeData = async () => {
    try {
      const { data } = await getCases({ projectID });
      dispatch(updateCaseConfig(data));
    } catch (error) {
      Logger.error("Error Case Type api:", error);
    }
  };

  const getDefaultCurrencyData = async () => {
    try {
      const { data } = await getDefaultCurrency({ userID, projectID });
      dispatch(updateProjectCurrency(data));
    } catch (error) {
      Logger.error("Error Default Currency api:", error);
    }
  };

  // Initialize all the project data required but not no need to wait for all the data to be fetched
  const initProjectApis = () => {
    getPumpsData();
    getCaseTypeData();
    getDefaultCurrencyData();
  };

  return {
    getProjectinfoData,
    getUnitOfMeasureData,
    getDefaultCurrencyData,
    initProjectApis,
  };
};
