import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useReport } from "@hooks/useReport";

import DynamicLoadder from "@common/utils/DynamicLoadder";

import { WPReportContent } from "./common/WPReportContent";
import { WPReportSideNav } from "./common/WPReportSideNav";
import { useUfReport } from "./uf/useUfReport";

import "./WPReport.scss";

export const WPReport = () => {
  const { ufCalcReportApiResp } = useSelector(state => state.report);
  const { unitConfig, currencyConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { getUfReportData, isUfReportLoading } = useReport();
  const { convertUfCalcReportToTableData } = useUfReport();

  useEffect(() => {
    getUfReportData();
  }, []);

  useEffect(() => {
    convertUfCalcReportToTableData();
  }, [unitConfig.selectedUnits, ufCalcReportApiResp, currencyConfig]);

  return (
    <DynamicLoadder isLoading={isUfReportLoading}>
      <div className='report-wrapper app-max-width'>
        <div className='report-sidenav-wrapper'>
          <div className='report-sidenav-content'>
            <WPReportSideNav />
          </div>
        </div>
        <div className='report-content-wrapper'>
          <div className='report-content'>
            <WPReportContent />
          </div>
        </div>
      </div>
    </DynamicLoadder>
  );
};

export default WPReport;
