import React from "react";

import { CustomModalButtons } from "@constants/global.constants";

import { combineClassNames } from "@utils/appUtils";

import IconButton from "@components/IconButton";
import CloseIcon from "@components/SVGs/CloseIcon";
import WPButton from "@components/WPButton";
import WPHeading from "@components/WPHeading";

import CircularLoader from "@common/styles/components/circularLoader";
import { colors } from "@common/styles/Theme";

import StyledCustomModal from "./StyledCustomModal";

const CustomModal = ({
  header,
  headerHelper,
  confirmBtn,
  confirmBtnDisabled,
  cancelBtn,
  children,
  onModalClose,
  customclass,
  width,
  cancelBtnDisabled,
  isLoading,
  loadingText,
  cancelBtnType,
  confirmBtnType,
  size,
  invisible,
  modalFooter,
  hideCloseIcon,
}) => {
  const handleBtnClick = id => {
    if (onModalClose) {
      onModalClose({ target: { id } });
    }
  };

  return (
    <StyledCustomModal
      show
      centered
      className={combineClassNames(customclass, invisible && "invisible")}
      width={width}
      size={size}
      backdrop={!invisible}
      scrollable
    >
      {isLoading && (
        <div className='loading-overlay'>
          <div className='spinner'>
            <CircularLoader />
            {loadingText && <div className='loading-text'>{loadingText}</div>}
          </div>
        </div>
      )}
      <StyledCustomModal.Header>
        <div>
          <WPHeading>{header}</WPHeading>
          <WPHeading className='subHeading' size='small' secondary color={colors.blackTransparency045}>
            {headerHelper}
          </WPHeading>
        </div>
        {!hideCloseIcon && (
          <IconButton id='modal-close-icon' tooltip='Close' onClick={() => handleBtnClick(CustomModalButtons.CLOSE)}>
            <CloseIcon />
          </IconButton>
        )}
      </StyledCustomModal.Header>
      <StyledCustomModal.Body>
        <div className='modal-body-container'>{children}</div>
      </StyledCustomModal.Body>
      <StyledCustomModal.Footer>
        {cancelBtn && (
          <WPButton
            type={cancelBtnType}
            variant='outlined'
            id={CustomModalButtons.cancelBtn}
            onClick={() => handleBtnClick(CustomModalButtons.CANCEL)}
            disabled={cancelBtnDisabled}
          >
            {cancelBtn}
          </WPButton>
        )}
        {confirmBtn && (
          <WPButton
            type={confirmBtnType}
            disabled={confirmBtnDisabled}
            id={CustomModalButtons.CONFIRM}
            onClick={() => handleBtnClick(CustomModalButtons.CONFIRM)}
          >
            {confirmBtn}
          </WPButton>
        )}
        {modalFooter}
      </StyledCustomModal.Footer>
    </StyledCustomModal>
  );
};

export default CustomModal;
