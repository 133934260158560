import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ChemAdjustmentDBFieldsMapping,
  CHEMICAL_CONCENTRATION,
  CHEMICAL_MEASUREMENT_TABLE_ROWS,
  ChemicalAdjustmentDefaultValues,
  ChemicalMeasurementTableHeaders,
  ChemicalTypesMappig,
} from "@constants/chemicalAdjustment.constant";
import { CustomModalButtons } from "@constants/global.constants";
import { UNITS } from "@constants/units.constant";

import { useChemicalAdjustment } from "@hooks/useChemicalAdjustment";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUpto2Digits, convertUptoDigits } from "@utils/appUtils";

import CustomModal from "@components/CustomModal";
import CustomTable from "@components/CustomTable";

import ArrowDownIconTeal from "@common/assets/images/ArrowDown_Teal.svg";
import ArrowDownBlackIcon from "@common/icons/ArrowDownBlackIcon";
import RightLongArrowPhIcon from "@common/icons/RightLongArrowPhIcon";
import TechButtons from "@common/styles/components/buttons/techButtons/TechButtons";
import CustomHeading from "@common/styles/components/headings/CustomHeading";

import { COMPANY_CODE } from "../constants/UFConstants";
import { setUFChemicalAdjustment } from "../UFSlice";

import ChemicalAdjustmentTemperatureCard from "./ChemicalAdjustmentTemperatureCard";
import ChemicalCard from "./ChemicalAjustmentCard";
import { CogulantValidations, LSIValidations, OxidantValidations, PhValidations } from "./ChemicalAjustmentLimit";
import StyledChemicalAdjustment from "./styled";

const AfterAdjustmentCol = {
  field: "afterAdjustment",
  label: (
    <span className='header_label--container'>
      <CustomHeading label='After' fontFamily='NotoSansSemiBold' lineHeight='21px' />
      <span style={{ marginLeft: 4 }}>
        <ArrowDownBlackIcon />
      </span>
      <CustomHeading label='pH' fontFamily='NotoSansSemiBold' lineHeight='21px' />
    </span>
  ),
  colWidth: 150,
};

const phUpDown = {
  label: "LSI",
  isPhUp: false,
  id: "lsiSdi",
  value: 0,
  icon: <img src={ArrowDownIconTeal} className='header_icon' />,
  disabled: false,
};

const ChemicalAdjustmentModal = ({ close }) => {
  const dispatch = useDispatch();

  const { unitConversionByName } = useUnitConversion();

  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const { pUFTechnologyID } = useSelector(state => state.UFStore.data);

  const {
    isSDI,
    temperatureData,
    isPhDownEnabled,
    chemicalListByCategory,
    chemicalListById,
    isLsiSdiEnabled,
    getChemicalDoseValue,
    formatChemicalDataForUF,
    calculateChemicalAdjustment,
    saveUFChemicalAdjustmentData,
  } = useChemicalAdjustment();

  const { ufChemicalAdjustment } = useSelector(state => state.UFStore);

  const [ispHToggleChecked, setIspHToggleChecked] = useState(false);
  const [isOxidantToggleChecked, setIsOxidantToggleChecked] = useState(false);
  const [isCoagToggleChecked, setCoagToggleChecked] = useState(false);
  const [phDownDetails, setPhDownDetails] = useState({});
  const [oxidentDetails, setOxidentDetails] = useState({});
  const [coagulantDetails, setCoagulantDetails] = useState({});
  const [phConcentration, setPhConcentration] = useState(0);
  const [beforeAdjustmentApiRespData, setBeforeAdjustmentApiRespData] = useState(null);
  const [afterAdjustmentData, setAfterAdjustmentData] = useState(null);
  const [phDownInputData, setPhDownInputData] = useState(phUpDown);
  const [designTempInput, setDesignTempInput] = useState(null);
  const [mesuremenTableData, setMesuremenTableData] = useState({ headers: [], data: [] });

  const errorMapping = {
    pH_input: "isPhError",
    lsiSdi_input: "isSDIError",
    Oxidant_input: "isOxidantError",
    Coagulant_input: "isCoagulantError",
    temp_input: "isTempError",
  };
  const [errors, setErrors] = useState({
    isPhError: false,
    isSDIError: false,
    isOxidantError: false,
    isCoagulantError: false,
    isTempError: false,
  });

  useEffect(() => {
    if (isSDI) {
      phUpDown.label = "SDSI";
    } else {
      phUpDown.label = "LSI";
    }
    phUpDown.disabled = !isLsiSdiEnabled();
  }, [isSDI]);

  const updateErrors = obj => setErrors({ ...errors, ...obj });

  const fetchBeforeAndAfterAdjustmentData = () => {
    const {
      data: { phDown, oxidant, coagulant },
      feedData: { feed_water },
    } = ufChemicalAdjustment;
    setBeforeAdjustmentApiRespData(feed_water);
    parsePhDownData(phDown, feed_water);
    if (oxidant) {
      setIsOxidantToggleChecked(true);
      setOxidentDetails(oxidant);
    } else {
      updateErrors({ isOxidantError: false });
    }
    if (coagulant) {
      setCoagToggleChecked(true);
      setCoagulantDetails(coagulant);
    } else {
      updateErrors({ isCoagulantError: false });
    }
  };

  useEffect(() => {
    fetchBeforeAndAfterAdjustmentData();
  }, []);

  const parsePhDownData = (phDown, beforeAdjustmentResp) => {
    if (phDown && isPhDownEnabled) {
      const phData = {
        chemicalId: phDown.chemicalId,
        value: phDown.ph || "",
        lsiSdi: phDown.lsiSdi,
        designTemp: beforeAdjustmentResp?.designTemp,
      };
      onPhDownToggle(true, phData);
      setPhDownDetails(phData);
      setIspHToggleChecked(true);
      setPhDownInputData({ ...phUpDown, value: phDown.lsiSdi || "" });
    } else {
      onPhDownToggle(false);
    }
  };

  const onPhDownToggle = (isChecked, existingData) => {
    setIspHToggleChecked(isChecked);
    let headers = ChemicalMeasurementTableHeaders.filter(header => header.field !== "afterAdjustment");
    let data = mesuremenTableData.data || [];

    const newPhDetails = { chemicalId: null, value: "", lsiSdi: "" };

    if (isChecked) {
      headers = [...headers, AfterAdjustmentCol];
      data = data.map(row => ({ ...row, afterAdjustment: "0.00" }));
      newPhDetails.value = existingData?.value;
      newPhDetails.lsiSdi = existingData?.lsiSdi;
    } else {
      data = data.map(row => {
        delete row.afterAdjustment;
        return row;
      });
      setAfterAdjustmentData(null);
      updateErrors({ isPhError: false });
    }
    setPhDownDetails(newPhDetails);
    setMesuremenTableData({ data, headers });
  };

  const convertNumber = value => (value !== "NaN" ? convertUpto2Digits(value) : value);

  const parseMeasurement = (beforeAdjustmentData, afterAdjustmentData) => {
    const beforeAdjustmentKey = beforeAdjustmentData ? "beforeAdjustment" : null;
    const afterAdjustmentKey = afterAdjustmentData ? "afterAdjustment" : null;
    return CHEMICAL_MEASUREMENT_TABLE_ROWS.map(item => ({
      ...item,
      ...(beforeAdjustmentKey && { [beforeAdjustmentKey]: convertNumber(beforeAdjustmentData[item.id]) }),
      ...(afterAdjustmentKey && { [afterAdjustmentKey]: convertNumber(afterAdjustmentData[item.id]) }),
    }));
  };

  useEffect(() => {
    const afterAdjustmentChemicals = afterAdjustmentData?.chemicalAdjustment?.[0] || null;
    const beforeAdjustmentChemicals = beforeAdjustmentApiRespData?.chemicalAdjustment?.[0] || null;
    const data = parseMeasurement(beforeAdjustmentChemicals, afterAdjustmentChemicals);
    setMesuremenTableData(oldState => ({ ...oldState, data }));
  }, [beforeAdjustmentApiRespData, afterAdjustmentData]);

  const getTempInCelsius = value => {
    if (unitConfig.selectedUnits[2] === UNITS.celsius) return value;
    return unitConversionByName(value, UNITS.celsius, unitConfig.selectedUnits[2]);
  };

  useEffect(() => {
    if (designTempInput) {
      calculateAdjustment();
    }
  }, [designTempInput]);

  const calculateAdjustment = async (data, beforeAdjustmentResp) => {
    const beforeAdjustmentData = beforeAdjustmentResp || beforeAdjustmentApiRespData;

    const chemicalId = data?.chemicalId || phDownDetails.chemicalId;
    const newPh = data?.value || phDownDetails.value || 0;
    const newLsiDsi = data?.lsiSdi || phDownDetails.lsiSdi || 0;
    const designTemp = data?.designTemp || designTempInput;
    if (beforeAdjustmentData && (newPh >= 0 || newLsiDsi) && chemicalId && designTemp !== null) {
      const chemical = chemicalListById[chemicalId] || {};

      const payload = {
        ph: newPh || "0",
        addReagent: ChemicalTypesMappig[chemical.name],
        LSITarg: isSDI || newPh > 0 ? "" : newLsiDsi,
        SDITarg: !isSDI || newPh > 0 ? "" : newLsiDsi,
        designTemp: +getTempInCelsius(designTemp),
      };

      const response = await calculateChemicalAdjustment(payload, beforeAdjustmentResp);
      if (response && response.data) {
        const { addReagent } = response.data;
        let phConcentration = CHEMICAL_CONCENTRATION[chemical.symbol] * addReagent * 1000;
        phConcentration = convertUpto2Digits(phConcentration);
        setPhConcentration(phConcentration);
        setAfterAdjustmentData(response.data);
      }
    }
  };

  const onToggleChange = event => {
    let newVal;
    switch (event.target.id) {
      case "pH":
        onPhDownToggle(!ispHToggleChecked);
        if (!ispHToggleChecked) {
          updateErrors({ isPhError: false });
        }
        break;
      case "Oxidant":
        newVal = !isOxidantToggleChecked;
        setIsOxidantToggleChecked(newVal);
        if (!newVal) {
          setOxidentDetails({ chemicalId: null, value: "" });
          updateErrors({ isOxidantError: false });
        }
        break;
      case "Coagulant":
        newVal = !isCoagToggleChecked;
        setCoagToggleChecked(newVal);
        if (!newVal) {
          setCoagulantDetails({ chemicalId: null, value: "" });
          updateErrors({ isCoagulantError: false });
        }
        break;
    }
  };

  const getSaveChemicalAdjustmentPayload = () => {
    const createChemicalPayload = (details, includeLsiSdi = false) => {
      if (!details || !details.chemicalId) return null;
      const chemicalId = details.chemicalId;
      const value = details.value;
      let payload = { chemicalId };
      if (includeLsiSdi) {
        const id = details.value
          ? ChemAdjustmentDBFieldsMapping.pH
          : isSDI
            ? ChemAdjustmentDBFieldsMapping.SDI
            : ChemAdjustmentDBFieldsMapping.LSI;
        payload.adjTargetTypeId = id;
        payload.ph = details?.value?.toString() || "0";
        payload.lsiSdi = details.lsiSdi?.toString() || "0";
      } else {
        payload = { ...payload, value };
      }
      return payload;
    };

    return {
      phDown: ispHToggleChecked ? createChemicalPayload(phDownDetails, true) : null,
      oxidant: isOxidantToggleChecked ? createChemicalPayload(oxidentDetails) : null,
      coagulant: isCoagToggleChecked ? createChemicalPayload(coagulantDetails) : null,
    };
  };

  const getAdjustmentData = () =>
    afterAdjustmentData && beforeAdjustmentApiRespData
      ? {
          beforePh: `${beforeAdjustmentApiRespData?.chemicalAdjustment[0].pH || ""}`,
          afterPh: `${afterAdjustmentData?.chemicalAdjustment[0].pH || ""}`,
          beforeTds: `${beforeAdjustmentApiRespData?.chemicalAdjustment[0].TDS || ""}`,
          afterTds: `${afterAdjustmentData?.chemicalAdjustment[0].TDS || ""}`,
        }
      : null;

  const handleModalClose = event => {
    const { id } = event.target;
    if (id === CustomModalButtons.CONFIRM) {
      const adjustment = getAdjustmentData();
      let chemicalAdjustment = null;
      const data = getSaveChemicalAdjustmentPayload();
      if (afterAdjustmentData) {
        chemicalAdjustment = formatChemicalDataForUF(afterAdjustmentData);
      }
      saveUFChemicalAdjustmentData(data);
      const feedData = { feed_water: beforeAdjustmentApiRespData, adjusted_water: afterAdjustmentData };
      dispatch(setUFChemicalAdjustment({ data, chemicalAdjustment, phConcentration, adjustment, feedData }));
    }
    close();
  };

  const handleBlur = event => {
    const { id, value } = event.target;
    const adjustmentMap = {
      pH_input: { value: value },
      lsiSdi_input: { lsiSdi: value },
      temp_input: { designTemp: value },
    };
    if (adjustmentMap[id]) {
      calculateAdjustment(adjustmentMap[id]);
    }
  };

  const onPhDownChange = value => {
    const newPhDetails = {
      ...phDownDetails,
      chemicalId: value.id,
      value: +phDownDetails?.value || ChemicalAdjustmentDefaultValues.phDown,
    };
    setPhDownDetails(newPhDetails);
    calculateAdjustment(newPhDetails);
  };

  const onOxidentChange = value => {
    const newOxidentDetails = {
      ...oxidentDetails,
      chemicalId: value.id,
      value: oxidentDetails.value || ChemicalAdjustmentDefaultValues.oxidant,
    };
    setOxidentDetails(newOxidentDetails);
  };

  const onCoagulantChange = ({ id, symbol }) => {
    const value = getChemicalDoseValue("coagulant", id, symbol);
    const updatedData = {
      ...oxidentDetails,
      chemicalId: id,
      value: convertUptoDigits(value),
    };
    setCoagulantDetails(updatedData);
  };

  const onPhLsiChange = data => setPhDownDetails({ ...phDownDetails, ...data });

  const updateScreenData = (id, value) => {
    const actionMap = {
      pH_dropdown: () => onPhDownChange(value),
      Oxidant_dropdown: () => onOxidentChange(value),
      Coagulant_dropdown: () => onCoagulantChange(value),
      pH_input: () => onPhLsiChange({ value: value, lsiSdi: "" }),
      Oxidant_input: () => setOxidentDetails({ ...oxidentDetails, value: value }),
      Coagulant_input: () => setCoagulantDetails({ ...coagulantDetails, value: value }),
      lsiSdi_input: () => onPhLsiChange({ value: "", lsiSdi: value }),
    };
    if (actionMap[id]) {
      actionMap[id]();
    }
  };

  const handleInputChange = event => {
    const { id, value } = event.target;
    updateScreenData(id, value);
  };

  const renderChemicalOptions = category => chemicalListByCategory[category] || [];

  const renderChemicalCard = (label, isChecked, data, validations, tabIndex) => (
    <ChemicalCard
      id={label}
      label={label}
      onToggleChange={onToggleChange}
      isToggleChecked={isChecked}
      chemicalOptions={renderChemicalOptions(label)}
      data={data}
      inputText='mg/L'
      onBlur={handleBlur}
      onChange={handleInputChange}
      ranges={validations}
      onError={onError}
      tabIndex={tabIndex}
    />
  );

  const onError = (id, value) => updateErrors({ [errorMapping[id]]: value });

  const isOkBtnDisabled = useMemo(() => Object.values(errors).some(err => err), [errors]);

  const handlePhLSIFocus = target => {
    if (target === "pH_input") {
      updateErrors({ isSDIError: false });
      onPhLsiChange({ lsiSdi: "" });
    } else {
      updateErrors({ isPhError: false });
      onPhLsiChange({ value: "" });
    }
  };

  return (
    <>
      <CustomModal
        header='Chemical Adjustment'
        headerHelper='You may add chemicals/degas from here. Based on your selection table gets updated. Please note that LSI and S&DI require non zero Ca and CO₃ Concentrations.'
        confirmBtn='Okay'
        confirmBtnDisabled={isOkBtnDisabled}
        onModalClose={handleModalClose}
      >
        <StyledChemicalAdjustment>
          <div className='product'>
            <div className='product-icon'>
              <TechButtons label='Feed' id='feedwater-input' />
              <RightLongArrowPhIcon />
            </div>
            <div className='bordered-div border-div-left'></div>
          </div>

          <div className='main-content'>
            <div className='card-wrapper'>
              <ChemicalCard
                id='pH'
                label='pH'
                onToggleChange={onToggleChange}
                isToggleChecked={ispHToggleChecked}
                chemicalOptions={renderChemicalOptions("Acid")}
                inputText='pH'
                onBlur={handleBlur}
                onChange={handleInputChange}
                phUpDown={phDownInputData}
                data={phDownDetails}
                disabled={!isPhDownEnabled}
                ranges={PhValidations}
                lSIValidations={LSIValidations}
                onFocus={handlePhLSIFocus}
                onError={onError}
                tabIndex={1}
              />
              {renderChemicalCard(
                "Oxidant",
                isOxidantToggleChecked,
                oxidentDetails,
                OxidantValidations[COMPANY_CODE[pUFTechnologyID]],
                2,
              )}
              {renderChemicalCard("Coagulant", isCoagToggleChecked, coagulantDetails, CogulantValidations, 3)}
              <ChemicalAdjustmentTemperatureCard
                temperatureData={temperatureData}
                designTempInput={designTempInput}
                setDesignTempInput={setDesignTempInput}
                handleBlur={handleBlur}
                onError={onError}
                tabIndex={4}
              />
            </div>

            {mesuremenTableData && mesuremenTableData.data.length ? (
              <div className='measurement-table'>
                <CustomTable {...mesuremenTableData} maxHeight={200} />
              </div>
            ) : null}
          </div>

          <div className='uf'>
            <div className='bordered-div border-div-right'></div>
            <div className='uf-btn'>
              <TechButtons label='UF' id='feedwater-output' />
            </div>
          </div>
        </StyledChemicalAdjustment>
      </CustomModal>
    </>
  );
};

export default ChemicalAdjustmentModal;
