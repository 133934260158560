import { reportStrings } from "@constants/report.strings";
import { UNIT_TYPES, UNITS } from "@constants/units.constant";

export const WPUfElectricityTableColumns = [
  {
    field: "label",
    rowHeader: true,
    width: "250px",
  },
  {
    field: "unit",
    rowHeader: true,
    width: "100px",
  },
  {
    field: "value",
  },
];

export const WPUfElectricityRowsData = [
  {
    apiKey: "Peak_Power",
    label: reportStrings.peakPower,
    unitData: {
      unit: UNIT_TYPES.POWER,
    },
  },
  {
    apiKey: "Energy",
    label: reportStrings.energy,
    unitData: {
      unit: UNITS.kiloWattHourPerDay,
    },
  },

  {
    apiKey: "Electricity_unit_cost",
    label: reportStrings.electricityUnitCost,
    unitData: {
      unit: UNITS.kiloWattHour,
      isCost: true,
      precision: 4,
    },
  },
  {
    apiKey: "Electricity_cost",
    label: reportStrings.electricityCost,
    unitData: {
      unit: UNITS.day,
      isCost: true,
    },
  },
  {
    apiKey: "Specific_energy",
    label: reportStrings.specificEnergy,
    unitData: {
      unit: UNITS.kiloWattHour,
      unitDenominator: UNIT_TYPES.VOLUME_SOLUTION,
    },
  },
];
