import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { reportStrings } from "@constants/report.strings";

import { typographyVariants, WPTypogrpahy } from "@components/WPTypography";

import { colors } from "@common/styles/Theme";

import { WPReportSectionHeader } from "@features/report-new/common/WPReportSectionHeader";
import { WPReportTableNotes } from "@features/report-new/common/WPReportTableNotes";

const Table = styled.table`
  border-left: ${({ tableBorder, fullBorder }) => getTableBorderStyle("left", tableBorder, fullBorder)};
  border-right: ${({ tableBorder, fullBorder }) => getTableBorderStyle("right", tableBorder, fullBorder)};
  border-bottom: ${({ tableBorder }) => getTableBorderStyle("bottom", tableBorder, true)};
  // border-top: ${({ tableBorder }) => getTableBorderStyle("top", tableBorder, true)};

  width: ${props => (props.tableWidth ? props.tableWidth : "100%")};
  table-layout: ${props => (props.tableLayout ? props.tableLayout : "auto")};
`;

const getTableBorderStyle = (side, tableBorder, fullBorder) => {
  const borderValue = `1px solid ${colors.GreyE1}`;
  if (tableBorder?.[side] === true || (tableBorder?.[side] === undefined && fullBorder)) {
    return borderValue;
  }
  return "none";
};

const TableHead = styled.thead`
  visibility: ${props => (props.showHeader ? "initial" : "collapse")};
`;

const TableRow = styled.tr`
  page-break-inside: avoid;
`;

const TableHeader = styled.th`
  page-break-inside: avoid;
  text-align: left;
  padding: 6px 2px 6px 6px;
  vertical-align: top;
  border-top: 1px solid ${colors.GreyE1};
  border-right: 1px solid ${colors.GreyE1};
  width: ${props => (props.customWidth ? props.customWidth : "auto")};
  ${props => props.customMinWidth && `min-width: ${props.customMinWidth};`}
  ${props => props.customMaxWidth && `max-width: ${props.customMaxWidth};`}


  :last-child {
    border-right: none;
  }
  ${props => props.removeRightBorder && "border-right: none;"}
`;

const TableCell = styled.td`
  border-top: 1px solid ${colors.GreyE1};
  border-right: 1px solid ${colors.GreyE1};
  background-color: ${props => (props.rowHeader ? colors.Pale20PaleTealOp50 : colors.White)};
  vertical-align: top;
  padding: 6px 2px 6px 6px;
  line-height: 20px;
  :last-child {
    border-right: none;
  }
  // width: ${props => (props.customWidth ? props.customWidth : "auto")};
  // ${props => props.customMinWidth && `min-width: ${props.customMinWidth};`}
  // ${props => props.customMaxWidth && `max-width: ${props.customMaxWidth};`}
  ${props => props.removeRightBorder && "border-right: none;"}
  ${props => props.removeTopBorder && "border-top: none;"}
  ${props => props.isBgColorWhite && `background-color: ${colors.White};`}
`;

// This is a view only table component
export const WPTable = ({
  columns,
  rows,
  fullBorder,
  showHeader,
  sectionHeader,
  footNotesList,
  remarksList,
  width,
  className,
  hasUnitsRow, // pass this props true if the table has a first row with units,
  tableLayout,
  renderEmptyTable,
  tableBorder,
}) => {
  const getTable = () => {
    if ((!rows || rows?.length === 0) && !renderEmptyTable) {
      return <WPTypogrpahy text={reportStrings.none} className='fs-14 p-2' />;
    }
    return (
      <Table
        tableWidth={width}
        fullBorder={fullBorder}
        className={className}
        tableLayout={tableLayout}
        tableBorder={tableBorder}
      >
        <TableHead showHeader={showHeader}>
          <TableRow>
            {columns.map((column, colIndex) => {
              if (column.colSpan === 0) {
                return <></>;
              }
              const { headerName, colSpan, width, minWidth, maxWidth } = column;
              return (
                <TableHeader
                  key={`header-${colIndex}`}
                  colSpan={colSpan}
                  customWidth={width}
                  customMinWidth={minWidth}
                  customMaxWidth={maxWidth}
                  removeRightBorder={column.rowHeader === true && columns[colIndex + 1]?.rowHeader === true}
                >
                  <WPTypogrpahy variant={typographyVariants.tableColumnData} renderHtml>
                    {headerName}
                  </WPTypogrpahy>
                </TableHeader>
              );
            })}
          </TableRow>
        </TableHead>
        <tbody>
          {rows?.map((row, rowIndex) => (
            <TableRow key={`row-${rowIndex}`}>
              {columns.map((column, colIndex) => {
                const { onCell, renderCell } = column;
                const value = row[column.field]?.value || row[column.field];
                const { rowSpan, colSpan } = onCell?.({ value, row, rowIndex }) || {};
                if (colSpan === 0 || rowSpan === 0) {
                  return <></>;
                }
                // const { width, minWidth, maxWidth } = column;
                return (
                  <TableCell
                    key={`cell-${rowIndex}${colIndex}`}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    rowHeader={column.rowHeader}
                    removeRightBorder={column.rowHeader === true && columns[colIndex + 1]?.rowHeader === true}
                    removeTopBorder={rowIndex === 0 && hasUnitsRow}
                    isBgColorWhite={(rowIndex === 0 && hasUnitsRow) || undefined}
                    // customWidth={width}
                    // customMinWidth={minWidth}
                    // customMaxWidth={maxWidth}
                  >
                    {renderCell ? (
                      renderCell(row[column.field], row, rowIndex)
                    ) : (
                      <WPTypogrpahy variant={typographyVariants.tableData} renderHtml>
                        {value}
                      </WPTypogrpahy>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div>
      <div className='no-page-break'>
        {sectionHeader && <WPReportSectionHeader text={sectionHeader} />}
        {getTable()}
      </div>
      <div className='d-flex gap-4 no-page-break'>
        {footNotesList && <WPReportTableNotes list={footNotesList} />}
        {remarksList && <WPReportTableNotes type='remarks' list={remarksList} />}
      </div>
    </div>
  );
};

WPTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      headerName: PropTypes.string,
      field: PropTypes.string.isRequired,
      colSpan: PropTypes.number,
      rowHeader: PropTypes.bool,
      onCell: PropTypes.func,
      /**
       * Function to render custom cell content.
       * @param {any} value - The value of the cell.
       * @param {object} row - The entire row data.
       * @param {number} rowIndex - The index of the row.
       * @returns {React.ReactNode} - The custom cell content.
       */
      renderCell: PropTypes.func,
      width: PropTypes.string,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fullBorder: PropTypes.bool,
  showHeader: PropTypes.bool,
  tableLayout: PropTypes.string, // value should be table-layout css property value
  renderEmptyTable: PropTypes.bool, // force rendering of table even if no data
  tableBorder: PropTypes.shape({
    // custom table border ovverides defaults
    left: PropTypes.bool,
    right: PropTypes.bool,
    bottom: PropTypes.bool,
    // top: PropTypes.bool,
  }),
};

WPTable.defaultProps = {
  fullBorder: false,
  showHeader: true,
};
