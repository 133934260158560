import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadProjectFile } from "src/api/importApi/importApi";

import { CustomModalButtons, PROJECT_TYPE } from "@constants/global.constants";

import { useCheckProjectExistence } from "@hooks/useCheckProjectExistence";
import useGlobalDataHandler from "@hooks/useGlobalDataHandler";

import { getFileExtension } from "@utils/appUtils";
import { ImportProjectStrings } from "@utils/StringConstants";

import ConfirmationPopup, { DIALOG_TYPES } from "@components/ConfirmationPopup";
import { useFullScreenLoader } from "@components/WPFullScreenLoader";

import { updateProjectInfo } from "@common/ProjectInfoSlice";
import StandardSecondaryButton from "@common/styles/components/buttons/standard/StandardSecondaryButton";

import AddToFolder from "@features/home/AddToFolder";
import { updateLoader } from "@features/home/CardListSlice";
import { updateActiveMenu, updateLeftpanel } from "@features/menu/SideMenuSlice";
import ReplaceRenameProjectPopup from "@features/modals/ReplaceProject";

import ImportProjectLoader from "./SucessfulImportPropjectPopup/ImportProjectLoader";
import { XLS_PROJECT_NAME } from "./importProjectUtils";
import EditProjectNamePopup from "./RenameProject";
import ImportProjectPoup from "./SucessfulImportPropjectPopup";

import "./index.css";

const ImportProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchChemicalLibrary } = useGlobalDataHandler();
  const [checkProjectExistence] = useCheckProjectExistence();
  const { showFullScreenLoader, hideFullScreenLoader } = useFullScreenLoader();

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const [warning, setWarning] = useState(null);
  const [isFound, setIsFound] = useState(false);
  const [editedName, setEditedName] = useState(null);
  const [projectInfo, setProjectInfo] = useState(null);
  const [addToFolder, setAddToFolder] = useState(false);
  const [parsedFileData, setParsedFileData] = useState(null);
  const [showEditNameProject, setEditNameProject] = useState(false);
  const [isProjectUploading, setIsProjectUploading] = useState(false);
  const [error, setError] = useState({ header: null, description: null });
  const [isLoadingPopupVisible, setIsLoadingPopupVisible] = useState(false);

  const getValueForKey = (result, key) => result?.[key] || null;

  const fetchDoesProjectExist = async projectName => {
    showFullScreenLoader(ImportProjectStrings.checkProjectDetails);
    const result = await checkProjectExistence(projectName);
    hideFullScreenLoader();
    setIsFound(result);
    setEditNameProject(!result);
    setIsNew(!result);
  };

  const handleErrorClose = () => setError({ header: null, description: null });

  const handleWarningClose = id => {
    const isConfirm = id === CustomModalButtons.CONFIRM;

    setIsFound(!isConfirm);
    setWarning(null);

    if (isConfirm) {
      setIsLoadingPopupVisible(true);
      setIsProjectUploading(true);
      handleFileUpload(file, true);
      setEditNameProject(null);
    }
  };

  const parseXML = xmlString => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "text/xml");
      const root = xmlDoc.documentElement;

      const extractData = (element, parentKey = "") => {
        const result = {};
        Array.from(element.children).forEach(child => {
          const key = parentKey ? `${parentKey}/${child.tagName}` : child.tagName;
          const value = child.textContent.trim();
          if (value) {
            result[key] = value;
          }
          Object.assign(result, extractData(child, key));
        });
        return result;
      };

      const parsedData = extractData(root);
      const projectName = getValueForKey(parsedData, XLS_PROJECT_NAME);
      hideFullScreenLoader();
      if (projectName) {
        setParsedFileData(projectName);
        setEditedName(projectName);
        fetchDoesProjectExist(projectName);
      } else {
        setError({ header: ImportProjectStrings.errorImportingFile, description: "Only .DWPX format allowed" });
      }
    } catch (error) {
      hideFullScreenLoader();
      setError({ header: ImportProjectStrings.errorParsingXML, description: error });
    }
  };

  const handleImportProject = () => fileInputRef?.current.click();

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = getFileExtension(file.name?.toLowerCase());
      if (fileExtension !== "dwpx" && fileExtension !== "dwpro") {
        setError({
          header: ImportProjectStrings.errorImportingFile,
          description: ImportProjectStrings.acceptedExtensions,
        });
        return;
      }
      setFile(file);
      const reader = new FileReader();
      reader.onload = e => {
        const xmlString = e.target.result;
        showFullScreenLoader(ImportProjectStrings.checkProjectDetails);
        parseXML(xmlString);
      };
      reader.onerror = () => {
        hideFullScreenLoader();
        setError({ header: ImportProjectStrings.errorParsingXML, description: reader.error });
      };
      reader.readAsText(file, "UTF-8");
    }
    event.target.value = null;
  };

  const handleRename = () => {
    setIsFound(false);
    setEditNameProject(true);
  };

  const handleClose = () => setIsFound(false);

  const handleFileUpload = async (file, isReplace) => {
    setIsProjectUploading(true);
    const formData = new FormData();
    formData.append("ProjectFileDetails", file);
    formData.append("IsReplace", isReplace);
    formData.append("Rename", editedName === parsedFileData ? "" : editedName);

    try {
      const response = await uploadProjectFile(formData);
      if (response.status === 200) {
        const { data } = response;
        if (data) {
          const projectInfo = {
            ProjectId: data.id,
            title: editedName,
            favorite: false,
            isDeleted: false,
            isLocked: false,
            lastModified: data.LastModified,
            deletedDate: data.DeletedDate,
            createDate: data.CreateDate,
            folderName: "",
            technologyName: data.TechnologyName,
            tagName: "",
            modifiedDuration: data.ModifiedDuration,
            createDateDuration: data.CreateDateDuration,
            segmentID: data.SegmentID,
            segmentName: data.SegmentName,
            totalCount: 0,
            isImported: true,
          };
          setProjectInfo(projectInfo);
        }
        fetchChemicalLibrary(true); // Refresh Chemicals when project imported successfully
      } else {
        setError({ header: ImportProjectStrings.errorImportingFile, description: "Invalid Data" });
      }
      setIsProjectUploading(false);
    } catch (error) {
      const errorDescription = error?.response?.data?.responseMessage || error.message;
      setError({
        header: ImportProjectStrings.errorImportingFile,
        description: errorDescription,
      });
      setIsLoadingPopupVisible(false);
    } finally {
      setIsProjectUploading(false);
      setEditNameProject(null);
    }
  };

  const openProject = () => {
    const { ProjectId, ...restProjectInfo } = projectInfo;
    if (!ProjectId) return;
    const obj = {
      projectID: ProjectId,
      caseId: 0,
      projectName: editedName,
      Technology: "UF",
      isImported: true,
    };

    dispatch(updateProjectInfo(obj));
    navigate("/FeedWaterHome", {
      state: {
        ...restProjectInfo,
        projectID: ProjectId,
        caseID: 0,
      },
    });
  };

  const handleRenameClose = ({ target }) => {
    const isConfirm = target.id === CustomModalButtons.CONFIRM;
    const isClose = target.id === CustomModalButtons.CLOSE;

    setEditNameProject(null);
    setIsFound(!isConfirm);

    if (isConfirm) {
      setIsLoadingPopupVisible(true);
      setIsProjectUploading(true);
      handleFileUpload(file, false);
    }

    if (!isConfirm && !isClose) {
      setWarning(null);
    }
  };

  const handleReplace = () => {
    setIsFound(false);
    hideFullScreenLoader();
    setWarning({
      header: ImportProjectStrings.replaceProjectQuestionMark,
      description: ImportProjectStrings.projectReplaceConfirmation,
    });
  };

  const handleImportProjectClose = ({ target }) => {
    setIsProjectUploading(false);
    setIsLoadingPopupVisible(false);
    setAddToFolder(false);
    if (target.id === CustomModalButtons.CANCEL) {
      hideFullScreenLoader();
      setAddToFolder(true);
    } else if (target.id === CustomModalButtons.CONFIRM) {
      openProject();
    }
  };

  const handleAddToFolderClose = id => {
    setProjectInfo(null);
  };

  const handleFolderIconClick = () => {
    setIsProjectUploading(false);
    setIsLoadingPopupVisible(false);
    dispatch(updateActiveMenu(PROJECT_TYPE.IMPORTED));
    dispatch(updateLeftpanel("masterdata/api/v1/ProjectAll"));
    dispatch(updateLoader(false));
  };

  return (
    <div>
      <div className='import-div'>
        <StandardSecondaryButton className='import-project-btn' label='Import Project' onClick={handleImportProject} />
        <input
          type='file'
          ref={fileInputRef}
          className='display-none'
          onChange={handleFileChange}
          accept='.dwpx,.dwpro'
        />
      </div>
      {isFound && !showEditNameProject && (
        <ReplaceRenameProjectPopup
          show={isFound}
          projectName={parsedFileData}
          handleRename={handleRename}
          handleClose={handleClose}
          handleReplace={handleReplace}
        />
      )}
      {showEditNameProject && !warning?.header && (
        <EditProjectNamePopup
          editedName={editedName}
          projectName={parsedFileData}
          handleClose={handleRenameClose}
          setEditedName={setEditedName}
          handleReplace={handleReplace}
          isNew={isNew}
        />
      )}
      {warning?.header && (
        <ConfirmationPopup
          header={warning?.header}
          description={warning?.description}
          type={DIALOG_TYPES.WARNING}
          onClose={handleWarningClose}
          confirmBtn='Yes'
          cancelBtn='No'
        />
      )}
      {error.header && (
        <ConfirmationPopup
          header={error.header}
          description={error.description}
          type={DIALOG_TYPES.ERROR}
          onClose={handleErrorClose}
          confirmBtn='Okay'
        />
      )}
      {isLoadingPopupVisible && (
        <ImportProjectPoup
          confirmBtn={isProjectUploading ? "Cancel" : "Open Project"}
          header={isProjectUploading ? "Uploading Project" : "Successfully imported project"}
          headerHelper={
            isProjectUploading ? "This might take time depending on your file size" : "What do you want to do next?"
          }
          width='572px'
          cancelBtn={"Add To Folder"}
          confirmBtnHidden={isProjectUploading}
          onModalClose={handleImportProjectClose}
          closeButtonHidden={isProjectUploading}
        >
          <ImportProjectLoader
            isLoading={isProjectUploading}
            projectName={editedName}
            fileSize={file.size / 1000}
            onFolderIconClick={handleFolderIconClick}
          />
        </ImportProjectPoup>
      )}
      {addToFolder && (
        <AddToFolder show={projectInfo} projectInfo={projectInfo} handleAddFolderClose={handleAddToFolderClose} />
      )}
    </div>
  );
};

export default ImportProject;
